import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Link, Page, Text, View } from "@react-pdf/renderer"

import { PDFListItem, PDFRecommendTitleStyle } from "./PDFReportHelper"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

export default function PDFReportRecommendEducation({
  needLevel,
  needLevelStyle,
  footerText,
}) {
  const styles = reportStyles()
  const TitleStyle = PDFRecommendTitleStyle(needLevelStyle)

  return (
    <Page size="A4" style={styles.page}>
      <PDFReportHeader />
      <Text
        style={{
          ...styles.subTitle2,
          ...TitleStyle,
        }}
      >
        Education: {needLevel}
      </Text>
      <Text style={styles.bodyHighlighted}>
        School-based Recovery Support Services
      </Text>
      <Text>
        School-based recovery services are designed to help students achieve
        their educational goals while supporting their sustained recovery from
        SUDs or co-occurring disorders. Both high schools and higher education
        institutions offer education-based recovery support services. Studies
        have shown that students enrolled in recovery high schools were much
        more likely than those not enrolled in such schools to report being
        substance-free six months after they were first surveyed.
      </Text>
      <Text style={styles.Paragraph}>
        Within higher education,{" "}
        <Link src="https://collegiaterecovery.org/crps-crcs/">
          Collegiate Recovery Programs
        </Link>{" "}
        provide seamless access to recovery-related educational and social
        supports for in-need college students. The{" "}
        <Link src="https://dbh.dc.gov/service/recovery-support-services">
          DC government
        </Link>{" "}
        also provides Education Support Services that provide group and
        one-on-one instruction focusing on expanding clients’ knowledge base,
        ranging from substance use disorder reoccurrence prevention to GED and
        employment preparation. In addition, the{" "}
        <Link src="https://recoveryschools.org/">
          Association of Recovery Schools
        </Link>{" "}
        provide resources for recovery high schools and The{" "}
        <Link src="https://collegiaterecovery.org/">
          Association of Recovery in Higher Education
        </Link>{" "}
        provides resources for collegiate recovery programs.
      </Text>

      <Text style={{ ...styles.textBold, ...styles.Paragraph }}>
        Examples:{" "}
      </Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://www.youtube.com/watch?v=iHNztoBkEv8">
            Generation Found Documentary Film
          </Link>{" "}
          <Text style={styles.textBold}>featuring Archway Academy </Text>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://apnc.org/services/">
            North Carolina Collegiate Recovery
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.augsburg.edu/college-recovery-program/">
            Step Up Program at Augsburg University
          </Link>
        </PDFListItem>
      </View>
      <PDFReportFooter text={footerText} />
    </Page>
  )
}
