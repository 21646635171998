import React from "react"

//styles
import reportStyles from "./PDFReportStyles" //styles

//Helpers
import { PDFRecommendTitleStyle } from "./PDFReportHelper"

//react-pdf
import { Link, Page, Text, View } from "@react-pdf/renderer"

import { PDFCustomListItem, PDFListItem } from "./PDFReportHelper"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

export default function PDFReportRecommendEnvironment({
  needLevel,
  needLevelStyle,
  footerText,
}) {
  const styles = reportStyles()
  const TitleStyle = PDFRecommendTitleStyle(needLevelStyle)

  return (
    <Page size="A4" style={styles.page}>
      <PDFReportHeader />
      <Text style={{ ...styles.subTitle2, ...TitleStyle }}>
        Environment: {needLevel}
      </Text>
      <Text style={styles.bodyHighlighted}>Prevention Services</Text>
      <Text>
        Prevention services and programs aim to prevent substance use or misuse
        to reduce the likelihood of developing a substance use disorder. These
        services are provided in different settings in the home, clinic, or
        community, and can be tailored to meet people’s needs at different
        stages of life (NIDA, 2014). For example, many SUD prevention programs
        focus on children and adolescents given that use of drugs early on in
        life increases an individual’s chance of developing an SUD. There are a
        number of evidence-based prevention programs that work to increase
        protective factors and reduce risk factors for drug use (NIDA, n.d.).
      </Text>
      <Text style={styles.exampleTitle}>Examples:</Text>
      <View style={{ ...styles.listContainer, marginTop: 0 }}>
        <PDFListItem>
          <Link src="https://www.communitiesthatcare.net/">
            Communities that Care Prevention System
          </Link>{" "}
          — A structured approach that helps communities utilize their resources
          most effectively to address identified risk factors for substance use,
          aggression, and other problems in youth. One study showed that a $602
          investment in each child (in 2017 dollars) had yielded an estimated
          $7,754 in savings by the time participants were age 23 (Kuklinski,
          2021).
        </PDFListItem>
        <PDFListItem>
          <Link src="https://themartinsburginitiative.com/">
            The Martinsburg Initiative
          </Link>{" "}
          — The initiative is based on the science of Adverse Childhood
          Experiences (ACEs) that shows how early childhood experiences shape
          the lives of adults. The program creates a partnership between police,
          schools, community, health, and education to address substance use in
          families.
        </PDFListItem>
        <PDFListItem>
          <Link src="https://depts.washington.edu/sdrg/">
            Raising Healthy Children
          </Link>{" "}
          — Initially developed in Seattle, in this intervention, elementary age
          children receive lessons targeted at enhancing children’s
          opportunities for forming healthy bonds and development of social
          skills and reinforcements. Research has shown improvement across many
          health outcomes for participants, including a reduced likelihood to
          report using drugs or alcohol as a teenager (Hill, 2020).
        </PDFListItem>
      </View>
      <hr style={styles.line} />
      <Text style={{ ...styles.bodyHighlighted, ...styles.listItemSpaceTop }}>
        Harm Reduction Services
      </Text>
      <Text>
        Harm reduction is an approach that “meets people where they are” by
        directly engaging with people who use drugs in order to prevent
        overdose, reduce spread of infectious disease, and improve overall
        well-being. Harm reduction services can save lives by being available
        and accessible in a way that emphasizes the need for compassion toward
        people who use drugs (SAMSHA, n.d.). Harm reduction programs are part of
        a comprehensive approach to preventing or reducing harms associated with
        drug use. They can include a wide range of services including the
        provision of overdose reversal through naloxone, overdose reversal
        education, supplies, and training, provision of fentanyl test strips,
        syringe service programs, sobering and crisis stabilization centers, and
        connection to overdose education, counseling and referral to treatment
        (Centers for Disease Control and Prevention, 2010; Fernandes et al.,
        2017; Legal Action Center, n.d.). The federal government is already
        supporting harm reduction{" "}
        <Link src="https://www.samhsa.gov/grants/grant-announcements/sp-22-001">
          through grants
        </Link>
        , and{" "}
        <Link src="https://harmreductionhelp.cdc.gov/s/">
          technical assistance
        </Link>
        , which may be available to your community.
      </Text>
      <View style={styles.listContainer}>
        <PDFCustomListItem bullet={"1."}>
          <Text>
            <Link src="https://www.samhsa.gov/medication-assisted-treatment/medications-counseling-related-conditions/naloxone">
              Naloxone
            </Link>{" "}
            — Naloxone (Narcan®) is a medication designed to rapidly reverse an
            opioid overdose. First responders and community members including
            caregivers can learn how to safely administer naloxone through
            training programs on naloxone administration and distribute free
            kits through community-based organizations and pharmacies.{" "}
            <Link src="http://legislativeanalysis.org/wp-content/uploads/2022/09/Naloxone-Access-Summary-of-State-Laws.pdf">
              Many states
            </Link>{" "}
            have passed laws that make it easier for community members to
            dispense naloxone in an emergency.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem>
          <Text style={styles.exampleTitle}>Examples:</Text>
        </PDFCustomListItem>
        <PDFListItem tabs={1}>
          <Link src="https://www.nyc.gov/site/doh/health/health-topics/naloxone.page#:~:text=How%20to%20get%20a%20free,receive%20a%20kit%20by%20mail.">
            New York City’s Naloxone Distribution Program
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://odh.ohio.gov/know-our-programs/violence-injury-prevention-program/projectdawn/">
            Ohio Project DAWN (Deaths Avoided With Naloxone)
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://www.northcarolinahealthnews.org/2022/11/09/vending-machines-dispensing-free-life-saving-medication/">
            North Carolina Naloxone Vending Machines in Jails
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://www.tippingthepainscale.com/">
            Tipping The Pain Scale Film featuring Community Outreach{" "}
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://store.samhsa.gov/product/Opioid-Overdose-Prevention-Toolkit/SMA18-4742">
            SAMSHA’s Opioid Overdose Prevention Toolkit
          </Link>
        </PDFListItem>
      </View>

      <View style={styles.listContainer}>
        <PDFCustomListItem bullet={"2."}>
          <Text>
            <Link src="https://nida.nih.gov/research-topics/syringe-services-programs">
              Syringe Services Programs
            </Link>{" "}
            — Syringe services programs (SSPs) are community-based prevention
            programs that can provide a range of services, including connection
            to substance use disorder treatment; access to and disposal of
            sterile syringes and injection equipment; and vaccination, testing,
            and linkage to care and treatment for infectious diseases.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem>
          <Text style={styles.exampleTitle}>Examples:</Text>
        </PDFCustomListItem>
        <PDFListItem tabs={1}>
          <Link src="https://fsph.iupui.edu/doc/research-centers/Indiana Syringe Exchange Program 2015.pdf">
            Indiana Syringe Exchange Program
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://louisvilleky.gov/government/health-wellness/harm-reduction-outreach-services-formerly-syringe-services">
            Kentucky Harm Reduction Outreach Services
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://harmreduction.org/issues/establishing-rural-programs/">
            Establishing a Syringe Services Program in Rural America
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://www.naco.org/resources/opioid-solutions/approved-strategies/ssps">
            National Association of Counties Brief on Syringe Services Program
            for Counties
          </Link>
        </PDFListItem>
      </View>
      <View style={styles.listContainer}>
        <PDFCustomListItem bullet={"3."}>
          <Text>
            <Link src="https://www.cdc.gov/stopoverdose/fentanyl/fentanyl-test-strips.html">
              Fentanyl Test Strips
            </Link>{" "}
            —Fentanyl test strips are small strips of paper that can detect the
            presence of fentanyl in pills, powder, and injectables. The strips
            are low-cost (approximately $1 each),{" "}
            <Link src="https://www.btnx.com/HarmReduction">simple to use</Link>,
            and can reduce the risk of overdose. Drug checking, including use of
            fentanyl test strips is not legal in all states, however many states
            have taken steps to decriminalize use of strips in efforts to reduce
            stigma and provide individuals and communities with additional tools
            to address the opioid epidemic. In April 2021, the U.S. Centers for
            Disease Control and Prevention and the Substance Abuse and Mental
            Health Services Administration (SAMHSA)
            <Link src="https://www.cdc.gov/media/releases/2021/p0407-Fentanyl-Test-Strips.html">
              announced
            </Link>{" "}
            that federal funding could be used to purchase strips.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem>
          <Text style={styles.exampleTitle}>Examples:</Text>
        </PDFCustomListItem>
        <PDFListItem tabs={1}>
          <Link src="https://www.dhs.wisconsin.gov/news/releases/101322.htm">
            Wisconsin State Program
          </Link>
        </PDFListItem>
        <PDFListItem tabs={1}>
          <Link src="https://harmreduction.org/issues/fentanyl/fentanyl-test-strip-pilot/">
            San Francisco Pilot
          </Link>
        </PDFListItem>
        <PDFCustomListItem bullet={"4."} customStyles={styles.listItemSpaceTop}>
          <Text>
            <Link src="https://www.chcf.org/publication/sobering-centers-explained/">
              Sobering
            </Link>{" "}
            or crisis stabilization centers offer a cost-effective alternative
            for providing care to intoxicated adults whose public alcohol or
            drug use puts themselves or others at risk. Too often people who are
            intoxicated without any other criminal justice or health
            stabilization need are brought to emergency departments for
            evaluation. This overburdens high-acute health care infrastructure
            with a population that is better served often in alternative
            settings designed to support public safety and encourage individuals
            engagement in treatment services.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem>
          <Text style={styles.exampleTitle}>Examples:</Text>
        </PDFCustomListItem>
        <PDFListItem tabs={1}>
          Houston Recovery Center: Sobering Center
        </PDFListItem>
        <PDFListItem tabs={1}>Baltimore City Stabilization Center</PDFListItem>

        <PDFCustomListItem bullet={"5."} customStyles={styles.listItemSpaceTop}>
          <Text>
            <Text style={styles.textBold}>Addressing Stigma. </Text>
            <Link src="https://www.hopkinsmedicine.org/stigma-of-addiction/#what-is-stigma-of-addiction">
              Stigma towards people who use drugs
            </Link>{" "}
            is based on the belief that addiction is a personal choice and the
            result of a lack of willpower or individual failing. Stigma within
            the community and in health care settings can{" "}
            <Link src="https://doi.org/10.1016/j.drugalcdep.2006.10.014">
              negatively impact the health
            </Link>{" "}
            and well-being of people with substance use disorders and the
            quality of care they receive. States and localities can address
            stigma through education, training, and public awareness campaigns.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem>
          <Text style={styles.exampleTitle}>Examples:</Text>
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"a."} tabs={1}>
          <Link src="https://www.cdc.gov/drugoverdose/od2a/pdf/od2a-stigma-reduction-case-study-508.pdf">
            Vermont Stigma/Harm Reduction Training Case Study and Evaluation
          </Link>
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"b."} tabs={1}>
          <Link src="https://www.opioidlibrary.org/featured_collection/reducing-stigma-surrounding-substance-use-disorders-creating-a-community-based-anti-stigma-initiative/">
            Collection of Resources on Creating a Community-based Anti-Stigma
            Initiative
          </Link>
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"c."} tabs={1}>
          <Link src="https://www.shatterproof.org/our-work/ending-addiction-stigma/how-you-can-fight-stigma">
            Shatterproof Resources on Stigma
          </Link>
        </PDFCustomListItem>
      </View>
      <hr style={styles.line} />
      <Text
        style={{ ...styles.bodyHighlighted, ...styles.listItemSpaceTop }}
        break
      >
        Recovery Community Centers
      </Text>
      <Text>
        Recovery Community Centers (RCCs) are non-profit centers that offer
        support services to individuals in recovery such as peer support,
        employment and housing resources, and opportunities to connect with
        others. These centers are typically operated by peers who have lived
        experience with addiction and recovery. RCCs are not residences or
        places where individuals can receive clinical treatment but instead
        provide an array of training and other non-clinical community-based
        supports. RCCs typically welcome all recovery support modalities and
        encourage multiple pathways to recovery.{" "}
      </Text>
      <Text style={styles.Paragraph}>
        Though peer-reviewed evidence is limited, one study of 32 RCCs across
        the Northeast found that RCCs were associated with increased
        self-reported personal and social resources that facilitate the process
        of recovery (e.g., recovery capital), which were associated with
        improvements in self-esteem, quality of life, and psychological distress
        (Kelly, 2020). RCCs are typically supported in part by time-limited
        grants, including state funding from federal block grants.
      </Text>
      <Text style={styles.exampleTitle}>Examples:</Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://www.chfs.ky.gov/agencies/dbhdid/Documents/recoverycommunitycentersinfograph.pdf">
            Kentucky
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://static1.squarespace.com/static/5d3f05dde2442800011cca16/t/6202e64fe96c6e5164d689aa/1644357200412/RCC+2021+Summary+infographic+FINAL+%28002%29.pdf">
            Missouri
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.rcnc.org/about/community-center.html">
            North Carolina
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://kinolorber.com/film/theanonymouspeople">
            Connecticut, Detroit, and Philadelphia as Featured in The Anonymous
            People Film
          </Link>
        </PDFListItem>
      </View>
      <PDFReportFooter text={footerText} />
    </Page>
  )
}
