import React, { useContext, useState } from "react"
import parse from "html-react-parser"

//Data
import getRecoveryClassData from "../../helpers/getRecoveryClassData"

//context
import { MainContext } from "../../context/mainContext"

//primeReact:
import { Dialog } from "primereact/dialog"
import { ProgressSpinner } from "primereact/progressspinner"

//Styles
import myStyles from "../../styles/Default/infoModal.module.css"

export default function InfoModal({
  show,
  onHide,
  infoTypeDisplayName,
  infoSectionDisplayName,
  infoSectionName,
}) {
  const mainContext = useContext(MainContext)

  const [hoverClose, setHoverClose] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function recoveryClassDataReceived(data) {
    mainContext.setRecoveryClassData(data.data)
    setIsLoading(false)
  }

  let content = undefined

  if (!mainContext.recoveryClassData) {
    getRecoveryClassData(recoveryClassDataReceived)
  } else if (isLoading) {
    setIsLoading(false)
  }

  if (!!mainContext.recoveryClassData && show) {
    const filteredContent = mainContext.recoveryClassData.filter(
      (x) => x.className === infoSectionName
    )

    if (filteredContent.length > 0) {
      content = filteredContent[0]
    }
  }

  return (
    <Dialog
      visible={show}
      header={
        <div className={myStyles.header}>
          <div className={myStyles.headerGrid}>
            <div
              className={myStyles.headerTitle}
            >{`${infoTypeDisplayName}: ${infoSectionDisplayName} `}</div>
          </div>
          <div className={myStyles.headerButton}>
            <button
              className={`${myStyles.headerCloseButton} ${
                hoverClose ? myStyles.headerCloseButtonHover : ""
              }`}
              onMouseEnter={() => setHoverClose(true)}
              onMouseLeave={() => setHoverClose(false)}
              onClick={onHide}
            >
              X
            </button>
          </div>
        </div>
      }
      closable={false}
      onHide={onHide}
      className={myStyles.dialog}
    >
      {isLoading ? (
        <div className={myStyles.progressSpinner}>
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className={myStyles.data}>
            {!content || !content.definition
              ? "No definition available"
              : parse(content.definition)}
          </div>
          {!!content && !!content.source ? (
            <>
              <br />
              <div className={myStyles.title}>Source</div>
              <div className={myStyles.data}>{parse(content.source)}</div>
            </>
          ) : null}
        </>
      )}
    </Dialog>
  )
}
