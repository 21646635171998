import React from "react"

import myStyles from "../../styles/Default/about.module.css"
import { GetHyperLink } from "../../helpers/valueHelper"

//Logos
import dukeLogo from "../../assets/images/margolis-logo-white.png"
import elevanceLogo from "../../assets/images/logo_elevance_blue.png"
import thsLogo from "../../assets/images/THS_logo_web.jpeg"

export default function About() {
  return (
    <div className={myStyles.mainBody}>
      <div className={myStyles.title}>Project Overview</div>
      <div className={myStyles.body}>
        The Duke-Margolis Institute for Health Policy, along with its partner
        Third Horizon Strategies, has launched a project to develop and
        disseminate resources and tools to guide state, county, and municipal
        investments of opioid settlement resources for their communities. The
        project will convene experts, conduct stakeholder interviews, and
        analyze qualitative and quantitative data to develop best practices for
        investments in sustainable, recovery-oriented infrastructure. The
        two-year project (July 2022-June 2024) is supported by The Elevance
        Health Foundation.
      </div>
      <div className={myStyles.title}>
        About Duke-Margolis Institute for Health Policy
      </div>
      <div className={myStyles.body}>
        The mission of the Robert J. Margolis, MD, Institute for Health Policy
        at Duke University is to improve health, health equity, and the value of
        health care through practical, innovative, and evidence-based policy
        solutions. For more information, visit{" "}
        <a
          href="https://healthpolicy.duke.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          healthpolicy.duke.edu
        </a>{" "}
        and follow us on Twitter @DukeMargolis.
      </div>
      <div className={myStyles.title}>About Third Horizon Strategies</div>
      <div className={myStyles.body}>
        Third Horizon Strategies is a boutique strategy and advisory firm
        focused on shaping a future system that actualizes a sustainable culture
        of health nationwide. The firm offers a 360º view of complex challenges
        across three horizons – past, present, and future – to help industry
        leaders and policymakers interpret signals and trends; design integrated
        systems; and enact changes so that all communities, families, and
        individuals can thrive. Third Horizon Strategies serves as the manager
        of the{" "}
        <GetHyperLink
          href="https://incentivizerecovery.org/"
          description="Alliance for Addiction Payment Reform"
        />
        , a national cross-sector learning collaborative working to advance
        value-based principles leveraging recovery-oriented system design for
        Substance Use Disorders. Learn more at{" "}
        <GetHyperLink
          href="http://www.thirdhorizonstrategies.com/"
          description="www.thirdhorizonstrategies.com"
        />
        .
      </div>

      <div className={myStyles.title}>About Elevance Health Foundation</div>
      <div className={myStyles.body}>
        Elevance Health Foundation is the philanthropic arm of Elevance Health,
        Inc. The Foundation works to advance health equity by focusing on
        improving the health of the socially vulnerable through partnerships and
        programs in our communities with an emphasis on maternal child health;
        substance use disorder; and food as medicine. Through its key areas of
        focus, the Foundation also strategically aligns with Elevance Health’s
        focus on community health and becoming a lifetime, trusted health
        partner that is fueled by its purpose to improve the health of humanity.
        To learn more about Elevance Health Foundation, please visit{" "}
        <GetHyperLink
          href="https://elevancehealth.foundation/"
          description="https://elevancehealth.foundation"
        />{" "}
        or follow us @ElevanceFND on Twitter and{" "}
        <GetHyperLink
          href="https://www.facebook.com/ElevanceHealthFoundation/"
          description="Elevance Health Foundation"
        />{" "}
        on Facebook.
      </div>

      <div className={myStyles.title}>Methodology</div>
      <div className={myStyles.body}>
        <p>
          The Need Index is a comparative index designed to measure the
          prospective investment needs for substance use and mental health
          prevention, treatment, and recovery support services in a specific
          area. The overall index is comprised of a set of county-level
          indicators that have been selected as monitoring variables based on
          their relationship to Social Determinants of Health (SDOH) domains
          extended to opportunities for recovery capitalization investments.
        </p>
        <p>
          The Need Index is calculated as a normalized composite of the
          monitoring variable measures for a given domain. First, individual
          indicators are transformed using the Box-Cox transformation method to
          make the data more closely resemble a normal distribution. Next, these
          indicators are converted into a z-score. Z-scores are a common
          statistical procedure that puts indicators measured on different
          scales onto a common scale. The z-score measures the fractional number
          of standard deviations by which the value of an observation is above
          or below the mean value of the population. The formula is:{" "}
        </p>
        <ul className={myStyles.methodList}>
          <li>z = (x – μ) / σ </li>
        </ul>
        <p>
          where: <br />
          <ul className={myStyles.methodList}>
            <li>x is the value being measured;</li>
            <li>μ is the population mean; </li>
            <li>and σ is the population standard deviation.</li>
          </ul>
        </p>
        <p>
          Using the mean and standard deviation of each indicator for the entire
          country, we calculate an indicator z-score within each county for each
          of our indicators.
        </p>
        <p>
          Next, we take the mean of the z-scores for that domain (e.g.
          Environment). Not all counties will have data available for each
          indicator. By taking the mean with NA values removed, we mitigate the
          effect missing data could have on the Index. Weighted averages were
          considered and ultimately rejected for lack of sufficient research.
        </p>
        <p>
          Being a relative and unitless measure, the Need Index is represented
          visually on a color spectrum rather than numerically. To account for
          outliers, z-scores are capped off at ±2 standard deviations from the
          mean. A z-score of ±2 encompasses approximately 95% of the data. The
          remaining data is not dropped but rather{" "}
          <GetHyperLink
            href="https://en.wikipedia.org/wiki/Winsorizing"
            description="winsorized"
          />
          . This preserves an outlier’s relative place in the distribution while
          ensuring that the color spectrum maintains high resolution. Users will
          be able to view the Need Index side by side with its component
          measurements for a better understanding of what is driving results.{" "}
        </p>
      </div>
      <div className={myStyles.title}>Contact</div>
      <div className={myStyles.body}>
        Please email <a href="mailto:frank.mcstay@duke.edu">Frank McStay</a> ,
        Assistant Research Director at the Duke-Margolis Institute for Health
        Policy with any questions.
      </div>
      <div className={myStyles.logos}>
        <img src={dukeLogo} alt="DUKE" className={myStyles.dukeLogo} />
        <img
          src={elevanceLogo}
          alt="ELEVANCE"
          className={myStyles.elevanceLogo}
        />
        <img src={thsLogo} alt="THS" className={myStyles.thsLogo} />
      </div>
    </div>
  )
}
