import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportMore() {
  return (
    <>
      <div className={myStyles.title}>MORE RESOURCES</div>
      <div className={myStyles.body}>
        <b>Opioid Settlement Information and Guidance</b>
        <ul>
          <li>
            <a
              href="https://opioidprinciples.jhsph.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Johns Hopkins Bloomberg School of Public Health Principles for the
              Use of Funds from The Opioid Litigation
            </a>
            <br />
            <i>
              This website provides planning and process level guidance for
              state and local policymakers.
            </i>
          </li>
          <li>
            <a
              href="https://www.naco.org/resources/opioid-solutions-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              National Association of Counties Opioid Solutions Center
            </a>{" "}
            <br />
            <i>
              This website provides resources for local leaders on effective
              treatment, recovery, prevention and harm reduction practices that
              save lives and address the underlying causes of substance use
              disorder. It includes links to briefs on approved strategies,
              technical assistance, and{" "}
              <a
                href="https://www.naco.org/resources/opioid-solutions/planning-principles-toolkit"
                target="_blank"
                rel="noopener noreferrer"
              >
                planning tools for counties
              </a>
              .
            </i>
          </li>
        </ul>
      </div>
      <div className={myStyles.body}>
        <b>Opioid Settlement Tracking</b>
        <ul>
          <li>
            <a
              href="https://www.opioidsettlementtracker.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Opioid Settlement Tracker
            </a>
            <br />
            <i>
              This site tracks opioid settlements and states’ opioid settlement
              spending plans.
            </i>
          </li>
          <li>
            <a
              href="https://www.nashp.org/how-states-administering-opioid-settlement-funds/"
              target="_blank"
              rel="noopener noreferrer"
            >
              National Academy for State Health Policy State Tracker
            </a>
            <br />
            <i>
              This site includes information about enacted state legislation
              related to opioid settlement funds.
            </i>
          </li>
        </ul>
      </div>
      <div className={myStyles.body}>
        <b>Other Sources for County-Level Data</b>
        <ul>
          <li>
            <a
              href="https://rsconnect.norc.org/recovery_ecosystem_index/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NORC Recovery Ecosystem Index
            </a>
            <br />
            <i>
              This tool provides county-level maps to describe the
              community-level factors in place to support individuals in
              recovery from substance use disorder.
            </i>
          </li>
          <li>
            <a
              href="https://opioidmisusetool.norc.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NORC Drug Overdose Deaths in the United States
            </a>
            <br />
            <i>
              This tool provides county-level maps illustrating the relationship
              between community and population demographics and fatal drug
              overdoses—including opioids—in the United States.
            </i>
          </li>
          <li>
            <a
              href="https://www.countyhealthrankings.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              County Health Rankings and Roadmaps
            </a>
            <br />
            <i>
              This website provides data, evidence, guidance, and examples to
              build awareness of the multiple factors that influence health and
              support leaders in growing community power to improve health
              equity.
            </i>
          </li>
        </ul>
      </div>
    </>
  )
}
