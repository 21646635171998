import React from "react"

export function getSelectionValueDisplay(field, overrideValue, acceptZero) {
  try {
    const value = overrideValue !== undefined ? overrideValue : field.value
    return getDisplayValue(
      value,
      field.valueUnitPosition,
      field.valueUnit,
      field.decimalPrecision,
      acceptZero
    )
  } catch (error) {
    console.error(`getSelectionValueDisplay::  ${error}`)
    return "error"
  }

  /*   const prefix = field.valueUnitPosition === "front" ? field.valueUnit : ""
  const suffix = field.valueUnitPosition === "back" ? field.valueUnit : ""

  const checkHasValue = (value) => {
    return !!value || value === 0
  }

  if (field.valueUnit === "bool") {
    let useValue = overrideValue !== undefined ? overrideValue : field.value

    if (useValue === 1 || useValue === "1.0") {
      return "Yes"
    } else if (useValue === 0 || useValue === "0.0") {
      return "No"
    } else {
      return "<Unknown>"
    }
  } else {
    let returnValue = checkHasValue(overrideValue) ? overrideValue : field.value

    if (!isNaN(returnValue)) {
      returnValue = (+returnValue).toLocaleString()
    }

    return !checkHasValue(field.value) && !checkHasValue(overrideValue)
      ? "<Unknown>"
      : `${prefix}${returnValue}${suffix}` 
  }*/
}

export function getDisplayValue(
  value,
  valueUnitPosition,
  valueUnit,
  decimalPrecision,
  acceptZero
) {
  try {
    const prefix = valueUnitPosition === "front" ? valueUnit : ""
    const suffix = valueUnitPosition === "back" ? valueUnit : ""

    const checkHasValue = (value, acceptZero) => {
      if (acceptZero) {
        return !!value || value === 0
      } else {
        return !!value //|| value === 0
      }
    }

    if (valueUnit === "bool") {
      if (value === 1 || value === "1.0") {
        return "Yes"
      } else if (value === 0 || value === "0.0") {
        return "No"
      } else {
        return "Not available"
      }
    } else {
      let returnValue = value

      if (!isNaN(returnValue)) {
        returnValue = (+returnValue).toFixed(
          decimalPrecision !== undefined ? decimalPrecision : 2
        )

        returnValue = (+returnValue).toLocaleString()
      }

      return !checkHasValue(value, acceptZero)
        ? "Not available"
        : `${prefix}${returnValue}${suffix}`
    }
  } catch (error) {
    console.error(error)
    return "error"
  }
}

export function GetHyperLink({ href, description }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {description}
    </a>
  )
}
