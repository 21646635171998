import Papa from "papaparse"
import CSVDataCounty from "../assets/data/score_card_scores_county.csv"
import CSVDataState from "../assets/data/score_card_scores_state.csv"

export function getScoreCardDataCounty(onDataRetrieved) {
  return fetch(CSVDataCounty)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          result.data = result.data.map((x) => {
            let geoID = x.GEOID.padStart(5, "0")

            return {
              ...x,
              fips: x.GEOID.padStart(5, "0"),
              county_FIPS: geoID.substring(2, 5),
              state_FIPS: geoID.substring(0, 2),
            }
          })
          onDataRetrieved(result)
        },
      })
    })
}

export function getScoreCardDataState(onDataRetrieved) {
  return fetch(CSVDataState)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          result.data = result.data.map((x) => {
            let geoID = x.GEOID.padStart(5, "0")

            return {
              ...x,
              fips: x.GEOID.padStart(5, "0"),
              county_FIPS: geoID.substring(2, 5),
              state_FIPS: geoID.substring(0, 2),
            }
          })
          onDataRetrieved(result)
        },
      })
    })
}
