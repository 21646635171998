import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommend({
  highNeed,
  moderateNeed,
  lowNeed,
  maintain,
}) {
  return (
    <>
      <div className={myStyles.title}>RECOVERY INVESTMENT OPPORTUNITIES </div>
      <div className={myStyles.recommendContainer}>
        <div className={myStyles.recommendItem}>
          <div className={myStyles.subTitle} style={{ color: "red" }}>
            High Need
          </div>
          <div className={myStyles.body}>
            <p>
              These are domains where your county scored significantly worse
              than the national average on our Need Index based on public data
              sources. These areas represent priority focus areas for settlement
              dollar activities.
            </p>
            {highNeed.length > 0 ? (
              <ul>
                {highNeed.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            ) : (
              <span style={{ fontStyle: "italic" }}>
                There are no domains in the High Need category
              </span>
            )}
          </div>
        </div>
        <div className={myStyles.recommendItem}>
          <div className={myStyles.subTitle} style={{ color: "#ED7C31" }}>
            Moderate Need
          </div>
          <div className={myStyles.body}>
            <p>
              These are domains where your county scored worse than the national
              average on our Need Index based on public data sources. These
              areas represent focus areas for settlement dollar activities.
            </p>

            {moderateNeed.length > 0 ? (
              <ul>
                {moderateNeed.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            ) : (
              <span style={{ fontStyle: "italic" }}>
                There are no domains in the Moderate Need category
              </span>
            )}
          </div>
        </div>
        <div className={myStyles.recommendItem}>
          <div className={myStyles.subTitle} style={{ color: "#D3A005" }}>
            Low Need
          </div>
          <div className={myStyles.body}>
            <p>
              These are domains where your county scored slightly worse than the
              national average on our Need Index based on public data sources.
              These areas represent potential focus areas for settlement dollar
              activities.
            </p>
            {lowNeed.length > 0 ? (
              <ul>
                {lowNeed.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            ) : (
              <span style={{ fontStyle: "italic" }}>
                There are no domains in the Low Need category
              </span>
            )}
          </div>
        </div>
        <div className={myStyles.recommendItem}>
          <div className={myStyles.subTitle} style={{ color: "green" }}>
            Lowest Need
          </div>
          <div className={myStyles.body}>
            <p>
              These are domains where your county scored similar or better than
              national averages on our Need Index.
            </p>
            {maintain.length > 0 ? (
              <ul>
                {maintain.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            ) : (
              <span style={{ fontStyle: "italic" }}>
                There are no domains in the Lowest Need category
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
