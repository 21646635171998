import React from "react"
import { Route, Routes } from "react-router-dom"
import MainMap from "../pages/Mapping/Index"
import ScoreCard from "../pages/ScoreCard/Index"
import getPath from "../helpers/routeHelper"
import About from "../pages/About/Index"
import Report from "../pages/Report/Index"
import Faq from "../pages/Faq/Index"

import RouteChangeTracker from "../helpers/googleAnalytics"

export default function RouteBuilder() {
  const router = [
    {
      path: "/",
      element: <MainMap />,
    },
    {
      path: getPath(""),
      element: <MainMap />,
    },
    { path: getPath("explore"), element: <MainMap /> },
    { path: getPath("monitor"), element: <ScoreCard /> },
    { path: getPath("about"), element: <About /> },
    { path: getPath("faq"), element: <Faq /> },
    { path: getPath("report"), element: <Report /> },
  ]

  return (
    <>
      <RouteChangeTracker />
      <Routes>
        {router.map((x, i) => (
          <Route key={i} path={x.path} element={x.element} />
        ))}
        <Route path="*" component={<MainMap />} />
      </Routes>
    </>
  )
}
