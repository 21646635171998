import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportAboutTheData({ reportType }) {
  const GetStateText = () => {
    return (
      <div className={myStyles.body}>
        This tool uses publicly available state and county data organized by the
        Healthy People 2030{" "}
        <a
          href="https://health.gov/healthypeople/priority-areas/social-determinants-health"
          target="_blank"
          rel="noopener noreferrer"
        >
          Social Determinants of Health Domains
        </a>{" "}
        You can see a state’s overall performance by domain by clicking on the{" "}
        <b>Monitor</b> tab within this tool, or viewing the <b>Appendix</b> at
        the end of this report. The indicators selected can help you understand
        the potential demand for substance use and mental health prevention,
        treatment, and recovery support services in a state. For county-level
        information, click on the <b>Explore</b> tab, select "County" in the
        drop down menu, and type in the county name.
      </div>
    )
  }

  const GetCountyText = () => {
    return (
      <div className={myStyles.body}>
        This tool uses publicly available state and county data organized by the
        Healthy People 2030{" "}
        <a
          href="https://health.gov/healthypeople/priority-areas/social-determinants-health"
          target="_blank"
          rel="noopener noreferrer"
        >
          Social Determinants of Health Domains
        </a>{" "}
        to provide comparisons to state and national averages. You can see a
        county’s overall performance by domain by clicking on the <b>Monitor</b>{" "}
        tab within this tool, or viewing the <b>Appendix</b> at the end of this
        report. The indicators selected can help you understand the potential
        demand for substance use and mental health prevention, treatment, and
        recovery support services in a county.{" "}
      </div>
    )
  }

  return (
    <>
      <div className={myStyles.title}>ABOUT THE DATA</div>
      {reportType === "State" ? <GetStateText /> : <GetCountyText />}
    </>
  )
}
