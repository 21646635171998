import React from "react"

//styles
import mainStyles from "../../styles/Default/mainFooter.module.css"

//images
import dukeLogo from "../../assets/images/margolis-logo-white.png"
import facebookLogo from "../../assets/images/facebookLogo.png"
import twitterLogo from "../../assets/images/twitterLogo.png"
import youTubeLogo from "../../assets/images/youTubeLogo.png"

export default function MainFooter() {
  const navigationLists = [
    [
      { display: "People", URL: "https://healthpolicy.duke.edu/people" },
      {
        display: "Research",
        URL: "https://healthpolicy.duke.edu/research-policy",
      },
      { display: "Education", URL: "https://healthpolicy.duke.edu/education" },
      { display: "News", URL: "https://healthpolicy.duke.edu/news" },
      { display: "Events", URL: "https://healthpolicy.duke.edu/events" },
      { display: "Give", URL: "https://healthpolicy.duke.edu/make-gift" },
    ],
    [
      { display: "About", URL: "https://healthpolicy.duke.edu/about" },
      { display: "Careers", URL: "https://healthpolicy.duke.edu/careers" },
      {
        display: "Resource Portal",
        URL: "https://healthpolicy.duke.edu/center-resources-faculty-staff",
      },
      {
        display: "Contact Us",
        URL: "https://healthpolicy.duke.edu/contact-us-0",
      },
    ],
  ]

  return (
    <div className={mainStyles.mainContainer}>
      <div className={mainStyles.footer}>
        <div className={`mainFooterList ${mainStyles.navigation}`}>
          {navigationLists.map((list, index) => {
            return (
              <ul key={index}>
                {list.map((link, index) => (
                  <li className={mainStyles.navigationItem} key={index}>
                    <a
                      href={link.URL}
                      className={mainStyles.navigationItemHref}
                    >
                      {link.display}
                    </a>
                  </li>
                ))}
              </ul>
            )
          })}
          <div className={mainStyles.divider}></div>
          <div className={mainStyles.contact}>
            <p className={mainStyles.contactTitle}>Contact</p>
            <p>
              If you or your organization are interested in partnering with us,
              you can{" "}
              <a
                href="https://healthpolicy.duke.edu/contact-us-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us
              </a>
              .
            </p>
          </div>
        </div>
        {/* <div className={mainStyles.acknowledgeContainer}>
          <img src={ehLogo} alt="" className={mainStyles.ehLogo} />
          <div className={mainStyles.ehText}>
            The development of this public resource was funded by a grant from
            the Elevance Health Foundation Inc.
          </div>
        </div> */}

        <div className={mainStyles.logoContainer}>
          <img src={dukeLogo} alt="" className={mainStyles.dukeLogo} />
        </div>
      </div>
      <div className={mainStyles.footerConnectContainer}>
        <div className={mainStyles.footerConnect}>
          <ul className={mainStyles.footerList}>
            <li>
              {" "}
              <p className={mainStyles.footerConnectHeading}>Connect</p>
            </li>
            <li>
              <a
                href="https://www.facebook.com/DukeMargolis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebookLogo}
                  alt=""
                  className={mainStyles.footerConnectIcon}
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/dukemargolis"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitterLogo}
                  alt=""
                  className={mainStyles.footerConnectIcon2}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCKA519NlVObjkZnKRfWDkfg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={youTubeLogo}
                  alt=""
                  className={mainStyles.footerConnectIcon2}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
