import React, { useContext, useEffect, useRef, useState } from "react"

//Custom components
import Selector from "../../components/Selector/Selector"
import Choropleth from "../../components/maps/Choropleth"
import InfoModal from "./InfoModal"
import VariableOptions from "./VariableOptions"

//Helpers
import { getDataMap } from "../../helpers/dataMap"
import { GetBaseCountyGeoJson } from "../../helpers/getEnvVariableHelper"
import { getSelectionValueDisplay } from "../../helpers/valueHelper"
import getStateBoundsData from "../../helpers/getStateBounds"

//Styles
import myStyle from "../../styles/Default/mainMap.module.css"
import choroplethStyles from "../../styles/Default/choropleth.module.css"

//context
import { MainContext } from "../../context/mainContext"

//PrimeReact
import { Tooltip } from "primereact/tooltip"
import { Button } from "primereact/button"

//image
import relocateImg from "../../assets/images/mapbox-marker-icon-20px-blue-no-bottom.png"

export default function Mapper() {
  const mapBoxKey = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

  const [dataMap] = useState(getDataMap())
  const mainContext = useContext(MainContext)

  const [showSelector, setShowSelector] = useState(
    mainContext.location.state_fips === ""
  )
  const [refreshSelector, setRefreshSelector] = useState(false)

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [toggleCenterBoundingBox, setToggleCenterBoundingBox] =
    useState(undefined)

  const mainContextLocationRef = useRef(undefined)

  useEffect(() => {
    mainContextLocationRef.current = mainContext.location
  }, [mainContext.location])

  const countyData = mainContext.countyData

  //Mapping variables
  let geometryURL = GetBaseCountyGeoJson()

  if (!geometryURL) {
    geometryURL = `${window.location.protocol}//${window.location.host}/2010_us_20m_counties.geojson`
  }

  const getCustomLegend = (mapObject) => {
    if (!mapObject || mapObject.bins.length === 0) {
      return null
    }

    //Calculate the color for a given value and return a block with that color.
    // If the block is at the beginning, middle or end of the range, display the value as well
    const getBin = (value, index, binCount) => {
      const col = mapObject.colorScale(value[0])

      //Determine what the start and end value is of the bin range.
      let firstValue = value[0]
      let secondValue = value[1]
      //If there is only one value, always use the first value
      if (value.length === 1) {
        secondValue = firstValue
      }

      return (
        <>
          {" "}
          {index === 0 ? (
            <div className={myStyle.choroplethLegendValueFirst}>
              {getSelectionValueDisplay(
                mainContext.mapDataSelection.field,
                mapObject.numberFormatFunc(firstValue),
                true
              )}
            </div>
          ) : null}
          <div style={{ height: "20px" }}>
            <span
              className={choroplethStyles.choroplethLegendBox}
              style={{ backgroundColor: col.hex() }}
            ></span>
          </div>
          {index === binCount - 1 ? (
            <div className={myStyle.choroplethLegendValueLast}>
              {getSelectionValueDisplay(
                mainContext.mapDataSelection.field,
                mapObject.numberFormatFunc(secondValue),
                true
              )}
            </div>
          ) : null}
        </>
      )
    }

    if (mainContext.mapDataSelection.field.valueUnit !== "bool") {
      const bins = JSON.parse(JSON.stringify(mapObject.bins))

      return <>{bins.map((x, index) => getBin(x, index, bins.length))}</>
    } else {
      const bins = [[1], [0]]
      return <>{bins.map((x, index) => getBin(x, index, bins.length))}</>
    }
  }

  function getDataNote() {
    let note = ""

    switch (mainContext.location.optionType) {
      case "County":
        note = mainContext.mapDataSelection.field.dataNoteCounty
        break
      default:
        break
    }

    return !!note ? ` (${note})` : ""
  }

  function GetSelectionDisplay(mapObj) {
    if (!mainContext.location.label) {
      return null
    }

    let value = 0

    if (!!countyData) {
      if (mainContext.location.county_fips !== "") {
        const countyValue = countyData.filter(
          (loc) =>
            loc.county_FIPS === mainContext.location.county_fips &&
            loc.state_FIPS === mainContext.location.state_fips
        )
        value = countyValue[0][mainContext.mapDataSelection.field.name]
      } else if (mainContext.location.state_fips !== "") {
        const stateValue = mainContext.stateData.filter(
          (loc) => loc.state_FIPS === mainContext.location.state_fips
        )

        if (stateValue.length > 0) {
          value = +stateValue[0][mainContext.mapDataSelection.field.name]
        }
      } else {
        const nationalValue = mainContext.nationalData

        if (!!nationalValue) {
          value = +nationalValue[mainContext.mapDataSelection.field.name]
        }
      }
    }

    const DisplayLabel = () => {
      const displayValue = getSelectionValueDisplay(
        mainContext.mapDataSelection.field,
        value
      )

      return (
        <>
          {`${mainContext.location.label}: `}{" "}
          <span
            className={
              displayValue === "Not available" ? myStyle.valueNotAvailable : ""
            }
          >
            {displayValue}
          </span>{" "}
          {getDataNote()}
        </>
      )
    }

    return (
      <div className={myStyle.selectionDisplay}>
        <Tooltip target={".tt-legend"} />
        <div className={myStyle.selectionItemSelection}>
          {mainContext.mapDataSelection.field.displayName}
          <i
            className="pi pi-info-circle"
            style={{ cursor: "pointer", paddingLeft: "5px" }}
            onClick={() => {
              setShowInfoModal(true)
            }}
          />
        </div>
        <div className={myStyle.selectionItemDetail}>
          <DisplayLabel />
        </div>
        <div
          className={`${myStyle.selectionItemLegend} tt-legend`}
          data-pr-tooltip={mainContext.mapDataSelection.field.description}
          data-pr-position={"bottom"}
        >
          {getCustomLegend(mapObj)}
        </div>
      </div>
    )
  }

  if (!mainContext.mapDataSelection) {
    return null
  }

  const selectCounty = (geoId) => {
    if (!!mainContextLocationRef.current) {
      const county = mainContext.countyOptions.find((x) => x.mapId === geoId)
      if (!!county && county.state === mainContextLocationRef.current.state) {
        mainContext.setLocation(county)
        setRefreshSelector(true)
      } else if (
        !!county &&
        mainContextLocationRef.current.optionType === "National"
      ) {
        mainContext.setLocation(county)
        setRefreshSelector(true)

        //Fit to bounds
        const stateBounds = getStateBoundsData()
        const stateBoundingBox = stateBounds.filter(
          (x) => x.fips === county.state_fips
        )
        if (stateBoundingBox.length > 0) {
          //Zoom the bounds out a bit
          let bounds = stateBoundingBox[0].bounds

          mainContext.setMapBoundingBox([
            [bounds[0][0], bounds[0][1]],
            [bounds[1][0], bounds[1][1]],
          ])
          mainContext.setMapZoom(9)
        } else {
          mainContext.setMapBoundingBox(undefined)
          mainContext.setMapZoom(undefined)
        }
      }
    }
  }

  return (
    <div className={myStyle.mainMapContainer}>
      <div
        className={
          !mainContext.location.focusColumn && !mainContext.location.optionType
            ? myStyle.gridContainerNoSelection
            : myStyle.gridContainer
        }
      >
        <Selector
          show={showSelector}
          onHide={() => {
            setShowSelector(false)
          }}
          refresh={refreshSelector}
          onRefreshDone={() => {
            setRefreshSelector(false)
          }}
        />
        {mainContext.mapDataSelection ? (
          <InfoModal
            show={showInfoModal}
            onHide={() => {
              setShowInfoModal(false)
            }}
            infoType={mainContext.mapDataSelection.group.groupName}
            infoTypeDisplayName={mainContext.mapDataSelection.group.groupLabel}
            infoSectionName={mainContext.mapDataSelection.field.name}
            infoSectionDisplayName={
              mainContext.mapDataSelection.field.displayName
            }
          />
        ) : null}
        {!!mainContext.location.label ? (
          <>
            <div className={myStyle.gridItemSelection}>
              <div className={myStyle.selectionLabel}>
                <Tooltip target=".t-tipSelection" />
                <div
                  className={myStyle.relocateIconParent}
                  onClick={() => {
                    setToggleCenterBoundingBox(!toggleCenterBoundingBox)
                  }}
                >
                  <img
                    src={relocateImg}
                    alt=""
                    className={`t-tipSelection ${myStyle.relocateIcon}`}
                    data-pr-tooltip="Recenter map on selection"
                  />
                </div>
                {mainContext.location.label}
                <div
                  className="t-tipSelection"
                  data-pr-tooltip="Explore more locations"
                >
                  <i
                    className="pi pi-search"
                    style={{
                      cursor: "pointer",
                      fontSize: "xx-large",
                      marginLeft: `10px`,
                    }}
                    onClick={() => {
                      setShowSelector(true)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={myStyle.gridItemChangeSelection}>
              <Button
                className={`${myStyle.searchButton} p-0`}
                aria-label="Explore more"
                onClick={() => {
                  setShowSelector(true)
                }}
              >
                <i className="pi pi-search px-2"></i>
                <span className="px-3">Explore more locations</span>
              </Button>
            </div>
            <div
              className={
                !mainContext.location.focusColumn &&
                !mainContext.location.optionType
                  ? myStyle.gridItemMapNoSelect
                  : myStyle.gridItemMap
              }
            >
              <div style={{ height: "100%" }}>
                <Choropleth
                  mapBoxToken={mapBoxKey}
                  geojsonURL={geometryURL}
                  geojsonIdColName="GEO_ID"
                  valueIdColName="county_FIPS"
                  valueData={countyData}
                  valueKeyToGeoJsonId={"map_geo_id"}
                  filterColumn={mainContext.location.focusColumn}
                  filterValue={mainContext.location.focusValue}
                  valueFieldName={mainContext.mapDataSelection.field.name}
                  valueDisplayName={
                    mainContext.mapDataSelection.field.displayName
                  }
                  valueFieldType={mainContext.mapDataSelection.field.valueUnit}
                  valueDisplayPrefix={
                    mainContext.mapDataSelection.field.valueUnitPosition ===
                    "front"
                      ? mainContext.mapDataSelection.field.valueUnit
                      : ""
                  }
                  valueDisplaySuffix={
                    mainContext.mapDataSelection.field.valueUnitPosition ===
                    "back"
                      ? mainContext.mapDataSelection.field.valueUnit
                      : ""
                  }
                  colorScheme={mainContext.mapDataSelection.group.color}
                  boundingBox={mainContext.mapBoundingBox}
                  zoom={mainContext.mapZoom}
                  hideLegend={showSelector}
                  getCustomLegend={GetSelectionDisplay}
                  selectedIdValue={mainContext.location.mapId}
                  boundingBoxRecenterToggle={toggleCenterBoundingBox}
                  onFeatureClick={selectCounty}
                />
              </div>
            </div>
          </>
        ) : null}

        {!!mainContext.location.optionType ? (
          <VariableOptions
            onSetVariableSelection={mainContext.setMapDataSelection}
            selectedVariable={mainContext.mapDataSelection}
            dataMap={dataMap}
            onInfoClicked={() => {
              setShowInfoModal(true)
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
