import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Text, View, Link } from "@react-pdf/renderer"

import { PDFListItem } from "./PDFReportHelper"

export default function PDFReportMore() {
  const styles = reportStyles()

  return (
    <>
      <Text style={styles.title}>MORE RESOURCES</Text>

      <Text style={styles.textBold}>
        Opioid Settlement Information and Guidance
      </Text>
      <View style={{ flexDirection: "column" }}>
        <PDFListItem>
          <Link src="https://opioidprinciples.jhsph.edu/">
            Johns Hopkins Bloomberg School of Public Health Principles for the
            Use of Funds from The Opioid Litigation
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This website provides planning and process level guidance for state
            and local policymakers.
          </Text>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.naco.org/resources/opioid-solutions-center">
            National Association of Counties Opioid Solutions Center
          </Link>{" "}
          <br />
          <Text style={styles.textItalic}>
            This website provides resources for local leaders on effective
            treatment, recovery, prevention and harm reduction practices that
            save lives and address the underlying causes of substance use
            disorder. It includes links to briefs on approved strategies,
            technical assistance, and{" "}
            <Link src="https://www.naco.org/resources/opioid-solutions/planning-principles-toolkit">
              a set of custom planning tools for counties
            </Link>
            .
          </Text>
        </PDFListItem>
      </View>
      <Text style={styles.textBold}>Opioid Settlement Tracking</Text>
      <View style={{ flexDirection: "column" }}>
        <PDFListItem>
          <Link src="https://www.opioidsettlementtracker.com/">
            Opioid Settlement Tracker
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This site tracks opioid settlements and states’ opioid settlement
            spending plans.
          </Text>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.nashp.org/how-states-administering-opioid-settlement-funds/">
            National Academy for State Health Policy State Tracker
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This site includes information about enacted state legislation
            related to opioid settlement funds.
          </Text>
        </PDFListItem>
      </View>
      <Text style={styles.textBold}>Other Sources for County-Level Data</Text>
      <View style={{ flexDirection: "column" }}>
        <PDFListItem>
          <Link src="mailto:https://rsconnect.norc.org/recovery_ecosystem_index/">
            NORC Recovery Ecosystem Index
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This tool provides county-level maps to describe the community-level
            factors in place to support individuals in recovery from substance
            use disorder.
          </Text>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://opioidmisusetool.norc.org/">
            NORC Drug Overdose Deaths in the United States
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This tool provides county-level maps illustrating the relationship
            between community and population demographics and fatal drug
            overdoses—including opioids—in the United States.
          </Text>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.countyhealthrankings.org/">
            County Health Rankings and Roadmaps
          </Link>
          <br />
          <Text style={styles.textItalic}>
            This website provides data, evidence, guidance, and examples to
            build awareness of the multiple factors that influence health and
            support leaders in growing community power to improve health equity.
          </Text>
        </PDFListItem>
      </View>
    </>
  )
}
