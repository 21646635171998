import React from "react"

//react-pdf
import { Text, View } from "@react-pdf/renderer"

export default function PDFReportFooter({ text }) {
  const paragraphMarginTop = 10

  return (
    <>
      <View
        fixed
        style={{
          position: "absolute",
          bottom: "20px",
          left: 0,
          right: 0,
          paddingLeft: "0mm",
          paddingRight: "0mm",
        }}
      >
        <hr
          style={{
            border: "1px",
            borderStyle: "solid",
            borderColor: "#001A57",
            marginTop: paragraphMarginTop * 2,
            marginBottom: paragraphMarginTop,
          }}
        />
      </View>
      <View
        fixed
        style={{
          position: "absolute",
          bottom: "15px",
          right: "20px",
          fontFamily: "Open Sans",
          fontSize: 10,
          display: "flex",
          alignSelf: "flex-end",
          flexDirection: "row",
          color: "gray",
        }}
      >
        <Text style={{ marginRight: "25px" }}>{text} </Text>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  )
}
