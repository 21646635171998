import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Link, Page, Text, View } from "@react-pdf/renderer"

import { PDFListItem, PDFRecommendTitleStyle } from "./PDFReportHelper"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

export default function PDFReportRecommendSocial({
  needLevel,
  needLevelStyle,
  footerText,
}) {
  const styles = reportStyles()
  const TitleStyle = PDFRecommendTitleStyle(needLevelStyle)
  return (
    <Page size="A4" style={styles.page}>
      <PDFReportHeader />
      <Text
        style={{
          ...styles.subTitle2,
          ...TitleStyle,
        }}
      >
        Social: {needLevel}
      </Text>
      <Text style={styles.bodyHighlighted}>
        Peer Recovery Support/Recovery Coach
      </Text>
      <Text>
        <Link src="https://www.samhsa.gov/sites/default/files/programs_campaigns/brss_tacs/peer-support-2017.pdf">
          Peer recovery support
        </Link>{" "}
        and recovery coaching include a wide range of services between people
        who share similar experiences with mental health conditions, substance
        use disorders, or both. Trained and certified peers provide non-clinical
        support in assisting people and their families with their recovery
        through sharing their own lived experience, practical guidance, and
        connection to other resources and tools. The use of peer recovery
        support services to support individuals with substance use disorders is
        growing both for outreach and engagement and ongoing long-term support.{" "}
      </Text>
      <Text style={styles.Paragraph}>
        Peer support paraprofessionals practice in a variety of settings
        including peer-run organizations, recovery community organizations,
        community centers, recovery residences, homeless shelters, criminal
        justice settings, and health care settings. While inconsistent
        implementation and research methods inhibit broad conclusions, some
        research suggests that peer recovery support is associated with reduced
        substance use and reoccurrence of SUD rates, increased retention in SUD
        treatment, and improved satisfaction with treatment (Gormley et al.,
        2021).
      </Text>
      <Text style={styles.Paragraph}>
        Please refer to the{" "}
        <Text style={styles.textBold}>Recovery Support Services</Text> section
        within the <Text style={styles.textBold}>Health Care domain</Text> for
        more information on Peer Recovery Support and other Recovery Support
        Services.
      </Text>
      <hr style={styles.line} />
      <Text style={{ ...styles.bodyHighlighted, ...styles.Paragraph }}>
        Mutual-Aid Support Meeting
      </Text>
      <Text>
        Mutual-Aid Support Meetings are community-based or virtual self-help
        programs to address substance use such as Alcoholics Anonymous (AA) or
        Narcotics Anonymous. They can include behavioral, spiritual, and
        cognitive components—{" "}
        <Link src="https://facesandvoicesofrecovery.org/resources/mutual-aid-resources/">
          this website
        </Link>{" "}
        includes a guide to different models used around the country. A review
        of AA and similar twelve-step facilitation programs found that these
        interventions usually produced higher rates of continuous abstinence
        from alcohol (opioids were not studied) compared to other treatments
        (e.g., cognitive behavioral therapy) (Kelly et al., 2020).
      </Text>
      <Text style={{ ...styles.textBold, ...styles.Paragraph }}>Examples:</Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://www.na.org/">Narcotics Anonymous</Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.aa.org/">Alcoholics Anonymous</Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.smartrecovery.org/">
            SMART (Self-Management and Recovery Training) Recovery
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://al-anon.org/">Al-Anon Family Groups</Link>
        </PDFListItem>
      </View>
      <PDFReportFooter text={footerText} />
    </Page>
  )
}
