import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportSources() {
  return (
    <>
      <div className={myStyles.title}>SOURCES</div>
      <div className={myStyles.body}>
        <p className={myStyles.source}>
          Aliprantis, D., & Schweitzer, M. E. (2018). Opioids and the Labor
          Market (2018 version). Working Paper, 18–07.
          <a
            href="https://doi.org/10.26509/frbc-wp-201807"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.26509/frbc-wp-201807
          </a>
        </p>
        <p className={myStyles.source}>
          Anderson, M., Himmelright, M., Kwong, J., Chait, N., Kearns, M.,
          Gibbons, R., McNulty, L., & Swanstrom, J. (2017). Opioids in Medicare
          Part D: Concerns about Extreme Use and Questionable Prescribing
          (OEI-02-17-00250; HHS OIG Data Brief). U.S. Department of Health and
          Human Services: Office of the Inspector General.
          <a
            href="https://oig.hhs.gov/oei/reports/oei-02-17-00250.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://oig.hhs.gov/oei/reports/oei-02-17-00250.pdf
          </a>
        </p>
        <p className={myStyles.source}>
          Azagba, S., Shan, L., Qeadan, F., & Wolfson, M. (2021). Unemployment
          rate, opioids misuse and other substance abuse: Quasi-experimental
          evidence from treatment admissions data. BMC Psychiatry, 21(1), 22.
          <a
            href="https://doi.org/10.1186/s12888-020-02981-7"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1186/s12888-020-02981-7
          </a>
        </p>
        <p className={myStyles.source}>
          Bassuk, E. L., Hanson, J., Greene, R. N., Richard, M., & Laudet, A.
          (2016). Peer-Delivered Recovery Support Services for Addictions in the
          United States: A Systematic Review. Journal of substance abuse
          treatment, 63, 1–9. doi: 10.1016/j.jsat.2016.01.003
        </p>
        <p className={myStyles.source}>
          Beheshti, D. (2022). The Impact of Opioids on the Labor Market:
          Evidence from Drug Rescheduling. Journal of Human Resources, 0320.
          <a
            href="https://doi.org/10.3368/jhr.0320-10762R1"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.3368/jhr.0320-10762R1
          </a>
        </p>
        <p className={myStyles.source}>
          Belcher, H. M. E., & Shinitzky, H. E. (1998). Substance Abuse in
          Children: Prediction, Protection, and Prevention. Archives of
          Pediatrics & Adolescent Medicine, 152(10).{" "}
          <a
            href="https://doi.org/10.1001/archpedi.152.10.952"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1001/archpedi.152.10.952
          </a>
        </p>
        <p className={myStyles.source}>
          Centers for Medicare & Medicaid Services. (n.d.). Medicare Part D
          Opioid Prescribing Mapping Tool. Retrieved January 5, 2023, from{" "}
          <a
            href="https://cms-oeda.maps.arcgis.com/apps/MapSeries/index.html?appid=5390718d875d4c049b1ac5976a9ff083"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://cms-oeda.maps.arcgis.com/apps/MapSeries/index.html?appid=5390718d875d4c049b1ac5976a9ff083
          </a>
        </p>
        <p className={myStyles.source}>
          Cho, D., Garcia, D. I., Board of Governors of the Federal Reseve
          System, Montes, J., & Weingarden, A. (2021). Labor Market Effects of
          the Oxycodone-Heroin Epidemic (No. 2021–025; Finance and Economics
          Discussion Series 2021-025, pp. 1–41). Board of Governors of the
          Federal Reseve System.{" "}
          <a
            href="https://www.federalreserve.gov/econres/feds/labor-market-effects-of-the-oxycodone-heroin-epidemic.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.federalreserve.gov/econres/feds/labor-market-effects-of-the-oxycodone-heroin-epidemic.htm
          </a>
        </p>
        <p className={myStyles.source}>
          County Health Rankings. (2022). Unemployment. County Health Rankings &
          Roadmaps.{" "}
          <a
            href="https://www.countyhealthrankings.org/explore-health-rankings/county-health-rankings-model/health-factors/social-economic-factors/employment/unemployment"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.countyhealthrankings.org/explore-health-rankings/county-health-rankings-model/health-factors/social-economic-factors/employment/unemployment
          </a>
        </p>
        <p className={myStyles.source}>
          Dahlhamer, J. M., Connor, E. M., Bose, J., Lucas, J. W., & Zelaya, C.
          E. (2021). Prescription opioid use among adults with chronic pain:
          United States, 2019 (No. 162). National Center for Health Statistics.{" "}
          <a
            href="https://doi.org/10.15620/cdc:107641"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.15620/cdc:107641
          </a>
        </p>
        <p className={myStyles.source}>
          Dean, A., & Kimmel, S. (2019). Free trade and opioid overdose death in
          the United States. SSM - Population Health, 8, 100409.{" "}
          <a
            href="https://doi.org/10.1016/j.ssmph.2019.100409"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.ssmph.2019.100409
          </a>
        </p>
        <p className={myStyles.source}>
          Eddie, D., Hoffman, L., Vilsaint, C., Abry, A., Bergman, B., Hoeppner,
          B., Weinstein, C., & Kelly, J. F. (2019). Lived Experience in New
          Models of Care for Substance Use Disorder: A Systematic Review of Peer
          Recovery Support Services and Recovery Coaching. Frontiers in
          Psychology, 10.{" "}
          <a
            href="https://doi.org/10.3389/fpsyg.2019.01052"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.3389/fpsyg.2019.01052
          </a>
        </p>
        <p className={myStyles.source}>
          Ellis, M. S., Kasper, Z. A., & Cicero, T. J. (2020). The impact of
          opioid use disorder on levels of educational attainment: Perceived
          benefits and consequences. Drug and Alcohol Dependence, 206, 107618.{" "}
          <a
            href="https://doi.org/10.1016/j.drugalcdep.2019.107618"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.drugalcdep.2019.107618
          </a>
        </p>
        <p className={myStyles.source}>
          Fernandes, R. M., Cary, M., Duarte, G., Jesus, G., Alarcão, J., Torre,
          C., Costa, S., Costa, J., & Carneiro, A. V. (2017). Effectiveness of
          needle and syringe Programmes in people who inject drugs - An overview
          of systematic reviews. BMC public health, 17(1), 309.
        </p>
        <p className={myStyles.source}>
          Fiorentine, R. (1999). After Drug Treatment: Are 12-Step Programs
          Effective in Maintaining Abstinence? The American Journal of Drug and
          Alcohol Abuse, 25(1), 93–116.{" "}
          <a
            href="https://doi.org/10.1081/ADA-100101848"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1081/ADA-100101848
          </a>
        </p>
        <p className={myStyles.source}>
          Fothergill, K. E., Ensminger, M. E., Green, K. M., Crum, R. M.,
          Robertson, J., & Juon, H.-S. (2008). The Impact of Early School
          Behavior and Educational Achievement on Adult Drug Use Disorders: A
          Prospective Study. Drug and Alcohol Dependence, 92(1–3), 191–199.{" "}
          <a
            href="https://doi.org/10.1016/j.drugalcdep.2007.08.001"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.drugalcdep.2007.08.001
          </a>
        </p>
        <p className={myStyles.source}>
          Ghertner, R., & Groves, L. (2018). The Opioid Crisis and Economic
          Opportunity: Geographic and Economic Trends [ASPE Research Brief].
          U.S. Department of Health and Human Services.
        </p>
        <p className={myStyles.source}>
          Gorman, A. (2019, January 23). Inside the Specialized “Recovery” High
          Schools Designed Just for Teens With Addiction. Time.{" "}
          <a
            href="https://time.com/5509829/sober-high-school-addiction/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://time.com/5509829/sober-high-school-addiction/
          </a>
        </p>
        <p className={myStyles.source}>
          Gormley, M. A., Pericot-Valverde, I., Diaz, L., Coleman, A.,
          Lancaster, J., Ortiz, E., Moschella, P., Heo, M., & Litwin, A. H.
          (2021). Effectiveness of peer recovery support services on stages of
          the opioid use disorder treatment cascade: A systematic review. Drug
          and alcohol dependence, 229(Pt B), 109123.{" "}
          <a
            href="https://pubmed.ncbi.nlm.nih.gov/34700201/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://pubmed.ncbi.nlm.nih.gov/34700201/
          </a>{" "}
        </p>
        <p className={myStyles.source}>
          Harm Reduction. (n.d.). Retrieved January 16, 2023, from{" "}
          <a
            href="https://www.samhsa.gov/find-help/harm-reduction"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.samhsa.gov/find-help/harm-reduction
          </a>
        </p>
        <p className={myStyles.source}>
          Harris, M. C., Kessler, L. M., Murray, M. N., & Glenn, B. (2020).
          Prescription Opioids and Labor Market Pains: The Effect of Schedule II
          Opioids on Labor Force Participation and Unemployment. Journal of
          Human Resources, 55(4), 1319–1364.
        </p>
        <p className={myStyles.source}>
          Henkel, D. (2011). Unemployment and substance use: A review of the
          literature (1990-2010). Current Drug Abuse Reviews, 4(1), 4–27.{" "}
          <a
            href="https://doi.org/10.2174/1874473711104010004"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.2174/1874473711104010004
          </a>
        </p>
        <p className={myStyles.source}>
          Hill, K. G., Bailey, J. A., Steeger, C. M., Hawkins, J. D., Catalano,
          R. F., Kosterman, R., Epstein, M., & Abbott, R. D. (2020). Outcomes of
          Childhood Preventive Intervention Across 2 Generations. JAMA
          Pediatrics, 174(8), 1–8.{" "}
          <a
            href="https://doi.org/10.1001/jamapediatrics.2020.1310"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1001/jamapediatrics.2020.1310
          </a>
        </p>
        <p className={myStyles.source}>
          Hyde,Serah. (2019, March). Does increased opioid use lead to declines
          in labor market participation? : Monthly Labor Review: U.S. Bureau of
          Labor Statistics. Monthly Labor Review.{" "}
          <a
            href="https://www.bls.gov/opub/mlr/2019/beyond-bls/does-increased-opioid-use-lead-to-declines-in-labor-market-participation.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bls.gov/opub/mlr/2019/beyond-bls/does-increased-opioid-use-lead-to-declines-in-labor-market-participation.htm
          </a>
        </p>
        <p className={myStyles.source}>
          Jones, E. B., Staab, E. M., Wan, W., Quinn, M. T., Schaefer, C.,
          Gedeon, S., Campbell, A., Chin, M. H., & Laiteerapong, N. (2020).
          Addiction Treatment Capacity in Health Centers: The Role of Medicaid
          Reimbursement and Targeted Grant Funding. Psychiatric Services, 71(7),
          684–690.{" "}
          <a
            href="https://doi.org/10.1176/appi.ps.201900409"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1176/appi.ps.201900409
          </a>
        </p>
        <p className={myStyles.source}>
          Joudrey, P. J., Edelman, E. J., & Wang, E. A. (2019). Drive Times to
          Opioid Treatment Programs in Urban and Rural Counties in 5 US States.
          JAMA, 322(13), 1310–1312.{" "}
          <a
            href="https://doi.org/10.1001/jama.2019.12562"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1001/jama.2019.12562
          </a>
        </p>
        <p className={myStyles.source}>
          Kelleher, K. J., Famelia, R., Yilmazer, T., Mallory, A., Ford, J.,
          Chavez, L. J., & Slesnick, N. (2021). “Prevention of opioid use
          disorder: The HOME (housing, opportunities, motivation and engagement)
          feasibility study.” Harm Reduction Journal, 18(1), 112.{" "}
          <a
            href="https://doi.org/10.1186/s12954-021-00560-x"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1186/s12954-021-00560-x
          </a>
        </p>
        <p className={myStyles.source}>
          Kelly J. F., Fallah-Sohy, N., Vilsaint C, Hoffman LA, Jason LA, Stout
          RL, Cristello JV, & Hoeppner BB (2020). New kid on the block: An
          investigation of the physical, operational, personnel, and service
          characteristics of recovery community centers in the United States.
          Journal of Substance Abuse Treatment, 111, 1–10.
          10.1016/j.jsat.2019.12.009
        </p>
        <p className={myStyles.source}>
          Kelly, J. F., Humphreys, K., & Ferri, M. (2020). Alcoholics Anonymous
          and other 12-step programs for alcohol use disorder. The Cochrane
          database of systematic reviews, 3(3), CD012880. Accessed 16 January
          2023. DOI: 10.1002/14651858.CD012880.pub2.{" "}
        </p>
        <p className={myStyles.source}>
          KFF. (2020). Individuals Reporting Needing but Not Receiving Treatment
          for Illicit Drug Use in the Past Year. KFF.{" "}
          <a
            href="https://www.kff.org/other/state-indicator/individuals-reporting-needing-but-not-receiving-treatment-for-illicit-drug-use-in-the-past-year/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.kff.org/other/state-indicator/individuals-reporting-needing-but-not-receiving-treatment-for-illicit-drug-use-in-the-past-year/
          </a>
        </p>
        <p className={myStyles.source}>
          Kleinman, R. A. (2020). Comparison of Driving Times to Opioid
          Treatment Programs and Pharmacies in the US. JAMA Psychiatry, 77(11),
          1163–1171.{" "}
          <a
            href="https://doi.org/10.1001/jamapsychiatry.2020.1624"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1001/jamapsychiatry.2020.1624
          </a>
        </p>
        <p className={myStyles.source}>
          Kuklinski, M. R., Oesterle, S., Briney, J. S., & Hawkins, J. D.
          (2021). Long-term Impacts and Benefit-Cost Analysis of the Communities
          That Care Prevention System at Age 23, 12 Years After Baseline.
          Prevention science : the official journal of the Society for
          Prevention Research, 22(4), 452–463. 10.1007/s11121-021-01218-7{" "}
        </p>
        <p className={myStyles.source}>
          Langabeer, J., Chambers, K., Cardenas-Turanzas, M., &
          Champagne-Langabeer, T. (2020). County-level factors underlying opioid
          mortality in the United States. Substance Abuse, 43, 1–7.{" "}
          <a
            href="https://doi.org/10.1080/08897077.2020.1740379"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1080/08897077.2020.1740379
          </a>
        </p>
        <p className={myStyles.source}>
          Laudet, A., Harris, K., Winters, K., Moberg, D., & Kimball, T. (2015).
          Results from the first nationwide survey of students in collegiate
          recovery programs. Drug and Alcohol Dependence, 146, e170.{" "}
          <a
            href="https://doi.org/10.1016/j.drugalcdep.2014.09.378"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.drugalcdep.2014.09.378
          </a>
        </p>
        <p className={myStyles.source}>
          Mclellan, A. T., Hagan, T. A., Levine, M., Gould, F., Meyers, K.,
          Bencivengo, M., & Durell, J. (1998). Supplemental social services
          improve outcomes in public addiction treatment. Addiction, 93(10),
          1489–1499.{" "}
          <a
            href="https://doi.org/10.1046/j.1360-0443.1998.931014895.x"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1046/j.1360-0443.1998.931014895.x
          </a>
        </p>
        <p className={myStyles.source}>
          Mutual Support Groups and Self-Help Programs—RHIhub Substance Use
          Disorder Toolkit. (n.d.). Retrieved January 16, 2023, from{" "}
          <a
            href="https://www.ruralhealthinfo.org/toolkits/substance-abuse/2/recovery/support-groups"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.ruralhealthinfo.org/toolkits/substance-abuse/2/recovery/support-groups
          </a>
        </p>
        <p className={myStyles.source}>
          National Association of Recovery Residences. (2011). An Introduction
          and Membership Invitation from the National Association of Recovery
          Residences [White Paper].
        </p>
        <p className={myStyles.source}>
          National Association of Recovery Residences. (2016). Recovery
          Residence Levels of Support. National Association of Recovery
          Residences.{" "}
          <a
            href="https://narronline.org/wp-content/uploads/2016/12/NARR_levels_summary.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://narronline.org/wp-content/uploads/2016/12/NARR_levels_summary.pdf
          </a>
        </p>
        <p className={myStyles.source}>
          O’Brien, P., Alikhan, S., Cummings, N., Hohlbauch, A., Hughey, L.,
          Schrader, K., Tehrani, A. B., & White, M. (2018). Medicaid Coverage of
          Medication-Assisted Treatment for Alcohol and Opioid Use Disorders and
          of Medication for the Reversal of Opioid Overdose (No. SMA-18-5093;
          HHS Publication, p. 119). Substance Abuse and Mental Health Services
          Administration.
        </p>
        <p className={myStyles.source}>
          Office, U. S. G. A. (n.d.). Substance Use Disorder: Medicaid Coverage
          of Peer Support Services for Adults. Retrieved January 16, 2023, from{" "}
          <a
            href="https://www.gao.gov/products/gao-20-616"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.gao.gov/products/gao-20-616
          </a>
        </p>
        <p className={myStyles.source}>
          Park-Lee, E., Lipari, R. N., Hedden, S. L., Kroutil, L. A., & Porter,
          J. D. (2012). Receipt of Services for Substance Use and Mental Health
          Issues Among Adults: Results from the 2016 National Survey on Drug Use
          and Health. In CBHSQ Data Review. Substance Abuse and Mental Health
          Services Administration (US).{" "}
          <a
            href="http://www.ncbi.nlm.nih.gov/books/NBK481724/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.ncbi.nlm.nih.gov/books/NBK481724/
          </a>
        </p>
        <p className={myStyles.source}>
          Peer Support Workers for those in Recovery. (n.d.). Retrieved January
          16, 2023, from{" "}
          <a
            href="https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers
          </a>
        </p>
        <p className={myStyles.source}>
          Pfefferle, S. G., Karon, S. S., & Wyant, B. (2019). Choice Matters:
          Housing Models that May Promote Recovery for Individuals and Families
          Facing Opioid Use Disorder (p. 44). U.S. Department of Health and
          Human Services Assistant Secretary for Planning and Evaluation Office
          of Disability, Aging and Long-Term Care Policy.
        </p>
        <p className={myStyles.source}>
          Powell, D. (2021). The Labor Supply Consequences of the Opioid Crisis
          (Working Paper WR-A1484-1; RAND Education and Labor). RAND
          Corporation.
        </p>
        <p className={myStyles.source}>
          Powell, D., Pacula, R. L., & Taylor, E. (2020). How increasing medical
          access to opioids contributes to the opioid epidemic: Evidence from
          Medicare Part D. Journal of Health Economics, 71, 102286.{" "}
          <a
            href="https://doi.org/10.1016/j.jhealeco.2019.102286"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.jhealeco.2019.102286
          </a>
        </p>
        <p className={myStyles.source}>
          Prevention. (2014, March 31). National Institute on Drug Abuse.
          Retrieved January 16, 2023, from{" "}
          <a
            href="https://nida.nih.gov/research-topics/prevention"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://nida.nih.gov/research-topics/prevention
          </a>
        </p>
        <p className={myStyles.source}>
          Preventing Drug Misuse and Addiction: The Best Strategy. National
          Institute on Drug Abuse.{" "}
          <a
            href="https://nida.nih.gov/publications/drugs-brains-behavior-science-addiction/preventing-drug-misuse-addiction-best-strategy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://nida.nih.gov/publications/drugs-brains-behavior-science-addiction/preventing-drug-misuse-addiction-best-strategy
          </a>
        </p>
        <p className={myStyles.source}>
          Recovery and Recovery Support. (n.d.). Substance Abuse and Mental
          Health Services Administration. Retrieved January 16, 2023, from{" "}
          <a
            href="https://www.samhsa.gov/find-help/recovery"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.samhsa.gov/find-help/recovery
          </a>
        </p>
        <p className={myStyles.source}>
          Rabinowitz, J. A., Reboussin, B. A., Thrul, J., Drabick, D. A. G.,
          Kahn, G., Green, K. M., Ialongo, N. S., Huhn, A. S., & Maher, B. S.
          (2022). Early Childhood Behavioral and Academic Antecedents of
          Lifetime Opioid Misuse among Urban Youth. Journal of Clinical Child
          and Adolescent Psychology: The Official Journal for the Society of
          Clinical Child and Adolescent Psychology, American Psychological
          Association, Division 53, 51(6), 864–876.{" "}
          <a
            href="https://doi.org/10.1080/15374416.2021.1875324"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1080/15374416.2021.1875324
          </a>
        </p>
        <p className={myStyles.source}>
          Ray, B., Watson, D. P., Xu, H., Salyers, M. P., Victor, G., Sightes,
          E., Bailey, K., Taylor, L. R., & Bo, N. (2021). Peer recovery services
          for persons returning from prison: Pilot randomized clinical trial
          investigation of SUPPORT. Journal of Substance Abuse Treatment, 126,
          108339.{" "}
          <a
            href="https://doi.org/10.1016/j.jsat.2021.108339"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.jsat.2021.108339
          </a>
        </p>
        <p className={myStyles.source}>
          Reif, S., George, P., Braude, L., Dougherty, R. H., Daniels, A. S.,
          Ghose, S. S., & Delphin-Rittmon, M. E. (2014). Recovery Housing:
          Assessing the Evidence. Psychiatric Services, 65(3), 295–300.{" "}
          <a
            href="https://doi.org/10.1176/appi.ps.201300243"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1176/appi.ps.201300243
          </a>
        </p>
        <p className={myStyles.source}>
          Robertson, E. B., Sims, B. E., & Reider, E. E. (2016). Principles of
          Substance Abuse Prevention for Early Childhood: A Research-Based Guide
          (p. 94). National Institute on Drug Abuse; National Institutes of
          Health; U.S. Department of Health and Human Services.{" "}
          <a
            href="https://nida.nih.gov/sites/default/files/early_childhood_prevention_march_2016_508.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://nida.nih.gov/sites/default/files/early_childhood_prevention_march_2016_508.pdf
          </a>
        </p>
        <p className={myStyles.source}>
          Rural Health Information Hub. (2020, November 23). Mutual Support
          Groups and Self-Help Programs—RHIhub Substance Use Disorder Toolkit.
          RHIHub.{" "}
          <a
            href="https://www.ruralhealthinfo.org/toolkits/substance-abuse/2/recovery/support-groups"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.ruralhealthinfo.org/toolkits/substance-abuse/2/recovery/support-groups
          </a>
        </p>
        <p className={myStyles.source}>
          SAMHSA. (2021). Key Substance Use and Mental Health Indicators in the
          United States: Results from the 2020 National Survey on Drug Use and
          Health.
        </p>
        <p className={myStyles.source}>
          SAMHSA. (2022a, September 26). Affordable Housing Models and Recovery.
          SAMHSA: Substance Abuse and Mental Health Services Administration.{" "}
          <a
            href="https://www.samhsa.gov/homelessness-programs-resources/hpr-resources/affordable-housing-models-recovery"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.samhsa.gov/homelessness-programs-resources/hpr-resources/affordable-housing-models-recovery
          </a>
        </p>
        <p className={myStyles.source}>
          SAMHSA. (2022b, September 27). Peer Support Workers for those in
          Recovery. SAMHSA: Substance Abuse and Mental Health Services
          Administration.{" "}
          <a
            href="https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.samhsa.gov/brss-tacs/recovery-support-tools/peers
          </a>
        </p>
        <p className={myStyles.source}>
          The McShin Foundation. (2021). 2021 Year in Review. McShin Foundation:
          Recovery Resource Foundation.{" "}
          <a
            href="https://mcshin.org/about-us/data/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mcshin.org/about-us/data/
          </a>
        </p>
        <p className={myStyles.source}>
          Tyler, J., & Darolia, R. (2020, April 13). The opioid crisis and
          community-level spillovers onto children’s education. Brookings.{" "}
          <a
            href="https://www.brookings.edu/research/the-opioid-crisis-and-community-level-spillovers-onto-childrens-education/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.brookings.edu/research/the-opioid-crisis-and-community-level-spillovers-onto-childrens-education/
          </a>
        </p>
        <p className={myStyles.source}>
          van Draanen, J., Tsang, C., Mitra, S., Karamouzian, M., & Richardson,
          L. (2020). Socioeconomic marginalization and opioid-related overdose:
          A systematic review. Drug and Alcohol Dependence, 214, 108127.{" "}
          <a
            href="https://doi.org/10.1016/j.drugalcdep.2020.108127"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.drugalcdep.2020.108127
          </a>
        </p>
        <p className={myStyles.source}>
          Zemore, S. E., Gilbert, P. A., Pinedo, M., Tsutsumi, S., McGeough, B.,
          & Dickerson, D. L. (2021). Racial/Ethnic Disparities in Mutual Help
          Group Participation for Substance Use Problems. Alcohol Research :
          Current Reviews, 41(1), 03.{" "}
          <a
            href="https://doi.org/10.35946/arcr.v41.1.03"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.35946/arcr.v41.1.03
          </a>
        </p>
      </div>
    </>
  )
}
