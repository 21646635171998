import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Text, View } from "@react-pdf/renderer"

import { PDFListItem } from "./PDFReportHelper"

export default function PDFReportRecommend({
  highNeed,
  moderateNeed,
  lowNeed,
  maintain,
}) {
  const styles = reportStyles()

  const NoNeedsText = ({ category }) => {
    return (
      <Text style={{ ...styles.textItalic, ...styles.Paragraph }}>
        There are no domains in the {category} Need category
      </Text>
    )
  }

  return (
    <>
      <View style={styles.recommendationView}>
        <Text style={{ ...styles.title, ...styles.recommendationTitle }}>
          RECOVERY INVESTMENT OPPORTUNITIES{" "}
        </Text>
        <View style={styles.recommendationItemsView}>
          <View style={styles.recommendationViewItem}>
            <Text style={{ ...styles.subTitle, color: "red" }}>High Need</Text>

            <Text style={styles.textRegular}>
              These are domains where your county scored significantly worse
              than the national average on our Need Index based on public data
              sources. These areas represent priority focus areas for settlement
              dollar activities.
            </Text>
            {highNeed.length > 0 ? (
              <View style={styles.listContainer}>
                {highNeed.map((x) => (
                  <PDFListItem key={x}>{x}</PDFListItem>
                ))}
              </View>
            ) : (
              <NoNeedsText category={"High"} />
            )}
          </View>
          <View style={styles.recommendationViewItem}>
            <Text style={{ ...styles.subTitle, color: "#ED7C31" }}>
              Moderate Need
            </Text>
            <Text style={styles.textRegular}>
              These are domains where your county scored worse than the national
              average on our Need Index based on public data sources. These
              areas represent focus areas for settlement dollar activities.
            </Text>
            {moderateNeed.length > 0 ? (
              <View style={styles.listContainer}>
                {moderateNeed.map((x) => (
                  <PDFListItem key={x}>{x}</PDFListItem>
                ))}
              </View>
            ) : (
              <NoNeedsText category={"Moderate"} />
            )}
          </View>
          <View style={styles.recommendationViewItem}>
            <Text style={{ ...styles.subTitle, color: "#D3A005" }}>
              Low Need
            </Text>
            <Text>
              These are domains where your county scored slightly worse than the
              national average on our Need Index based on public data sources.
              These areas represent potential focus areas for settlement dollar
              activities.
            </Text>
            {lowNeed.length > 0 ? (
              <View style={styles.listContainer}>
                {lowNeed.map((x) => (
                  <PDFListItem key={x}>{x}</PDFListItem>
                ))}
              </View>
            ) : (
              <NoNeedsText category={"Low"} />
            )}
          </View>{" "}
          <View style={styles.recommendationViewItem}>
            <Text style={{ ...styles.subTitle, color: "green" }}>
              Lowest Need
            </Text>
            <Text>
              These are domains where your county scored similar or better than
              national averages on our Need Index.
            </Text>
            {maintain.length > 0 ? (
              <View style={styles.listContainer}>
                {maintain.map((x) => (
                  <PDFListItem key={x}>{x}</PDFListItem>
                ))}
              </View>
            ) : (
              <NoNeedsText category={"Lowest"} />
            )}
          </View>
        </View>
      </View>
    </>
  )
}
