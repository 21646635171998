import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommendEconomy({ needLevel, needLevelStyle }) {
  return (
    <>
      <div
        className={`${myStyles.mainContainer} ${myStyles.mainContainerRecommendItem} ${needLevelStyle}`}
      >
        <div className={myStyles.mainBody}>
          <div className={`${myStyles.subTitle2} ${myStyles.noMargin}`}>
            Economic Stability: {needLevel}
          </div>
        </div>
      </div>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <br />
          <div className={myStyles.bodyHighlighted}>Recovery Housing</div>
          <div className={myStyles.body}>
            <p>
              Recovery housing includes a broad range of housing options,
              including housing for individuals transitioning from homelessness,
              a treatment facility, or an individual’s own home. Individuals
              living in recovery housing receive essential supports to initiate
              and sustain long-term addiction recovery. Establishing safe and
              secure housing options as part of the recovery process can
              contribute to improved recovery outcomes. A lack of housing
              creates fragmentation in treatment continuums, where hospitals and
              other high intensity clinical programs struggle in their ability
              to place individuals in safe and supportive community-based
              housing.
            </p>
            <p>
              Research on the effectiveness of recovery housing suggests
              improved substance use outcomes, as well as improvements in
              functioning, decreased criminal activity, and improved employment
              behavior.
            </p>
            <p>
              Some states, such as Florida, Massachusetts, and Utah, have
              established state certification and licensure programs for
              recovery housing to formally increase oversight. Other states
              provide training and technical assistance to recovery housing
              managers.
            </p>
            <p>
              Here are examples of local Recovery Housing models currently being
              implemented in communities:
            </p>
            <ul>
              <li>
                <a
                  href="https://mashsoberhousing.org/about-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Massachusetts Alliance for Sober Housing
                </a>
              </li>
              <li>
                <a
                  href="https://www.nhcorr.org/about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New Hampshire Coalition of Recovery Residences{" "}
                </a>
              </li>
              <li>
                <a
                  href="https://www.oxfordhouse.org/oxford_house_history"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Oxford House
                </a>
              </li>
            </ul>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>
            Re-Entry Support Services
          </div>
          <div className={myStyles.body}>
            <p>
              Individuals with substance use disorders involved with the
              criminal justice system often face many obstacles accessing
              quality{" "}
              <a
                href="https://www.samhsa.gov/sites/default/files/topics/criminal_juvenile_justice/reentry-resources-for-consumers-providers-communities-states.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                behavioral health services
              </a>
              . In addition to behavioral health access, other barriers include
              a lack of job skills, overarching health care needs, and stable
              housing. Though studies have shown that SUD treatment programs for
              incarcerated individuals are generally effective, those studies
              also show that discharged individuals of these treatment programs
              are likely to remain substance free if they receive continuing
              treatment or interventions in the community. Indeed, studies have
              found that with individuals engaged in support services that
              provide meaningful ongoing hope and recovery support and personal
              interaction with those living in recovery have lower recidivism
              rates when compared to individuals who did not participate.
            </p>
            <p>
              Re-entry support services, especially for individuals with SUD,
              are currently funded in{" "}
              <a
                href="https://www.samhsa.gov/sites/default/files/topics/criminal_juvenile_justice/reentry-resources-for-consumers-providers-communities-states.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                several ways
              </a>
              . Grant programs include the Offender Reentry Program (ORP) that
              expands and enhance substance use treatment services for
              individuals reintegrating into communities after being released
              from correctional facilities. In addition, SAMHSA actively
              partners with other federal agencies to address the myriad of
              issues related to offender reentry through policy changes,
              recommendations to U.S. states and local governments, and
              elimination of myths surrounding offender reentry. In addition,
              SAMHSA provides resources for communities, local jurisdictions,
              and state policymakers to support individuals returning from jails
              and prisons, such as a{" "}
              <a
                href="https://www.addictioncounselorce.com/articles/101286/apic.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Best Practice Approach publication
              </a>
              and{" "}
              <a
                href="https://store.samhsa.gov/product/Medicaid-Coverage-and-Financing-of-Medications-to-Treat-Alcohol-and-Opioid-Use-Disorders/sma14-4854"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medicaid Coverage options
              </a>
              .
            </p>
            <p>
              Here are additional examples of Re-Entry Programs currently or
              previously implemented across the country:
            </p>
            <ul>
              <li>
                <a
                  href="https://bja.ojp.gov/sites/g/files/xyckuh186/files/media/document/BJA-2016-9094.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Second Chance Act Reentry Program for Adults with Co-Occurring
                  Substance Abuse and Mental Disorders
                </a>
              </li>
              <li>
                <a
                  href="https://www.rand.org/pubs/research_reports/RR572.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Substance Use Treatment and Reentry (STAR) Program
                </a>
              </li>
              <li>
                <a
                  href="https://www.mow.uscourts.gov/reentry-court"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Missouri Re-Entry Courts{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
