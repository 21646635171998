import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportQuestions() {
  return (
    <>
      <div className={myStyles.title}>QUESTIONS ABOUT THIS TOOL?</div>
      <div className={myStyles.body}>
        Please email <a href="mailto:frank.mcstay@duke.edu">Frank McStay</a>{" "}
        with any questions. While our capacity to provide direct one-on-one
        support regarding use of this tool is limited, we may be able to link
        you to a number of existing state and county level resources providing
        technical assistance on opioid settlement funding.
      </div>
    </>
  )
}
