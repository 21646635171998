import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

//ScoreCard
import ScoreCard from "../ScoreCard/Index"

export default function ReportAppendix() {
  return (
    <>
      <div className={myStyles.title}>Appendix</div>
      <div className={myStyles.body}>
        <ScoreCard reportMode={true} />
      </div>
    </>
  )
}
