import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import dukeHeader from "../../assets/images/margolis-logo-white.png"

//styles
import mainStyles from "../../styles/Default/mainHeader.module.css"

//helpers
import getPath from "../../helpers/routeHelper"

//Primereact
import { Tooltip } from "primereact/tooltip"

//Components
import Selector from "../../components/Selector/Selector"

//context
import { MainContext } from "../../context/mainContext"

export default function MainHeader() {
  const navigate = useNavigate()
  const { setShowTutorial } = useContext(MainContext)

  const [showSelector, setShowSelector] = useState(false)
  const [refreshSelector, setRefreshShowSelector] = useState(false)

  function navigateToPath(path) {
    switch (path) {
      case "Explore":
        navigate(getPath("explore"))
        break
      case "Monitor":
        navigate(getPath("monitor"))
        break
      case "About":
        navigate(getPath("about"))
        break
      case "Faq":
        navigate(getPath("faq"))
        break
      default:
        navigate(getPath("explore"))
    }
  }

  return (
    <>
      <Selector
        show={showSelector}
        refresh={refreshSelector}
        onHide={() => {
          setShowSelector(false)
        }}
      />
      <div className={mainStyles.mainContainer}>
        <div className={mainStyles.header}>
          <div className={mainStyles.dukeLogo}>
            <img
              src={dukeHeader}
              alt=""
              className={`${mainStyles.dukeLogoImage} ${mainStyles.dukeLogo}`}
            />
          </div>

          <div className={mainStyles.navigation}>
            <div className={mainStyles.optionsGridContainer}>
              <div
                className={`${mainStyles.optionItem} ${mainStyles.optionItemExplore}`}
                onClick={() => navigateToPath("Explore")}
              >
                Explore
              </div>
              <div
                className={`${mainStyles.optionItem} ${mainStyles.optionItemMonitor}`}
                onClick={() => navigateToPath("Monitor")}
              >
                Monitor
              </div>
              <div
                className={`${mainStyles.optionItem} ${mainStyles.optionItemAbout}`}
                onClick={() => navigateToPath("About")}
              >
                About
              </div>
              <div
                className={`${mainStyles.optionItem} ${mainStyles.optionItemFaq}`}
                onClick={() => navigateToPath("Faq")}
              >
                FAQ
              </div>
              <div className={mainStyles.optionItemTutorial}>
                <Tooltip target={".tt-tut"} />
                <i
                  className="pi pi-question-circle tt-tut"
                  style={{ cursor: "pointer", paddingLeft: "5px" }}
                  data-pr-tooltip={"Show tutorial"}
                  onClick={() => {
                    setRefreshShowSelector(true)
                    setShowTutorial(true)
                    setShowSelector(true)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
