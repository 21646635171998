import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import chroma from "chroma-js"
import ReactSpeedometer from "react-d3-speedometer"

//context
import { MainContext } from "../../context/mainContext"

//Styling
import myStyles from "../../styles/Default/scoreCard.module.css"

//Custom components
import Selector from "../../components/Selector/Selector"
import InfoModal from "./InfoModal"
import InfoModalOpportunities from "./InfoModalOpportunities"
import LegendModal from "./LegendModal"

//Helpers
import { getDataMap } from "../../helpers/dataMap"
import getPath, { getImgPath } from "../../helpers/routeHelper"
import { getDisplayValue } from "../../helpers/valueHelper"

//PrimeReact
import { Tooltip } from "primereact/tooltip"
import { Button } from "primereact/button"

//images
import imgInfo from "../../assets/images/BlueInfoIcon.png"

export default function ScoreCard({ reportMode, ref, style }) {
  const navigate = useNavigate()
  const mainContext = useContext(MainContext)

  const [dataMap] = useState(getDataMap())
  const [score, setScore] = useState({ fips: "" })
  const [variableData, setVariableData] = useState(undefined)
  const [variableStateData, setVariableStateData] = useState(undefined)
  const [variableNationalData, setVariableNationalData] = useState(undefined)

  const [showSelector, setShowSelector] = useState(
    mainContext.location.state_fips === ""
  )
  const [showInfo, setShowInfo] = useState(false)
  const [showRIOInfo, setShowRIOInfo] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  const [assetClass, setAssetClass] = useState({
    group: undefined,
    assetClass: undefined,
  })

  const [highNeed, setHighNeed] = useState([])
  const [moderateNeed, setModerateNeed] = useState([])
  const [lowNeed, setLowNeed] = useState([])
  const [maintain, setMaintain] = useState([])

  const locationFips = `${mainContext.location.state_fips}${mainContext.location.county_fips}`

  function GetScoreHeaders() {
    const headers = [
      { style: myStyles.scoreItemGroup, value: "" },
      {
        style: myStyles.scoreItemIndex,
        value: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
            }}
          >
            <span>Need Index </span>
            {!reportMode ? (
              <img
                src={imgInfo}
                alt="info"
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowLegend(true)
                }}
              />
            ) : null}
          </span>
        ),
      },
      {
        style: `${myStyles.scoreItemNeed} ${myStyles.scoreHeaderTextAlignStart}`,
        value: "Potential Need (variable measures)",
      },
      {
        style: `${myStyles.scoreItemCounty} ${myStyles.gridItemMinWidth100}`,
        value: "County",
      },
      {
        style: `${myStyles.scoreItemState} ${myStyles.gridItemMinWidth100}`,
        value: "State",
      },
      {
        style: `${myStyles.scoreItemNational} ${myStyles.gridItemMinWidth100}`,
        value: " Nat'l Avg",
      },
      {
        style: myStyles.scoreItemClass,
        value: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "40px",
            }}
          >
            <span>Recovery Investment Opportunities </span>
            {!reportMode ? (
              <img
                src={imgInfo}
                alt="info"
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowRIOInfo(true)
                }}
              />
            ) : null}
          </span>
        ),
      },
    ]

    return (
      <div className={myStyles.scoreGridContainerHeader}>
        {headers.map((head, index) => (
          <div
            key={index}
            className={`${head.style} ${
              index === 0 ? myStyles.scoreHeaderFirst : myStyles.scoreHeader
            }`}
          >
            {head.value}
          </div>
        ))}
      </div>
    )
  }

  function calculateScoreBuckets(scoreDataValues, scoreDataType) {
    if (!!scoreDataValues && !!scoreDataType) {
      const scoreValues = scoreDataValues.filter((x) =>
        scoreDataType === "State"
          ? x.GEOID === mainContext.location.state
          : x.fips === locationFips
      )

      if (scoreValues.length > 0) {
        let high = []
        let low = []
        let moderate = []
        let maintain = []

        dataMap.forEach((group) => {
          const scoreValue = +scoreValues[0][group.groupName]
          const scoreCardValues = scoreDataValues
            .filter((x) => !!+x[group.groupName])
            .map((x) => +x[group.groupName])
          const dataCount = scoreCardValues.length

          const mean =
            scoreCardValues.reduce((accumulator, currentValue) => {
              return accumulator + currentValue
            }, 0) / dataCount

          const stdDeviation = Math.sqrt(
            scoreCardValues
              .map((x) => Math.pow(x - mean, 2))
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue
              ) / dataCount
          )

          const upperLimit = mean + stdDeviation
          const lowerLimit = mean - stdDeviation

          if (scoreValue < lowerLimit) {
            maintain.push(group.groupName)
          } else if (scoreValue >= lowerLimit && scoreValue < mean) {
            low.push(group.groupName)
          } else if (scoreValue >= mean && scoreValue < upperLimit) {
            moderate.push(group.groupName)
          } else if (scoreValue >= upperLimit) {
            high.push(group.groupName)
          } else {
            console.error(`Unknown classification: ${group.groupName}`)
          }
        })

        setHighNeed(high)
        setLowNeed(low)
        setModerateNeed(moderate)
        setMaintain(maintain)
      }
    }
  }

  function GetScoreGauge({ categoryName, score }) {
    const minValue = -2
    const maxValue = 2
    let scoreValue = +score

    if (score === undefined || isNaN(scoreValue)) {
      return null
    } else {
      if (scoreValue < minValue) {
        scoreValue = minValue
      } else if (scoreValue > maxValue) {
        scoreValue = maxValue
      }

      //let color = getScoreColor(score).hex() Note: This will calculate a color based on the Choropleth range
      let color = "gray"
      let valueText = "Unknown"

      if (highNeed.some((x) => x === categoryName)) {
        valueText = "High need"
        color = "red"
        scoreValue = 1.5
      } else if (moderateNeed.some((x) => x === categoryName)) {
        valueText = "Moderate need"
        color = "#FF9900"
        scoreValue = 0.5
      } else if (lowNeed.some((x) => x === categoryName)) {
        valueText = "Low need"
        color = "#99CC00"
        scoreValue = -0.5
      } else if (maintain.some((x) => x === categoryName)) {
        valueText = "Lowest need"
        color = "green"
        scoreValue = -1.5
      }

      return (
        <div style={{ width: "100%", height: "120px" }}>
          <ReactSpeedometer
            fluidWidth={true}
            maxSegmentLabels={0}
            minValue={minValue}
            maxValue={maxValue}
            value={scoreValue}
            segments={2}
            segmentColors={[color, "lightgray"]}
            needleHeightRatio={0.5}
            customSegmentStops={[-2, scoreValue, 2]}
            currentValueText={valueText}
            needleTransitionDuration={0}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    if (
      !!mainContext.scoreCardDataState &&
      !!mainContext.location.optionType &&
      mainContext.location.optionType === "State"
    ) {
      //Get state scores
      const findScore = mainContext.scoreCardDataState.filter(
        (x) => x.GEOID === mainContext.location.state
      )
      setScore(findScore.length > 0 ? findScore[0] : undefined)
    } else if (
      !!mainContext.scoreCardDataCounty &&
      !!mainContext.location.optionType
    ) {
      //Get county scores
      const findScore = mainContext.scoreCardDataCounty.filter(
        (x) => x.fips === locationFips
      )
      setScore(findScore.length > 0 ? findScore[0] : undefined)
    } else {
      setScore(undefined)
    }

    if (!!mainContext.countyData && !!mainContext.location.optionType) {
      const findValues = mainContext.countyData.filter(
        (x) => x.fips === locationFips
      )

      setVariableData(findValues.length > 0 ? findValues[0] : undefined)

      if (mainContext.location.optionType === "State") {
        calculateScoreBuckets(
          mainContext.scoreCardDataState,
          mainContext.location.optionType
        )
      } else {
        calculateScoreBuckets(
          mainContext.scoreCardDataCounty,
          mainContext.location.optionType
        )
      }
    }
  }, [
    mainContext.location,
    mainContext.scoreCardDataCounty,
    mainContext.countyData,
  ])

  useEffect(() => {
    if (!!mainContext.nationalData) {
      //Copy national data
      let nationalValues = JSON.parse(JSON.stringify(mainContext.nationalData))

      //Look for any grouping data that requires average calculations
      dataMap.forEach((group) => {
        group.columnNames.forEach((col) => {
          if (col.calculateAverage === true) {
            //Get the total
            let nationalTotal = mainContext.countyData.reduce(
              (prev, current) =>
                prev + (!!current[col.name] ? current[col.name] : 0),
              0
            )

            //calculate the average
            nationalValues[col.name] =
              nationalTotal / mainContext.countyData.length
          }
        })
      })

      setVariableNationalData(nationalValues)
    }
  }, [mainContext.nationalData, mainContext.countyData])

  useEffect(() => {
    if (!!mainContext.stateData && !!mainContext.location.state_fips) {
      let stateCountyData = undefined
      let stateSourceValues = mainContext.stateData.filter(
        (state) => state.state_FIPS === mainContext.location.state_fips
      )

      let stateValues = {
        state: stateSourceValues[0].Name,
        state_FIPS: stateSourceValues[0].state_FIPS,
      }

      dataMap.forEach((group) => {
        stateValues[group.groupName] = {}

        group.columnNames.forEach((col) => {
          if (col.calculateAverage === true) {
            //Get the county data per state
            if (!stateCountyData) {
              stateCountyData = mainContext.countyData.filter(
                (x) => x.state_FIPS === mainContext.location.state_fips
              )
            }
            //Get the total per state
            let stateTotal = stateCountyData.reduce(
              (prev, current) =>
                prev + (!!current[col.name] ? current[col.name] : 0),
              0
            )

            //calculate the state average
            stateValues[group.groupName][col.name] =
              stateTotal / stateCountyData.length
          } else {
            if (stateSourceValues.length > 0) {
              stateValues[group.groupName][col.name] =
                stateSourceValues[0][col.name]
            }
          }
        })
      })

      setVariableStateData(stateValues)

      /* 
      if (stateValues.length > 0) {
        let stateAvg = {
          state: stateValues[0].state,
          state_FIPS: stateValues[0].state_FIPS,
        }

        dataMap.forEach((group) => {
          stateAvg[group.groupName] = {}

          group.columnNames.forEach((col) => {
            //Get the total per state
            stateAvg[group.groupName][col.name] = stateValues.reduce(
              (prev, current) =>
                prev + (!!current[col.name] ? current[col.name] : 0),
              0
            )

            //calculate the state average
            stateAvg[group.groupName][col.name] =
              stateAvg[group.groupName][col.name] / stateValues.length
          })
        })

        setVariableStateData(stateAvg) */
    }
  }, [
    mainContext.stateData,
    mainContext.countyData,
    mainContext.location.state_fips,
  ])

  function getScoreColor(value) {
    const scale = chroma.scale(["Red", "Yellow", "Green"]).domain([2, -2])
    return scale(value)
  }

  function GetBucketDescription(group) {
    let description = "No description available"

    if (highNeed.some((x) => x === group.groupName)) {
      description =
        "High need: These are domains where your county scored significantly worse than the national average on our Need Index based on public data sources. These areas represent priority focus areas for settlement dollar activities."
    } else if (lowNeed.some((x) => x === group.groupName)) {
      description =
        "Low need: These are domains where your county scored slightly worse than the national average on our Need Index based on public data sources. These areas represent potential focus areas for settlement dollar activities."
    } else if (moderateNeed.some((x) => x === group.groupName)) {
      description =
        "Moderate need: These are domains where your county scored worse than the national average on our Need Index based on public data sources. These areas represent focus areas for settlement dollar activities."
    } else if (maintain.some((x) => x === group.groupName)) {
      description =
        "Monitor/Maintain: These are domains where your county scored similar or better than national averages on our Need Index."
    }

    return description
  }

  function GetScoreCards({ group }) {
    const getVariableValue = (col, index) => {
      if (!!mainContext.countyData && locationFips !== "") {
        if (!!variableData) {
          const displayValue = !col.hideCountyOnMonitor
            ? getDisplayValue(
                variableData[col.name],
                col.valueUnitPosition,
                col.valueUnit,
                col.decimalPrecision
              )
            : "Not available"

          return (
            <div
              className={
                displayValue === "Not available"
                  ? myStyles.valueNotAvailable
                  : ""
              }
            >
              {displayValue}
            </div>
          )
        }
      }

      return null
    }

    const getNationalValue = (col, index) => {
      if (!!variableNationalData) {
        const displayValue = getDisplayValue(
          variableNationalData[col.name],
          col.valueUnitPosition,
          col.valueUnit,
          col.decimalPrecision
        )

        return (
          <div
            className={
              displayValue === "Not available" ? myStyles.valueNotAvailable : ""
            }
          >
            {displayValue}
          </div>
        )
      }
    }

    const getOtherVariableValue = (source, col, index) => {
      if (!!mainContext.countyData && locationFips !== "") {
        if (!!source) {
          const displayValue = getDisplayValue(
            source[group.groupName][col.name],
            col.valueUnitPosition,
            col.valueUnit,
            col.decimalPrecision
          )

          return (
            <div
              className={
                displayValue === "Not available"
                  ? myStyles.valueNotAvailable
                  : ""
              }
            >
              {displayValue}
              {/*               {parseFloat(source[group.groupName][col.name]).toFixed(2) ||
                "N/A"} */}
            </div>
          )
        }
      }

      return null
    }

    let myScore = undefined
    if (!!score) {
      myScore = score[group.groupName]
    }

    return (
      <div className={myStyles.scoreGridContainer} style={style}>
        <Tooltip target={".scoreItemGroup"} />
        <div
          className={`${myStyles.scoreItemGroup} scoreItemGroup`}
          style={{ backgroundColor: group.panelColor }}
        >
          <div className={myStyles.centerAndJustifyContainer}>
            <div className={myStyles.groupGridContainer}>
              <div className={myStyles.groupItemIcon}>
                <div className={myStyles.centerAndJustifyContainer}>
                  <img
                    src={getImgPath(`icons/${group.iconName}`)}
                    alt=""
                    className={myStyles.icon}
                  />
                </div>
              </div>
              <div className={`${myStyles.groupItemName}`}>
                {group.groupLabel}
              </div>
            </div>
          </div>
        </div>
        <div className={`${myStyles.scoreItemIndex}`}>
          <div className={myStyles.centerAndJustifyContainer}>
            {!!myScore ? (
              <GetScoreGauge score={myScore} categoryName={group.groupName} />
            ) : (
              <div
                className={`${myStyles.scoreIndicator}  scoreItemGroup`}
                style={{
                  backgroundColor: getScoreColor(myScore),
                }}
                data-pr-tooltip={GetBucketDescription(group)}
              ></div>
            )}
          </div>
        </div>
        <div
          className={`${myStyles.scoreItemNeed} ${myStyles.scoreItemNeedNoPadding}`}
        >
          {group.columnNames.map((col, index) => (
            <div className={`${myStyles.valuesGridContainer}`} key={index}>
              <div
                className={`${myStyles.valueItemNeed} ${
                  index === group.columnNames.length - 1
                    ? myStyles.scoreNeedItemLast
                    : myStyles.scoreNeedItem
                }`}
              >
                {col.displayName}
              </div>
              <div
                className={`${myStyles.valueItemCounty} ${
                  myStyles.gridItemMinWidth100
                } ${
                  index === group.columnNames.length - 1
                    ? myStyles.scoreNeedItemLast
                    : myStyles.scoreNeedItem
                }`}
              >
                {getVariableValue(col, index)}
              </div>
              <div
                className={`${myStyles.valueItemState}  ${
                  myStyles.gridItemMinWidth100
                } ${
                  index === group.columnNames.length - 1
                    ? myStyles.scoreNeedItemLast
                    : myStyles.scoreNeedItem
                }`}
              >
                {getOtherVariableValue(variableStateData, col, index)}
              </div>
              <div
                className={`${myStyles.valueItemNational} ${
                  myStyles.gridItemMinWidth100
                }  ${
                  index === group.columnNames.length - 1
                    ? myStyles.scoreNeedItemLast
                    : myStyles.scoreNeedItem
                }`}
              >
                {getNationalValue(col, index)}
              </div>
            </div>
          ))}
        </div>

        <div className={`${myStyles.scoreItemClass}`}>
          {group.recoveryAssetClasses.map((col, index) => (
            <div
              key={index}
              className={
                index === group.recoveryAssetClasses.length - 1
                  ? myStyles.needClassItemLast
                  : myStyles.needClassItem
              }
              onClick={() => {
                setAssetClass({ group: group, assetClass: col })
                setShowInfo(true)
              }}
              style={{
                height: `${90 / group.recoveryAssetClasses.length}%`,
                backgroundColor: group.panelColor,
              }}
            >
              {col.displayName}
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <Selector
        show={showSelector}
        onHide={() => {
          setShowSelector(false)
        }}
      />
      <InfoModal
        show={showInfo}
        onHide={() => setShowInfo(false)}
        infoTypeDisplayName={
          assetClass.group ? assetClass.group.groupLabel : undefined
        }
        infoSectionDisplayName={
          assetClass.assetClass ? assetClass.assetClass.displayName : undefined
        }
        infoSectionName={
          assetClass.assetClass ? assetClass.assetClass.name : undefined
        }
      />
      <InfoModalOpportunities
        show={showRIOInfo}
        onHide={() => setShowRIOInfo(false)}
      />
      <LegendModal
        show={showLegend}
        onHide={() => {
          setShowLegend(false)
        }}
        getScoreColor={getScoreColor}
      />
      <div className={myStyles.mainGridContainer} id={"scoreCard"}>
        <div className={myStyles.mainGridItemSelection}>
          {!!mainContext.location.label ? (
            <>
              <div className={myStyles.selectionLabel}>
                <Tooltip target=".t-tipSelection" />
                {mainContext.location.label}
                {!reportMode ? (
                  <i
                    className="pi pi-search t-tipSelection"
                    style={{
                      cursor: "pointer",
                      fontSize: "xx-large",
                      marginLeft: `10px`,
                    }}
                    onClick={() => {
                      setShowSelector(true)
                    }}
                    data-pr-tooltip="Explore more locations"
                  />
                ) : null}
              </div>
              <div
                className={myStyles.reportButtonContainer}
                hidden={!!reportMode}
              >
                <Button
                  className={myStyles.reportButton}
                  label="Generate Report"
                  onClick={() => {
                    navigate(`/${getPath("report")}`, { replace: true })
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className={myStyles.mainGridItemBody}>
          {GetScoreHeaders()}
          {dataMap.map((group, index) => (
            <GetScoreCards group={group} key={index} />
          ))}
        </div>
      </div>
    </>
  )
}
