import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Text, Link } from "@react-pdf/renderer"

export default function PDFReportQuestions() {
  const styles = reportStyles()

  return (
    <>
      <Text style={styles.title}>QUESTIONS ABOUT THIS TOOL?</Text>
      <Text style={styles.textRegular}>
        Please email{" "}
        <Link src="mailto:frank.mcstay@duke.edu">Frank McStay</Link> with any
        questions. While our capacity to provide direct one-on-one support
        regarding use of this tool is limited, we may be able to link you to a
        number of existing state and county level resources providing technical
        assistance on opioid settlement funding.
      </Text>
    </>
  )
}
