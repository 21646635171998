import Papa from "papaparse"
import CSVData from "../assets/data/recovery_class_copy.csv"

export default function getRecoveryClassData(onDataRetrieved) {
  return fetch(CSVData)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          onDataRetrieved(result)
        },
      })
    })
}
