import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommendEnvironment({
  needLevel,
  needLevelStyle,
}) {
  return (
    <>
      <div
        className={`${myStyles.mainContainer} ${myStyles.mainContainerRecommendItem} ${needLevelStyle}`}
      >
        <div className={myStyles.mainBody}>
          <div className={`${myStyles.subTitle2} ${myStyles.noMargin}`}>
            Environment: {needLevel}
          </div>
        </div>
      </div>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <br />
          <div className={myStyles.bodyHighlighted}>Prevention Services</div>
          <div className={myStyles.body}>
            <p>
              Prevention services and programs aim to prevent substance use or
              misuse to reduce the likelihood of developing a substance use
              disorder. These services are provided in different settings in the
              home, clinic, or community, and can be tailored to meet people’s
              needs at different stages of life (NIDA, 2014). For example, many
              SUD prevention programs focus on children and adolescents given
              that use of drugs early on in life increases an individual’s
              chance of developing an SUD. There are a number of evidence-based
              prevention programs that work to increase protective factors and
              reduce risk factors for drug use (NIDA, n.d.).
            </p>
            <span className={myStyles.exampleHeader}>Examples:</span>
            <ul>
              <li>
                <a
                  href="https://www.communitiesthatcare.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Communities that Care Prevention System
                </a>{" "}
                — A structured approach that helps communities utilize their
                resources most effectively to address identified risk factors
                for substance use, aggression, and other problems in youth. One
                study showed that a $602 investment in each child (in 2017
                dollars) had yielded an estimated $7,754 in savings by the time
                participants were age 23 (Kuklinski, 2021).
              </li>
              <li>
                <a
                  href="https://themartinsburginitiative.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Martinsburg Initiative
                </a>{" "}
                — The initiative is based on the science of Adverse Childhood
                Experiences (ACEs) that shows how early childhood experiences
                shape the lives of adults. The program creates a partnership
                between police, schools, community, health, and education to
                address substance use in families.
              </li>
              <li>
                <a
                  href="https://depts.washington.edu/sdrg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Raising Healthy Children
                </a>{" "}
                — Initially developed in Seattle, in this intervention,
                elementary age children receive lessons targeted at enhancing
                children’s opportunities for forming healthy bonds and
                development of social skills and reinforcements. Research has
                shown improvement across many health outcomes for participants,
                including a reduced likelihood to report using drugs or alcohol
                as a teenager (Hill, 2020).
              </li>
            </ul>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>
            Harm Reduction Services
          </div>
          <div className={myStyles.body}>
            <p>
              Harm reduction is an approach that “meets people where they are”
              by directly engaging with people who use drugs in order to prevent
              overdose, reduce spread of infectious disease, and improve overall
              well-being. Harm reduction services can save lives by being
              available and accessible in a way that emphasizes the need for
              compassion toward people who use drugs (SAMSHA, n.d.). Harm
              reduction programs are part of a comprehensive approach to
              preventing or reducing harms associated with drug use. They can
              include a wide range of services including the provision of
              overdose reversal through naloxone, overdose reversal education,
              supplies, and training, provision of fentanyl test strips, syringe
              service programs, sobering and crisis stabilization centers, and
              connection to overdose education, counseling and referral tol
              treatment (Centers for Disease Control and Prevention, 2010;
              Fernandes et al., 2017; Legal Action Center, n.d.). The federal
              government is already supporting harm reduction{" "}
              <a
                href="https://www.samhsa.gov/grants/grant-announcements/sp-22-001"
                target="_blank"
                rel="noopener noreferrer"
              >
                through grants
              </a>
              , and{" "}
              <a
                href="https://harmreductionhelp.cdc.gov/s/"
                target="_blank"
                rel="noopener noreferrer"
              >
                technical assistance
              </a>
              , which may be available to your community.
            </p>
            <ol type="1">
              <li>
                <p>
                  <a
                    href="https://www.samhsa.gov/medication-assisted-treatment/medications-counseling-related-conditions/naloxone"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Naloxone
                  </a>{" "}
                  — Naloxone (Narcan®) is a medication designed to rapidly
                  reverse an opioid overdose. First responders and community
                  members including caregivers can learn how to safely
                  administer naloxone through training programs on naloxone
                  administration and distribute free kits through
                  community-based organizations and pharmacies.{" "}
                  <a
                    href="http://legislativeanalysis.org/wp-content/uploads/2022/09/Naloxone-Access-Summary-of-State-Laws.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Many states
                  </a>{" "}
                  have passed laws that make it easier for community members to
                  dispense naloxone in an emergency.
                  <br />
                </p>
                <span className={myStyles.exampleHeader}>Examples:</span>
                <ul>
                  <li>
                    <a
                      href="https://www.nyc.gov/site/doh/health/health-topics/naloxone.page#:~:text=How%20to%20get%20a%20free,receive%20a%20kit%20by%20mail."
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      New York City’s Naloxone Distribution Program
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://odh.ohio.gov/know-our-programs/violence-injury-prevention-program/projectdawn/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ohio Project DAWN (Deaths Avoided With Naloxone)
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.northcarolinahealthnews.org/2022/11/09/vending-machines-dispensing-free-life-saving-medication/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      North Carolina Naloxone Vending Machines in Jails
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tippingthepainscale.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tipping The Pain Scale Film featuring Community Outreach{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://store.samhsa.gov/product/Opioid-Overdose-Prevention-Toolkit/SMA18-4742"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SAMSHA’s Opioid Overdose Prevention Toolkit
                    </a>
                  </li>
                </ul>
                <br />
              </li>
              <li>
                <p>
                  <a
                    href="https://nida.nih.gov/research-topics/syringe-services-programs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Syringe Services Programs
                  </a>{" "}
                  — Syringe services programs (SSPs) are community-based
                  prevention programs that can provide a range of services,
                  including connection to substance use disorder treatment;
                  access to and disposal of sterile syringes and injection
                  equipment; and vaccination, testing, and linkage to care and
                  treatment for infectious diseases.
                  <br />
                </p>
                <span className={myStyles.exampleHeader}>Examples:</span>
                <ul>
                  <li>
                    <a
                      href="https://fsph.iupui.edu/doc/research-centers/Indiana Syringe Exchange Program 2015.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Indiana Syringe Exchange Program
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://louisvilleky.gov/government/health-wellness/harm-reduction-outreach-services-formerly-syringe-services"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kentucky Harm Reduction Outreach Services
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://harmreduction.org/issues/establishing-rural-programs/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Establishing a Syringe Services Program in Rural America
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.naco.org/resources/opioid-solutions/approved-strategies/ssps"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      National Association of Counties Brief on Syringe Services
                      Program for Counties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <a
                    href="https://www.cdc.gov/stopoverdose/fentanyl/fentanyl-test-strips.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fentanyl Test Strips
                  </a>{" "}
                  —Fentanyl test strips are small strips of paper that can
                  detect the presence of fentanyl in pills, powder, and
                  injectables. The strips are low-cost (approximately $1 each),{" "}
                  <a
                    href="https://www.btnx.com/HarmReduction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    simple to use
                  </a>
                  , and can reduce the risk of overdose. Drug checking,
                  including use of fentanyl test strips is not legal in all
                  states, however many states have taken steps to decriminalize
                  use of strips in efforts to reduce stigma and provide
                  individuals and communities with additional tools to address
                  the opioid epidemic. In April 2021, the U.S. Centers for
                  Disease Control and Prevention and the Substance Abuse and
                  Mental Health Services Administration (SAMHSA)
                  <a
                    href="https://www.cdc.gov/media/releases/2021/p0407-Fentanyl-Test-Strips.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    announced
                  </a>{" "}
                  that federal funding could be used to purchase strips.
                  <br />
                </p>
                <span className={myStyles.exampleHeader}>Examples:</span>
                <ul>
                  <li>
                    <a
                      href="https://www.dhs.wisconsin.gov/news/releases/101322.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wisconsin State Program
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://harmreduction.org/issues/fentanyl/fentanyl-test-strip-pilot/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      San Francisco Pilot
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <a
                    href="https://www.chcf.org/publication/sobering-centers-explained/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sobering
                  </a>{" "}
                  or crisis stabilization centers offer a cost-effective
                  alternative for providing care to intoxicated adults whose
                  public alcohol or drug use puts themselves or others at risk.
                  Too often people who are intoxicated without any other
                  criminal justice or health stabilization need are brought to
                  emergency departments for evaluation. This overburdens
                  high-acute health care infrastructure with a population that
                  is better served often in alternative settings designed to
                  support public safety and encourage individuals engagement in
                  treatment services.
                </p>
                <span className={myStyles.exampleHeader}>Examples:</span>
                <ul>
                  <li>Houston Recovery Center: Sobering Center</li>
                  <li>Baltimore City Stabilization Center</li>
                </ul>
              </li>
              <li>
                <p>
                  Addressing Stigma.{" "}
                  <a
                    href="https://www.hopkinsmedicine.org/stigma-of-addiction/#what-is-stigma-of-addiction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stigma towards people who use drugs
                  </a>{" "}
                  is based on the belief that addiction is a personal choice and
                  the result of a lack of willpower or individual failing.
                  Stigma within the community and in health care settings can{" "}
                  <a
                    href="https://doi.org/10.1016/j.drugalcdep.2006.10.014"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    negatively impact the health
                  </a>{" "}
                  and well-being of people with substance use disorders and the
                  quality of care they receive. States and localities can
                  address stigma through education, training, and public
                  awareness campaigns.
                </p>
                <span className={myStyles.exampleHeader}>Examples:</span>
                <ol type="a">
                  <li>
                    <a
                      href="https://www.cdc.gov/drugoverdose/od2a/pdf/od2a-stigma-reduction-case-study-508.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Vermont Stigma/Harm Reduction Training Case Study and
                      Evaluation
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.opioidlibrary.org/featured_collection/reducing-stigma-surrounding-substance-use-disorders-creating-a-community-based-anti-stigma-initiative/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Collection of Resources on Creating a Community-based
                      Anti-Stigma Initiative
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.shatterproof.org/our-work/ending-addiction-stigma/how-you-can-fight-stigma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Shatterproof Resources on Stigma
                    </a>
                  </li>
                </ol>
              </li>
            </ol>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>
            Recovery Community Centers
          </div>
          <div className={myStyles.body}>
            <p>
              Recovery Community Centers (RCCs) are non-profit centers that
              offer support services to individuals in recovery such as peer
              support, employment and housing resources, and opportunities to
              connect with others. These centers are typically operated by peers
              who have lived experience with addiction and recovery. RCCs are
              not residences or places where individuals can receive clinical
              treatment but instead provide an array of training and other
              non-clinical community-based supports. RCCs typically welcome all
              recovery support modalities and encourage multiple pathways to
              recovery.{" "}
            </p>
            <p>
              Though peer-reviewed evidence is limited, one study of 32 RCCs
              across the Northeast found that RCCs were associated with
              increased self-reported personal and social resources that
              facilitate the process of recovery (e.g., recovery capital), which
              were associated with improvements in self-esteem, quality of life,
              and psychological distress (Kelly, 2020). RCCs are typically
              supported in part by time-limited grants, including state funding
              from federal block grants.
            </p>
            <span className={myStyles.exampleHeader}>Examples:</span>
            <ul>
              <li>
                <a
                  href="https://www.chfs.ky.gov/agencies/dbhdid/Documents/recoverycommunitycentersinfograph.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kentucky
                </a>
              </li>
              <li>
                <a
                  href="https://static1.squarespace.com/static/5d3f05dde2442800011cca16/t/6202e64fe96c6e5164d689aa/1644357200412/RCC+2021+Summary+infographic+FINAL+%28002%29.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Missouri
                </a>
              </li>
              <li>
                <a
                  href="https://www.rcnc.org/about/community-center.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  North Carolina
                </a>
              </li>
              <li>
                <a
                  href="https://kinolorber.com/film/theanonymouspeople"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Connecticut, Detroit, and Philadelphia as Featured in The
                  Anonymous People Film
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
