import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Link, Page, Text, View } from "@react-pdf/renderer"

import { PDFListItem, PDFRecommendTitleStyle } from "./PDFReportHelper"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

export default function PDFReportRecommendEconomy({
  needLevel,
  needLevelStyle,
  footerText,
}) {
  const styles = reportStyles()
  const TitleStyle = PDFRecommendTitleStyle(needLevelStyle)

  return (
    <Page size="A4" style={styles.page}>
      <PDFReportHeader />
      <Text
        style={{
          ...styles.subTitle2,
          ...TitleStyle,
        }}
      >
        Economic Stability: {needLevel}
      </Text>
      <Text style={styles.bodyHighlighted}>Recovery Housing</Text>
      <Text>
        Recovery housing includes a broad range of housing options, including
        housing for individuals transitioning from homelessness, a treatment
        facility, or an individual’s own home. Individuals living in recovery
        housing receive essential supports to initiate and sustain long-term
        addiction recovery. Establishing safe and secure housing options as part
        of the recovery process can contribute to improved recovery outcomes. A
        lack of housing creates fragmentation in treatment continuums, where
        hospitals and other high intensity clinical programs struggle in their
        ability to place individuals in safe and supportive community-based
        housing.
      </Text>
      <Text style={styles.Paragraph}>
        Research on the effectiveness of recovery housing suggests improved
        substance use outcomes, as well as improvements in functioning,
        decreased criminal activity, and improved employment behavior.
      </Text>
      <Text style={styles.Paragraph}>
        Some states, such as Florida, Massachusetts, and Utah, have established
        state certification and licensure programs for recovery housing to
        formally increase oversight. Other states provide training and technical
        assistance to recovery housing managers.
      </Text>
      <Text style={styles.Paragraph}>
        Here are examples of local Recovery Housing models currently being
        implemented in communities:
      </Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://mashsoberhousing.org/about-us">
            Massachusetts Alliance for Sober Housing
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.nhcorr.org/about">
            New Hampshire Coalition of Recovery Residences{" "}
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.oxfordhouse.org/oxford_house_history">
            Oxford House
          </Link>
        </PDFListItem>
      </View>
      <hr style={styles.line} />
      <Text style={{ ...styles.bodyHighlighted, ...styles.Paragraph }}>
        Re-Entry Support Services
      </Text>

      <Text>
        Individuals with substance use disorders involved with the criminal
        justice system often face many obstacles accessing quality{" "}
        <Link src="https://www.samhsa.gov/sites/default/files/topics/criminal_juvenile_justice/reentry-resources-for-consumers-providers-communities-states.pdf">
          behavioral health services
        </Link>
        . In addition to behavioral health access, other barriers include a lack
        of job skills, overarching health care needs, and stable housing. Though
        studies have shown that SUD treatment programs for incarcerated
        individuals are generally effective, those studies also show that
        discharged individuals of these treatment programs are likely to remain
        substance free if they receive continuing treatment or interventions in
        the community. Indeed, studies have found that with individuals engaged
        in support services that provide meaningful ongoing hope and recovery
        support and personal interaction with those living in recovery have
        lower recidivism rates when compared to individuals who did not
        participate.
      </Text>
      <Text style={styles.Paragraph}>
        Re-entry support services, especially for individuals with SUD, are
        currently funded in{" "}
        <Link src="https://www.samhsa.gov/sites/default/files/topics/criminal_juvenile_justice/reentry-resources-for-consumers-providers-communities-states.pdf">
          several ways
        </Link>
        . Grant programs include the Offender Reentry Program (ORP) that expands
        and enhance substance use treatment services for individuals
        reintegrating into communities after being released from correctional
        facilities. In addition, SAMHSA actively partners with other federal
        agencies to address the myriad of issues related to offender reentry
        through policy changes, recommendations to U.S. states and local
        governments, and elimination of myths surrounding offender reentry. In
        addition, SAMHSA provides resources for communities, local
        jurisdictions, and state policymakers to support individuals returning
        from jails and prisons, such as a{" "}
        <Link src="https://www.addictioncounselorce.com/articles/101286/apic.pdf">
          Best Practice Approach publication
        </Link>
        and{" "}
        <Link src="https://store.samhsa.gov/product/Medicaid-Coverage-and-Financing-of-Medications-to-Treat-Alcohol-and-Opioid-Use-Disorders/sma14-4854">
          Medicaid Coverage options
        </Link>
        .
      </Text>
      <Text style={styles.Paragraph}>
        Here are additional examples of Re-Entry Programs currently or
        previously implemented across the country:
      </Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://bja.ojp.gov/sites/g/files/xyckuh186/files/media/document/BJA-2016-9094.pdf">
            Second Chance Act Reentry Program for Adults with Co-Occurring
            Substance Abuse and Mental Disorders
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.rand.org/pubs/research_reports/RR572.html">
            Substance Use Treatment and Reentry (STAR) Program
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.mow.uscourts.gov/reentry-court">
            Missouri Re-Entry Courts{" "}
          </Link>
        </PDFListItem>
      </View>
      <PDFReportFooter text={footerText} />
    </Page>
  )
}
