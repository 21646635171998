export function getDataMap() {
  return [
    {
      groupName: "Economic",
      groupLabel: "Economic Stability",
      scoreCalculationDescription:
        "Placeholder:: Economic Stability:: Explain how the score was calculated",
      iconName: "sdoh-economic-icon.png",
      panelColor: "#993399",
      color: ["#B6D5E8", "#084C8F"],
      columnNames: [
        {
          name: "unemployment_rate",
          displayName: "Unemployment rate",
          description: "Unemployment rate",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
        },
        {
          name: "Per_Capita_income",
          displayName: "Per capita income",
          description: "Per capita income",
          dataNoteCounty: "",
          valueUnit: "$",
          valueUnitPosition: "front",
          decimalPrecision: 0,
        },
        {
          name: "labor_force_participation_rate",
          displayName: "Labor force participation rate",
          description: "Labor force participation rate",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
        },
        {
          name: "Poverty_Rate",
          displayName: "Poverty rate",
          description: "Poverty rate",
          valueUnit: "%",
          valueUnitPosition: "back",
        },

        {
          name: "Severe_Housing_Problems",
          displayName: "Severe housing problems",
          description: "Severe housing problems",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
          decimalPrecision: 2,
        },
      ],
      recoveryAssetClasses: [
        {
          name: "recoveryHousing",
          displayName: "Recovery Housing",
        },
        {
          name: "reentrySupportService",
          displayName: "Re-Entry Support Service",
        },
      ],
    },
    {
      groupName: "Education",
      groupLabel: "Education",
      scoreCalculationDescription:
        "Placeholder:: Education:: Explain how the score was calculated",
      dataNoteCounty: "",
      iconName: "sdoh-education-icon.png",
      panelColor: "#0577B1",
      color: ["#B6D5E8", "#084C8F"],
      columnNames: [
        {
          name: "aged_3_to_4_enrolled_in_school",
          displayName: "Aged 3-4 and Enrolled in School",
          description:
            "Percentage of population aged 3-4 and enrolled in school",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
        },
        {
          name: "Completed_High_School",
          displayName: "Completed High School",
          description: "Percentage of population that completed High School",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
        },
        {
          name: "Completed_College",
          displayName: "Completed College",
          description: "Percentage of population that completed College",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
        },
      ],
      recoveryAssetClasses: [
        {
          name: "schoolBased",
          displayName: "School-based Recovery Support Service",
        },
        {
          name: "schoolBasedPrevention",
          displayName: "School-based Prevention",
        },
      ],
    },
    {
      groupName: "Healthcare",
      groupLabel: "Healthcare",
      scoreCalculationDescription:
        "Placeholder:: Healthcare:: Explain how the score was calculated",
      iconName: "sdoh-healthcare-icon.png",
      panelColor: "#339898",
      color: ["#B6D5E8", "#084C8F"],
      columnNames: [
        {
          name: "Medicaid_expansion.y",
          displayName: "Medicaid expansion",
          description: "Medicaid expansion",
          dataNoteCounty: "Available at the state level only",
          valueUnit: "bool",
          valueUnitPosition: "front",
          calculateAverage: false,
        },
        {
          name: "Supply_of_opioids_reimbursed",
          displayName: "Supply of opioids reimbursed",
          description: "Supply of opioids reimbursed",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "Uninsured_Rate",
          displayName: "Percent of people without health insurance",
          description: "Percent of people without health insurance",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "ED_visits_resulting_from_opioids_per_100000",
          displayName: "Opioid related hospitalizations",
          description: "Opioid related hospitalizations per 100,000",
          dataNoteCounty: "",
          valueUnit: "/100,000",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "Need_but_not_receiving_addiction_treatment",
          displayName: "Needing but not receiving addiction treatment",
          description: "Needing but not receiving addiction treatment",
          dataNoteCounty: "Available at the state level only",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
          hideCountyOnMonitor: true,
        },
        {
          name: "Distance_to_nearest_MAT_provider",
          displayName: "Distance to Nearest MAT Provider",
          description: "Distance to Nearest MAT Provider",
          dataNoteCounty: "",
          valueUnit: " mi",
          valueUnitPosition: "back",
          calculateAverage: true,
        },
      ],
      recoveryAssetClasses: [
        {
          name: "mhSudTreatmet",
          displayName: "Mental Health/Substance Use Disorder Treatment",
        },
        {
          name: "counselingAddictionPro",
          displayName: "Counseling/Addiction Professional",
        },
        {
          name: "recoverySupport",
          displayName: "Recovery Support Services",
        },
      ],
    },
    {
      groupName: "Environment",
      groupLabel: "Environment",
      scoreCalculationDescription:
        "Placeholder:: Environment:: Explain how the score was calculated",
      iconName: "sdoh-environment-icon.png",
      panelColor: "#666666",
      color: ["#B6D5E8", "#084C8F"],
      columnNames: [
        {
          name: "Age_Adjusted_Drug_Poisoning_Deaths_per_100000",
          displayName: "Age-Adjusted Drug Poisoning Deaths per 100,000",
          description: "Age-Adjusted Drug Poisoning Deaths per 100,000",
          dataNoteCounty: "",
          valueUnit: "/100,000",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "Adults_Reporting_Binge_or_Heavy_Drinking",
          displayName: "Adults Reporting Binge or Heavy Drinking",
          description: "Adults Reporting Binge or Heavy Drinking",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "non_fatal_drug_overdoses",
          displayName: "Non-Fatal Drug Overdoses",
          description: "Non-Fatal Drug Overdoses",
          dataNoteCounty: "Available at the state level only",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
          hideCountyOnMonitor: true,
        },
        {
          name: "opioid_related_deaths",
          displayName: "Opioid Overdose Deaths",
          description: "Opioid overdose deaths per 100,000",
          dataNoteCounty: "",
          valueUnit: "/100,000",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "distance_to_nearest_SSP",
          displayName: "Distance to Nearest Syringe Service Program",
          description: "Distance to Nearest Syringe Service Program",
          dataNoteCounty: "",
          valueUnit: " mi",
          valueUnitPosition: "back",
          calculateAverage: true,
        },
      ],
      recoveryAssetClasses: [
        { name: "prevention", displayName: "Prevention Service" },
        {
          name: "hardReduction",
          displayName: "Harm Reduction Service",
        },
        {
          name: "recoveryCommunityCenter",
          displayName: "Recovery Community Center",
        },
      ],
    },
    {
      groupName: "Social",
      groupLabel: "Social",
      scoreCalculationDescription:
        "Placeholder:: Social:: Explain how the score was calculated",
      iconName: "sdoh-social-icon.png",
      panelColor: "#988675",
      color: ["#B6D5E8", "#084C8F"],
      columnNames: [
        {
          name: "Physical_Unhealthy_Days",
          displayName: "Physically Unhealthy Days",
          description: "Physically Unhealthy Days",
          dataNoteCounty: "",
          valueUnit: " days (last 30 days)",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
        {
          name: "Mental_Unhealthy_Days",
          displayName: "Mentally Unhealthy Days",
          description: "Mentally Unhealthy Days",
          dataNoteCounty: "",
          valueUnit: " days (last 30 days)",
          valueUnitPosition: "back",
          calculateAverage: false,
        },

        {
          name: "Percent_of_People_without_Internet_Service",
          displayName: "Percentage with no internet",
          description: "Percentage with no internet",
          dataNoteCounty: "",
          valueUnit: "%",
          valueUnitPosition: "back",
          calculateAverage: false,
        },

        {
          name: "Social_Association_Rate",
          displayName: "Social Association per 10,000",
          description: "Social Association per 10,000",
          dataNoteCounty: "",
          valueUnit: "/10,000",
          valueUnitPosition: "back",
          calculateAverage: false,
        },
      ],
      recoveryAssetClasses: [
        {
          name: "peerRecovery",
          displayName: "Peer Recovery Support / Recovery Coach",
        },
        {
          name: "mutualAidMeeting",
          displayName: "Mutual-Aid Support Meeting",
        },
      ],
    },
  ]
}

export function getDataColumnNames() {
  const map = getDataMap()
  let columns = []
  map.forEach((x) => {
    let cols = x.columnNames
      .filter((col) => col.name !== "")
      .map((col) => col.name)
    columns = [...columns, ...cols]
  })

  return columns
}
