import React, { useContext, useState } from "react"
import { Document, Page, pdf, View } from "@react-pdf/renderer"
import { saveAs } from "file-saver"

import reportStyles from "./PDFReportStyles"
import PDFReportIntro from "./PDFReportIntro"
import PDFReportHowToUse from "./PDFReportHowToUse"
import PDFReportRecommend from "./PDFReportRecommend"
import PDFReportMore from "./PDFReportMore"
import PDFReportQuestions from "./PDFReportQuestions"
import PDFReportAppendix from "./PDFReportAppendix"
import PDFReportSources from "./PDFReportSources"

//context
import { MainContext } from "../../../context/mainContext"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

//Google analytics
import { gtag } from "ga-gtag"

export default function Index({
  highNeed,
  lowNeed,
  moderateNeed,
  maintain,
  GetRecommendations,
}) {
  const mainContext = useContext(MainContext)

  // Create styles
  const styles = reportStyles()

  const GetFooter = () => {
    return <PDFReportFooter text={mainContext.location.label} />
  }

  const MyDoc = () => (
    <Document>
      <Page
        size="A4"
        style={{
          ...styles.page,
          paddingLeft: "0mm",
          paddingRight: "0mm",
          paddingTop: "0",
        }}
      >
        <PDFReportIntro
          reportType={mainContext.location.optionType}
          reportSelection={mainContext.location.label}
        />
        <View style={styles.body}>
          <PDFReportHowToUse reportType={mainContext.location.optionType} />
        </View>
        <GetFooter />
      </Page>
      <Page size="A4" style={styles.page}>
        <PDFReportHeader />
        <PDFReportRecommend
          highNeed={highNeed}
          lowNeed={lowNeed}
          moderateNeed={moderateNeed}
          maintain={maintain}
        />
        <GetFooter />
      </Page>
      <GetRecommendations usePDF={true} />
      <Page size="A4" style={styles.page}>
        <PDFReportHeader />
        <PDFReportMore />
        <PDFReportQuestions />
        <GetFooter />
      </Page>
      <Page size="A4" style={styles.page}>
        <PDFReportHeader />
        <PDFReportAppendix />
        <GetFooter />
      </Page>
      <Page size="A4" style={styles.page}>
        <PDFReportHeader />
        <PDFReportSources />
        <GetFooter />
      </Page>
    </Document>
  )

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

  const LazyDownloadPDFButton = () => (
    <button
      className={`btn`}
      style={{ float: "right" }}
      onClick={async () => {
        setIsGeneratingPdf(true)

        //Report event to analytics
        const locationLabel = mainContext.location.label || "Unknown"
        gtag("event", "report", {
          action: "Downloaded report",
          label: locationLabel,
        })

        const doc = <MyDoc />
        const asPdf = pdf([]) // {} is important, throws without an argument
        asPdf.updateContainer(doc)
        const blob = await asPdf.toBlob()
        saveAs(blob, "document.pdf")
        setIsGeneratingPdf(false)
      }}
      disabled={isGeneratingPdf}
    >
      {isGeneratingPdf ? "Generating PDF" : "Download PDF"}
    </button>
  )

  return (
    <div style={{ width: "100%" }}>
      <LazyDownloadPDFButton />
      {/*       <PDFDownloadLink document={<MyDoc />} filename={"report.pdf"}>
        {({ loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink> */}
    </div>
  )
}
