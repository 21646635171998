import React from "react"

//PrimeReact
import { Accordion, AccordionTab } from "primereact/accordion"

//Styles
import myStyle from "../../styles/Default/mainMap.module.css"
import { Button } from "primereact/button"

//helpers
import getPath from "../../helpers/routeHelper"
import { useNavigate } from "react-router"

//Google analytics
import { gtag } from "ga-gtag"

export default function VariableOptions({
  dataMap,
  selectedVariable,
  onSetVariableSelection,
  onInfoClicked,
}) {
  const navigate = useNavigate()

  function selectValueOption(option, optionColumn) {
    //Add analytics event
    gtag("event", "report_selection", {
      category: option.groupLabel,
      report_name: optionColumn.displayName,
    })

    onSetVariableSelection({
      group: option,
      field: optionColumn,
    })
  }

  if (!dataMap) {
    return null
  }

  return (
    <div className={myStyle.gridItemDetail}>
      <Accordion activeIndex={0}>
        {dataMap.map((option, index) => {
          return (
            <AccordionTab
              headerTemplate={() => (
                <div className={myStyle.optionHeader}>{option.groupLabel}</div>
              )}
              headerStyle={{ background: option.panelColor }}
              key={index}
            >
              {option.columnNames.map((col, index) => {
                return (
                  <div
                    onClick={() => {
                      selectValueOption(option, col)
                    }}
                    className={`${myStyle.optionColumnDisplayPanel} ${
                      selectedVariable.group.groupLabel === option.groupLabel &&
                      selectedVariable.field.name === col.name &&
                      selectedVariable.field.displayName === col.displayName
                        ? myStyle.optionColumnSelectedPanel
                        : ""
                    }`}
                    key={index}
                  >
                    {col.displayName}
                    <i
                      className="pi pi-info-circle"
                      style={{ cursor: "pointer", paddingLeft: "5px" }}
                      onClick={() => {
                        onInfoClicked()
                      }}
                    />
                  </div>
                )
              })}
            </AccordionTab>
          )
        })}
      </Accordion>

      <Button
        className={myStyle.MonitorButton}
        onClick={() => {
          navigate(`/${getPath("monitor")}`, { replace: true })
        }}
        label="View location statistics"
      />
    </div>
  )
}
