import React from "react"
import myStyles from "../../styles/Default/faq.module.css"
import { GetHyperLink } from "../../helpers/valueHelper"

export default function Faq() {
  return (
    <div className={myStyles.mainBody}>
      <div className={myStyles.title}>
        Duke Margolis Institute for Health Policy & Third Horizon Strategies
        Opioid Abatement Needs and Investment Tool
      </div>
      <div className={myStyles.title}>
        Frequently Asked Questions about the Tool
      </div>
      <div className={myStyles.bodyHighlighted}>
        What is the purpose of this tool and how can it be helpful?
      </div>
      <div className={myStyles.body}>
        This toolset is geared toward stakeholders, particularly local and state
        policymakers, with influence over and interest in the settlement fund
        distribution or, more generally, opioid response in their community. In
        particular, this toolset attempts to answer the questions (1) what is
        the community’s current status in the opioid epidemic, and (2) what is
        the community’s capacity to respond to the opioid epidemic currently,
        providing insights into potential strategic pathways for settlement fund
        investment opportunities. While data and guidance are as comprehensive
        as possible, every community has unique circumstances outside this
        toolset that it will need to consider.{" "}
        <i>“All models are wrong, some are useful,”</i> George Box.
      </div>
      <div className={myStyles.bodyHighlighted}>
        Where was the data sourced from?
      </div>
      <div className={myStyles.body}>
        Data were gathered from various reliable, independent sources, including
        government and philanthropically-funded data projects. To see each
        variable's source, description, and methodology, click on the “i” icon
        to the right of each variable on the Explore tab.
      </div>

      <div className={myStyles.bodyHighlighted}>
        What year is the data from, and how often will the sources be updated?
      </div>
      <div className={myStyles.body}>
        The tool sources the most recent data available at the county or state
        level, which in some cases may include five-year estimates. The tool is
        designed to pull data from a repository that monitors updates from the
        source, as such, the data in the tool will be updated as new source data
        is released. Each underlying data source utilized in the tool will have
        a unique cadence of updating its data, to learn more about each
        variable’s source, click on the “i” icon to the right of each variable
        on the Explore tab.
      </div>
      <div className={myStyles.bodyHighlighted}>
        Why were these data variables chosen?
      </div>
      <div className={myStyles.body}>
        Each data variable was chosen based on two primary priorities. The first
        is demonstrated evidence between the measure and severity of SUD
        prevalence in a community. The second priority is to find as much
        uniformity in the data variable availability at a county level
        nationally, although, in some instances, no data is available for
        certain counties (indicated by a N/A in the cell) because data are not
        reported or have methodological limitations. This uniformity allows the
        tool to compare the same and most up-to-date measures across
        communities. To read more about the importance of each data variable,
        click on the “i” icon to the right of each variable on the Explore tab.
      </div>
      <div className={myStyles.bodyHighlighted}>
        How were data sources chosen?
      </div>
      <div className={myStyles.body}>
        The Duke-Margolis Institute for Health Policy spoke to subject matter
        experts and performed a comprehensive literature review of key
        components, drivers, and indicators of the severity of the opioid crisis
        in local communities. The variables chosen provide a holistic and
        multi-dimensional view of the challenges and opportunities for
        investment in opioid abatement. To read more about the importance of
        each data variable, click on the “i” icon to the right of each variable
        on the Explore tab.
      </div>
      <div className={myStyles.bodyHighlighted}>
        How is the recovery index calculated?
      </div>
      <div className={myStyles.body}>
        The Need Index is a comparative index designed to measure the
        prospective investment needs for substance use and mental health
        prevention, treatment, and recovery support services in a specific area.
        The Need Index is calculated as a normalized composite of the monitoring
        variable measures for a given domain. First, individual indicators are
        transformed using the Box-Cox transformation method to make the data
        more closely resemble a normal distribution. Next, these indicators are
        converted into a z-score. Z-scores are a common statistical procedure
        that puts indicators measured on different scales onto a common scale.
        The z-score measures the fractional number of standard deviations by
        which the value of an observation is above or below the mean value of
        the population. Next, we take the mean of the z-scores for that domain
        (e.g. Environment). Not all counties will have data available for each
        indicator. By removing the mean with N/A values, we mitigate the effect
        missing data could have on the Index. Weighted averages were considered
        and ultimately rejected for lack of sufficient research. Being a
        relative and unitless measure, the Need Index is represented visually on
        a color scale associated where the datapoint falls in terms of its
        standard deviation . To account for outliers, z-scores are capped off at
        ±2 standard deviations from the mean. A z-score of ±2 encompasses
        approximately 95% of the data. The remaining data is not dropped but
        rather{" "}
        <GetHyperLink
          href="https://en.wikipedia.org/wiki/Winsorizing"
          description="winsorized"
        />
        . This preserves an outlier’s relative place in the distribution. Users
        can view the Need Index side by side with its component measurements for
        a better understanding of what is driving results.
      </div>
      <div className={myStyles.bodyHighlighted}>
        How were the categories of the index determined?
      </div>
      <div className={myStyles.body}>
        The overall index comprises a set of county- and state-level indicators
        selected as monitoring variables based on their relationship to Social
        Determinants of Health (SDOH) domains extended to opportunities for
        recovery capitalization investments. The index categories map to Healthy
        People 2030’s SDOH objectives. The Healthy People 2030 goals for each
        objective are as follows:
        <ul>
          <li>
            Economic Stability: Help people earn steady incomes that allow them
            to meet their health needs.
          </li>
          <li>
            Education Access and Quality: Increase educational opportunities and
            help children and adolescents do well in school.
          </li>
          <li>
            Health Care Access and Quality: Increase access to comprehensive,
            high-quality health care services.
          </li>
          <li>
            Neighborhood and Built Environment: Create neighborhoods and
            environments that promote health and safety.
          </li>
          <li>
            Social and Community Context: Increase social and community support.
          </li>
        </ul>
      </div>
      <div className={myStyles.bodyHighlighted}>
        Why is “County” the smallest area the data drills down to?
      </div>
      <div className={myStyles.body}>
        The tool is designed to utilize data that can uniformly be compared
        nationwide. While some data might be available at the municipal or the
        ZIP code level, these sources are less uniform nationwide for
        comparison.
      </div>
      <div className={myStyles.bodyHighlighted}>
        Will new data be added to this tool in the future?
      </div>
      <div className={myStyles.body}>
        We are exploring opportunities to incorporate new, uniform data into the
        toolset. Stakeholders are encouraged to supplement this tool with their
        own data. For additional feedback, please contact{" "}
        <a href="mailto:frank.mcstay@duke.edu">Frank McStay</a>.
      </div>
    </div>
  )
}
