import React, { useEffect, useState } from "react"
import parse from "html-react-parser"

//Charting
/*import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from "recharts"*/

//CSV SourceData
import Papa from "papaparse"
import CSVData from "../../assets/data/exploreInfoModalSource.csv"

//primeReact:
import { Dialog } from "primereact/dialog"
import { TabView, TabPanel } from "primereact/tabview"

//Styles
import myStyles from "../../styles/Default/infoModal.module.css"

export default function InfoModal({
  show,
  onHide,
  infoType,
  infoTypeDisplayName,
  infoSectionName,
  infoSectionDisplayName,
}) {
  const [infoSource, setInfoSource] = useState(undefined)
  const [infoData, setInfoData] = useState(undefined)
  const [lastDataSearch, setLastDataSearch] = useState("")
  const [hoverClose, setHoverClose] = useState(false)

  const SetInfo = () => {
    if (
      !!infoSource &&
      (!lastDataSearch ||
        lastDataSearch.subType !== infoSectionName ||
        lastDataSearch.type !== infoType)
    ) {
      setLastDataSearch({ type: infoType, subType: infoSectionName })

      let source = infoSource.filter(
        (x) => x.Type === infoType && x.SubType === infoSectionName
      )

      if (source.length > 0) {
        setInfoData(source[0])
      } else {
        setInfoData(undefined)
      }
    }
  }

  useEffect(() => {
    if (!infoSource) {
      fetch(CSVData)
        .then((data) => data.text())
        .then((responseText) => {
          Papa.parse(responseText, {
            header: true,
            skipEmptyLines: true,
            delimiter: ",",
            complete: (result) => {
              if (result.errors.length > 0) {
                //TODO: Add better error handling
                console.log(result.errors)
              }
              setInfoSource(result.data)
            },
          })
        })
    }
  })

  useEffect(() => {
    SetInfo()
  }, [infoSource, infoSectionName, infoType])

  /*   const data = [
    { name: "2019", uv: 400, pv: 2400, amt: 2400 },
    { name: "2020", uv: 300, pv: 2400, amt: 2400 },
    { name: "2021", uv: 100, pv: 2400, amt: 2400 },
    { name: "2022", uv: 500, pv: 2400, amt: 2400 },
  ] */

  return (
    <Dialog
      visible={show}
      header={
        <div className={myStyles.header}>
          <div className={myStyles.headerGrid}>
            <div className={myStyles.headerTitle}>
              {`${infoTypeDisplayName}: ${infoSectionDisplayName} `}
            </div>
            <div className={myStyles.headerButton}>
              <button
                className={`${myStyles.headerCloseButton} ${
                  hoverClose ? myStyles.headerCloseButtonHover : ""
                }`}
                onMouseEnter={() => setHoverClose(true)}
                onMouseLeave={() => setHoverClose(false)}
                onClick={onHide}
              >
                X
              </button>
            </div>
          </div>
        </div>
      }
      className={myStyles.dialog}
      closable={false}
      onHide={onHide}
    >
      {!infoData ? (
        <div>No information found</div>
      ) : (
        <>
          <TabView>
            <TabPanel header={"Importance"}>
              {parse(infoData.Justification)}
              {!!infoData.JustificationSource ? (
                <>
                  <br />
                  <br />
                  <div className={myStyles.title}>Source:</div>
                  <div className={myStyles.source}>
                    {parse(infoData.JustificationSource)}
                  </div>
                </>
              ) : null}
            </TabPanel>
            <TabPanel header={"Description"}>
              <div className={myStyles.data}>{parse(infoData.Description)}</div>
              <br />
              <div className={myStyles.title}>Source:</div>
              <div className={myStyles.data}>{infoData.Source}</div>
              <br />
              <div className={myStyles.title}>Year:</div>
              <div className={myStyles.data}>{infoData["Year Collected"]}</div>
              <br />
              <div className={myStyles.title}>Link:</div>
              <div className={myStyles.data}>
                <a
                  href={
                    (infoData.Link.substr(0, 4).toLowerCase() !== "http"
                      ? "http://"
                      : "") + infoData.Link
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {infoData.Link}
                </a>
              </div>
            </TabPanel>
            <TabPanel header={"Methodology"}>
              <div>{infoData.Methodology}</div>
            </TabPanel>
            {/* <TabPanel header={"Historical Data"}>
              <div>
                <AreaChart
                  width={500}
                  height={250}
                  data={data}
                  margin={{ top: 5, right: 5, bottom: 5, left: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="name" />
                  <YAxis orientation="right">
                    <Label
                      value="Example historic value"
                      angle={90}
                      position="insideRight"
                      offset={5}
                      style={{ textAnchor: "middle" }}
                    />
                  </YAxis>
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              </div>
            </TabPanel> */}
          </TabView>
        </>
      )}
    </Dialog>
  )
}
