import { StyleSheet, Font } from "@react-pdf/renderer"

import fontRegular from "../../../assets/fonts/OpenSans-Regular.ttf"
import fontBold from "../../../assets/fonts/OpenSans-Bold.ttf"
import fontSemiBold from "../../../assets/fonts/OpenSans-SemiBold.ttf"
import fontExtraBold from "../../../assets/fonts/OpenSans-ExtraBold.ttf"
import fontItalic from "../../../assets/fonts/OpenSans-Italic.ttf"

export default function GetPDFReportStyles() {
  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: fontRegular,
      },
      {
        src: fontBold,
        fontWeight: "bold",
      },
      { src: fontSemiBold, fontWeight: "semibold" },
      { src: fontExtraBold, fontWeight: "800" },
      { src: fontItalic, fontStyle: "italic" },
    ],
  })

  Font.registerHyphenationCallback((word) => [word])

  const textColor = "#012169"
  const dukeBlueColor = "#001A57"

  const titleFontSize = 18
  const sectionTitleFontSize = 16
  const subTitleFontSize = 14
  const subTitle2FontSize = 13
  const mediumFontSize = 12
  const regularFontSize = 10
  const smallFontSize = 8

  const paragraphMarginTop = 10

  const styles = StyleSheet.create({
    body: { paddingHorizontal: "10mm" },
    bodyHighlighted: {
      fontFamily: "Open Sans",
      fontWeight: "semibold",
      fontSize: mediumFontSize,
      color: "#012169",
      marginBottom: "5mm",
    },
    headerView: { backgroundColor: dukeBlueColor, padding: "0px" },
    exampleTitle: {
      fontFamily: "Open Sans",
      fontStyle: "italic",
      marginTop: paragraphMarginTop,
    },
    line: {
      border: "0.5px",
      borderStyle: "solid",
      borderColor: "gray",
      marginTop: paragraphMarginTop * 2,
      marginBottom: paragraphMarginTop,
    },
    lineHeader: {
      border: "2px",
      borderStyle: "solid",
      borderColor: dukeBlueColor,
      marginTop: paragraphMarginTop * 2,
      marginBottom: paragraphMarginTop,
    },

    listContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: paragraphMarginTop,
    },
    listItemContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 4,
    },
    listItemSpaceTop: {
      marginTop: paragraphMarginTop,
    },
    Paragraph: {
      marginTop: paragraphMarginTop,
    },
    page: {
      fontFamily: "Open Sans",
      backgroundColor: "white",
      paddingBottom: 30,
      paddingTop: 30,
      paddingHorizontal: "10mm",
      fontSize: regularFontSize,
    },
    recommendationItemsView: {
      display: "flex",
      flexDirection: "row",
      marginTop: paragraphMarginTop,
    },
    recommendationView: {
      backgroundColor: "#F2F2F2",
      marginTop: paragraphMarginTop,
    },
    recommendationViewItem: {
      width: "25%",
      padding: "5mm",
    },
    recommendationHighNeedText: {
      color: "red",
      backgroundColor: "#FEEAEB",
      paddingTop: "5mm",
      paddingBottom: "5mm",
    },
    recommendationModerateNeedText: {
      color: "#ED7C31",
      backgroundColor: "#FCF1ED",
      paddingTop: "5mm",
      paddingBottom: "5mm",
    },
    recommendationLowNeedText: {
      color: "#D3A005",
      backgroundColor: "#F9F5EA",
      paddingTop: "5mm",
      paddingBottom: "5mm",
    },
    recommendationLowestNeedText: {
      color: "green",
      backgroundColor: "#B3F7AF",
      paddingTop: "5mm",
      paddingBottom: "5mm",
    },
    reportTitle: {
      fontFamily: "Open Sans",
      color: textColor,
      fontSize: titleFontSize,
      fontWeight: "ultrabold",
      marginTop: "5mm",
      marginBottom: "5mm",
    },
    subTitle: {
      fontFamily: "Open Sans",
      color: textColor,
      fontSize: subTitleFontSize,
      fontWeight: "ultrabold",
      marginTop: "2mm",
      marginBottom: "2mm",
    },
    subTitle2: {
      fontFamily: "Open Sans",
      color: textColor,
      fontSize: subTitle2FontSize,
      fontWeight: "ultrabold",
      marginTop: "5mm",
      marginBottom: "5mm",
      textDecoration: "underline",
    },
    section: {
      fontFamily: "Open Sans",
      color: "white",
      textAlign: "center",
      margin: 30,
    },
    source: {
      fontFamily: "Open Sans",
      fontStyle: "italic",
      fontSize: smallFontSize,
      marginTop: "2mm",
    },
    textRegular: { fontFamily: "Open Sans", fontSize: regularFontSize },
    textItalic: { fontFamily: "Open Sans", fontStyle: "italic" },
    textBold: {
      fontFamily: "Open Sans",
      fontSize: regularFontSize,
      fontWeight: "bold",
    },
    title: {
      fontFamily: "Open Sans",
      color: textColor,
      fontSize: sectionTitleFontSize,
      fontWeight: "bold",
      marginTop: "5mm",
      marginBottom: "4mm",
    },
  })

  return styles
}
