import React, { useContext, useEffect, useState } from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

//context
import { MainContext } from "../../context/mainContext"

//Helpers
import { getDataMap } from "../../helpers/dataMap"

//Report components
import ReportAboutTheData from "./ReportAboutTheData"
import ReportHowToUse from "./ReportHowToUse"
import ReportRecommend from "./ReportRecommend"
import ReportRecommendEconomy from "./ReportRecommendEconomy"
import ReportRecommendEducation from "./ReportRecommendEducation"
import ReportRecommendHealthcare from "./ReportRecommendHealthcare"
import ReportRecommendEnvironment from "./ReportRecommentEnvironment"
import ReportRecommendSocial from "./ReportRecommendSocial"
import ReportMore from "./ReportMore"
import ReportQuestions from "./ReportQuestions"
import ReportAppendix from "./ReportAppendix"
import ReportSources from "./ReportSources"
import PdfReport from "./pdf/Index"

//PDF Report components
import PDFReportRecommendEconomy from "./pdf/PDFReportRecommendEconomy"
import PDFReportRecommendEducation from "./pdf/PDFReportRecommendEducation"
import PDFReportRecommendHealthcare from "./pdf/PDFReportRecommendHealthcare"
import PDFReportRecommendEnvironment from "./pdf/PDFReportRecommentEnvironment"
import PDFReportRecommendSocial from "./pdf/PDFReportRecommendSocial"

export default function Report() {
  const mainContext = useContext(MainContext)

  const [dataMap] = useState(getDataMap())

  const [highNeed, setHighNeed] = useState([])
  const [moderateNeed, setModerateNeed] = useState([])
  const [lowNeed, setLowNeed] = useState([])
  const [maintain, setMaintain] = useState([])

  const locationFips = `${mainContext.location.state_fips}${mainContext.location.county_fips}`

  //When selection changes, calculate the high to low needs
  //These will be used to decide the order of the Report recommendations
  useEffect(() => {
    if (
      !!mainContext.scoreCardDataCounty &&
      !!mainContext.scoreCardDataState &&
      !!mainContext.location.optionType
    ) {
      const scoreDataValues =
        mainContext.location.optionType === "State"
          ? mainContext.scoreCardDataState
          : mainContext.scoreCardDataCounty

      const scoreValues =
        mainContext.location.optionType === "State"
          ? scoreDataValues.filter(
              (x) => x.GEOID === mainContext.location.state
            )
          : scoreDataValues.filter((x) => x.fips === locationFips)

      if (scoreValues.length > 0) {
        let high = []
        let low = []
        let moderate = []
        let maintain = []

        dataMap.forEach((group) => {
          const scoreValue = +scoreValues[0][group.groupName]
          const scoreCardValues = scoreDataValues
            .filter((x) => !!+x[group.groupName])
            .map((x) => +x[group.groupName])
          const dataCount = scoreCardValues.length

          const mean =
            scoreCardValues.reduce((accumulator, currentValue) => {
              return accumulator + currentValue
            }, 0) / dataCount

          const stdDeviation = Math.sqrt(
            scoreCardValues
              .map((x) => Math.pow(x - mean, 2))
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue
              ) / dataCount
          )

          const upperLimit = mean + stdDeviation
          const lowerLimit = mean - stdDeviation

          if (scoreValue < lowerLimit) {
            maintain.push(group.groupName)
          } else if (scoreValue >= lowerLimit && scoreValue < mean) {
            low.push(group.groupName)
          } else if (scoreValue >= mean && scoreValue < upperLimit) {
            moderate.push(group.groupName)
          } else if (scoreValue >= upperLimit) {
            high.push(group.groupName)
          } else {
            console.error(`Unknown classification: ${group.groupName}`)
          }
        })

        setHighNeed(high)
        setLowNeed(low)
        setModerateNeed(moderate)
        setMaintain(maintain)
      }
    }
  }, [dataMap, mainContext.location, mainContext.scoreCardData])

  function MapNeedToReportComponent(name, needLevel, usePDF) {
    let needStyle = ""

    switch (needLevel) {
      case "High Need":
        needStyle = myStyles.needHigh
        break
      case "Moderate Need":
        needStyle = myStyles.needModerate
        break
      case "Low Need":
        needStyle = myStyles.needLow
        break
      default:
        //Lowest Need
        needStyle = myStyles.needLowest
        break
    }

    let component = null
    switch (name) {
      case "Economic":
        component = !usePDF ? (
          <ReportRecommendEconomy
            needLevel={needLevel}
            needLevelStyle={needStyle}
          />
        ) : (
          <PDFReportRecommendEconomy
            needLevel={needLevel}
            needLevelStyle={needStyle}
            footerText={mainContext.location.label}
          />
        )
        break
      case "Education":
        component = !usePDF ? (
          <ReportRecommendEducation
            needLevel={needLevel}
            needLevelStyle={needStyle}
          />
        ) : (
          <PDFReportRecommendEducation
            needLevel={needLevel}
            needLevelStyle={needStyle}
            footerText={mainContext.location.label}
          />
        )
        break
      case "Healthcare":
        component = !usePDF ? (
          <ReportRecommendHealthcare
            needLevel={needLevel}
            needLevelStyle={needStyle}
          />
        ) : (
          <PDFReportRecommendHealthcare
            needLevel={needLevel}
            needLevelStyle={needStyle}
            footerText={mainContext.location.label}
          />
        )
        break
      case "Environment":
        component = !usePDF ? (
          <ReportRecommendEnvironment
            needLevel={needLevel}
            needLevelStyle={needStyle}
          />
        ) : (
          <PDFReportRecommendEnvironment
            needLevel={needLevel}
            needLevelStyle={needStyle}
            footerText={mainContext.location.label}
          />
        )
        break
      case "Social":
        component = !usePDF ? (
          <ReportRecommendSocial
            needLevel={needLevel}
            needLevelStyle={needStyle}
          />
        ) : (
          <PDFReportRecommendSocial
            needLevel={needLevel}
            needLevelStyle={needStyle}
            footerText={mainContext.location.label}
          />
        )
        break
      default:
        break
    }

    return component
  }

  function GetRecommendations({ usePDF }) {
    let recommendations = []

    highNeed.forEach((x) =>
      recommendations.push(MapNeedToReportComponent(x, "High Need", usePDF))
    )
    moderateNeed.forEach((x) =>
      recommendations.push(MapNeedToReportComponent(x, "Moderate Need", usePDF))
    )
    lowNeed.forEach((x) =>
      recommendations.push(MapNeedToReportComponent(x, "Low Need", usePDF))
    )
    maintain.forEach((x) =>
      recommendations.push(MapNeedToReportComponent(x, "Lowest Need", usePDF))
    )

    const GetComponent = ({ component }) => {
      return component
    }

    return (
      <>
        {recommendations.map((x, index) => (
          <GetComponent component={x} key={index} />
        ))}
      </>
    )
  }

  return (
    <>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <div>
            <div
              className={myStyles.reportTitle}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <span style={{ width: "95%" }}>
                {mainContext.location.label || "No selection available"} Report
              </span>

              <PdfReport
                highNeed={highNeed}
                lowNeed={lowNeed}
                moderateNeed={moderateNeed}
                maintain={maintain}
                GetRecommendations={GetRecommendations}
              />
            </div>
            <div className={myStyles.body}>
              This tool provides state and local officials, community members,
              and others involved in addressing the opioid epidemic with
              tailored guidance on areas of need in their communities and where
              to target spending funds from the{" "}
              <a
                href="https://www.opioidsettlementtracker.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                billions of dollars in opioid settlements
              </a>{" "}
              reached with manufacturers, pharmaceutical distributors, and
              pharmacies.
            </div>
            <ReportAboutTheData reportType={mainContext.location.optionType} />
            <ReportHowToUse reportType={mainContext.location.optionType} />
          </div>
        </div>
        <div
          className={`${myStyles.mainContainer} ${myStyles.mainContainerGrey}`}
        >
          <div className={myStyles.mainBody}>
            <ReportRecommend
              highNeed={highNeed}
              lowNeed={lowNeed}
              moderateNeed={moderateNeed}
              maintain={maintain}
            />
          </div>
        </div>
        <GetRecommendations usePDF={false} />
        <div
          className={`${myStyles.mainContainer} ${myStyles.mainContainerGrey}`}
        >
          <div className={myStyles.mainBody}>
            <ReportMore />
            <ReportQuestions />
          </div>
        </div>
        <div className={`${myStyles.mainContainer} `}>
          <div className={myStyles.mainBody}>
            <ReportAppendix />
            <ReportSources />
          </div>
        </div>
      </div>
    </>
  )
}
