import React, { useContext, useState } from "react"

//PriceReact
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { Button } from "primereact/button"

//Styles
import myStyles from "../../styles/Default/login.module.css"

//context
import { MainContext } from "../../context/mainContext"

export default function Login() {
  const mainContext = useContext(MainContext)

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const unlock = () => {
    setError(mainContext.unlock(user, password))
  }

  return (
    <div className={myStyles.loginMain}>
      <div className={myStyles.loginBlock}>
        <div>Username</div>

        <InputText
          value={user}
          onChange={(e) => {
            setUser(e.target.value)
          }}
          className={myStyles.input}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              unlock()
            }
          }}
        />
        <div className={myStyles.label}>Password</div>
        <div className={myStyles.input}>
          <Password
            inputStyle={{ width: "100%" }}
            style={{ width: "100%" }}
            value={password}
            feedback={false}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                unlock()
              }
            }}
          />
        </div>
        {!!error ? (
          <div className={myStyles.error}>
            <label>{error}</label>
          </div>
        ) : null}

        <Button label="Login" className={myStyles.button} onClick={unlock} />
      </div>
    </div>
  )
}
