import Papa from "papaparse"
import CSVData from "../assets/data/County_data.csv"

export default function getCountyData(onDataRetrieved) {
  return fetch(CSVData)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          result.data = result.data.map((row) => {
            let geoID = row.GEOID.padStart(5, "0")

            return {
              ...row,
              map_geo_id: `0500000US${row.GEOID.padStart(5, "0")}`,
              fips: row.GEOID.padStart(5, "0"),
              county_FIPS: geoID.substring(2, 5),
              state_FIPS: geoID.substring(0, 2),
            }
          })
          onDataRetrieved(result)
        },
      })
    })
}
