import Papa from "papaparse"
import CSVData from "../assets/data/National.Data.csv"

export default function getNationalData(onDataRetrieved) {
  return fetch(CSVData)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          onDataRetrieved(result)
        },
      })
    })
}
