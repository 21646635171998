import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommendHealthcare({
  needLevel,
  needLevelStyle,
}) {
  return (
    <>
      <div
        className={`${myStyles.mainContainer} ${myStyles.mainContainerRecommendItem} ${needLevelStyle}`}
      >
        <div className={myStyles.mainBody}>
          <div className={`${myStyles.subTitle2} ${myStyles.noMargin}`}>
            Healthcare: {needLevel}
          </div>
        </div>
      </div>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <br />
          <div className={myStyles.bodyHighlighted}>
            Recovery Support Services
          </div>
          <div className={myStyles.body}>
            <p>
              The Substance Abuse and Mental Health Services Administration
              (SAMHSA)’s working definition of recovery defines recovery as a
              process of change through which individuals improve their health
              and wellness, live self-directed lives, and strive to reach their
              full potential. Recovery support services are typically
              non-clinical supports that help people enter into and navigate
              systems of care, remove barriers to recovery, stay engaged in the
              recovery process, and live within their community of choice. These
              services are often provided by peer recovery coaches —trained and
              certified individuals living in recovery with mental illness
              and/or substance use disorder. Examples of support can include
              child care, employment, job training, transportation, and
              recreational activities that all contribute to better recovery
              outcomes, like increased housing stability and reduced rates of
              reoccurrence of SUD and hospitalization (Bassuk et al., 2016;
              Eddie et al., 2016).
            </p>
            <p>
              Recovery support services are currently funded in a variety of
              ways through state Medicaid programs, the Substance Use
              Prevention, Treatment, and Recovery Block Grant, other SAMSHA
              grants, state funding sources, employers, and philanthropy. A
              majority of states cover peer support services for adults with
              substance use disorders in their Medicaid programs, though there
              are differences in who qualifies as a provider, supervision
              requirements, and what specific services are covered. However,
              additional funding is often necessary to expand access to services
              for more people (for example, youths), increase the types of
              services covered, and support training for the peer workforce (US
              Governmental Accountability Office, 2020).
            </p>
            <span className={myStyles.exampleHeader}>Examples:</span>
            <ul>
              <li>
                <a
                  href="https://portal.ct.gov/DMHAS/Initiatives/DMHAS-Initiatives/Emergency-Department-Recovery-Coach-Services"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CT Emergency Department Recovery Coach Services{" "}
                </a>
              </li>
              <li>
                <a
                  href="https://www.rwjbh.org/treatment-care/institute-for-prevention-and-recovery/programs/peer-recovery-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RWJ Barnabas Health Peer Recovery Program
                </a>{" "}
                (hospital based)
              </li>
              <li>
                <a
                  href="https://www.greatlakesrecovery.org/recovery-services/peer-recovery/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Marquette County Peer Recovery Services
                </a>{" "}
                (rural)
              </li>
            </ul>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>MH/SUD Treatment</div>
          <div className={myStyles.body}>
            <p>
              There are many types of treatments that professionals provide for
              mental health and substance use disorders. The amount of available
              mental health and substance use disorder treatment is important in
              assessing a region’s capacity to treat opioid use disorder.
            </p>
            <p>
              According to the{" "}
              <a
                href="https://nida.nih.gov/publications/drugfacts/treatment-approaches-drug-addiction"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Institute of Drug Abuse
              </a>
              , the following are key principles that should inform the basis of
              any effective substance use treatment program:
            </p>
            <ul>
              <li>
                Addiction is a complex but treatable disease that affects brain
                function and behavior.
              </li>
              <li>No single treatment is right for everyone.</li>
              <li>People need to have quick access to treatment.</li>
              <li>
                Effective treatment addresses all of the patient’s needs, not
                just his or her drug use.
              </li>
              <li>Staying in treatment long enough is critical.</li>
              <li>
                Counseling and other behavioral therapies are the most commonly
                used forms of treatment.
              </li>
              <li>
                Medications are often an important part of treatment, especially
                when combined with behavioral therapies.
              </li>
              <li>
                Treatment plans must be reviewed often and modified to fit the
                patient’s changing needs.
              </li>
              <li>Treatment should address other possible mental disorders.</li>
              <li>
                Medically assisted detoxification is only the first stage of
                treatment.
              </li>
              <li>Treatment doesn't need to be voluntary to be effective.</li>
              <li>Drug use during treatment must be monitored continuously.</li>
              <li>
                Treatment programs should test patients for HIV/AIDS, hepatitis
                B and C, tuberculosis, and other infectious diseases as well as
                teach them about steps they can take to reduce their risk of
                these illnesses.
              </li>
            </ul>

            <p>
              Patients can receive treatment in several settings, such as in
              emergency departments, outpatient specialty SUD and behavioral
              health programs, primary care, via telehealth, and/or
              inpatient/residential treatment centers. Adjunct services are now
              being expanded using mobile technology for asynchronous treatment
              engagement and providing contingency management incentives to
              increase retention and engagement of individuals in treatment.
            </p>
            <p>
              Many states and jurisdictions provide treatments for individuals
              struggling with substance use disorders. For example,{" "}
              <a
                href="https://www.nyc.gov/site/doh/health/health-topics/alcohol-and-drug-use-services.page"
                target="_blank"
                rel="noopener noreferrer"
              >
                New York City
              </a>{" "}
              provides many free and low-cost services for people struggling
              with drug or alcohol use disorder, such as a{" "}
              <a
                href="https://www.samhsa.gov/medication-assisted-treatment/find-treatment/treatment-practitioner-locator"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buprenorphine Physician Locator
              </a>
              . Also, The District of Columbia provides{" "}
              <a
                href="https://dbh.dc.gov/service/treatment-services"
                target="_blank"
                rel="noopener noreferrer"
              >
                Assessment and Referral sites
              </a>{" "}
              that deliver same-day assessments and referrals for individuals
              seeking treatment for substance use disorders.
            </p>
            <p>
              SAMHSA provides a national treatment locator at{" "}
              <a
                href="https://findtreatment.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FindTreatment.gov
              </a>
              .
            </p>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>
            Counseling/Addiction Professional
          </div>
          <div className={myStyles.body}>
            <p>
              Counselors and addiction professionals typically administer
              clinical mental health and substance use disorder treatment
              services. The number of addiction and counseling professionals
              available in a region is important in assessing a region’s
              capacity to treat opioid use disorder. Expanding access to
              counseling and addiction professionals are a key part in fighting
              the opioid epidemic. Studies have found that health centers that
              received Medicaid behavioral health reimbursement were five times
              as likely as those that did not to offer addiction counseling
              services and to employ certified addiction counselors. In general,{" "}
              <a
                href="https://aspe.hhs.gov/sites/default/files/private/pdf/263006/CLRSUDWorkforce.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                inconsistent reimbursement
              </a>{" "}
              practices across payers relative to mental health provider type
              and state-regulations has stymied response efforts.
            </p>
            <p>
              In addition to Medicaid reimbursement, grant funding also provides
              a significant opportunity to grow the workforce and improve
              addiction treatment capacities within the health system. Research
              has highlighted that the capacity for delivering behavioral health
              services, in particular, is sensitive to grant funding. Therefore,
              increasing grant funding could be an effective way to continue
              increasing access to behavioral health services in underserved
              areas. Some{" "}
              <a
                href="https://www.ruralhealthinfo.org/topics/substance-use/funding"
                target="_blank"
                rel="noopener noreferrer"
              >
                non-profit organizations
              </a>{" "}
              provide funding to communities to improve their health care
              networks, as well as to individuals to pursue degrees in addiction
              counseling. Strengthening the counseling/addiction professional
              workforce could be an area of opportunity for states and
              localities as well. Some examples are listed below:
            </p>
            <ul>
              <li>
                <a
                  href="https://nbccfoundation.submittable.com/submit/fc97067e-8fea-4f13-b5d3-c859fc24730a/2023-nbcc-minority-fellowship-program-for-addictions-counselors-masters"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NBCC Foundation Minority Fellowship Program for Addiction
                  Counselors
                </a>
              </li>
              <li>
                <a
                  href="https://www.ruralhealthinfo.org/funding/5675"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rural Communities Opioid Response Program – Overdose Response
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
      </div>
    </>
  )
}
