import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Link, Page, Text, View } from "@react-pdf/renderer"

//Helpers
import { PDFListItem, PDFRecommendTitleStyle } from "./PDFReportHelper"
import PDFReportHeader from "./PDFReportHeader"
import PDFReportFooter from "./PDFReportFooter"

export default function PDFReportRecommendHealthcare({
  needLevel,
  needLevelStyle,
  footerText,
}) {
  const styles = reportStyles()
  const TitleStyle = PDFRecommendTitleStyle(needLevelStyle)

  return (
    <Page size="A4" style={styles.page}>
      <PDFReportHeader />
      <Text
        style={{
          ...styles.subTitle2,
          ...TitleStyle,
        }}
      >
        Healthcare: {needLevel}
      </Text>
      <Text style={styles.bodyHighlighted}>Recovery Support Services</Text>
      <Text>
        The Substance Abuse and Mental Health Services Administration (SAMHSA)’s
        working definition of recovery defines recovery as a process of change
        through which individuals improve their health and wellness, live
        self-directed lives, and strive to reach their full potential. Recovery
        support services are typically non-clinical supports that help people
        enter into and navigate systems of care, remove barriers to recovery,
        stay engaged in the recovery process, and live within their community of
        choice. These services are often provided by peer recovery coaches
        —trained and certified individuals living in recovery with mental
        illness and/or substance use disorder. Examples of support can include
        child care, employment, job training, transportation, and recreational
        activities that all contribute to better recovery outcomes, like
        increased housing stability and reduced rates of reoccurrence of SUD and
        hospitalization (Bassuk et al., 2016; Eddie et al., 2016).
      </Text>
      <Text style={styles.Paragraph}>
        Recovery support services are currently funded in a variety of ways
        through state Medicaid programs, the Substance Use Prevention,
        Treatment, and Recovery Block Grant, other SAMSHA grants, state funding
        sources, employers, and philanthropy. A majority of states cover peer
        support services for adults with substance use disorders in their
        Medicaid programs, though there are differences in who qualifies as a
        provider, supervision requirements, and what specific services are
        covered. However, additional funding is often necessary to expand access
        to services for more people (for example, youths), increase the types of
        services covered, and support training for the peer workforce (US
        Governmental Accountability Office, 2020).
      </Text>

      <Text style={styles.exampleTitle}>Examples:</Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://portal.ct.gov/DMHAS/Initiatives/DMHAS-Initiatives/Emergency-Department-Recovery-Coach-Services">
            CT Emergency Department Recovery Coach Services{" "}
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.rwjbh.org/treatment-care/institute-for-prevention-and-recovery/programs/peer-recovery-program/">
            RWJ Barnabas Health Peer Recovery Program
          </Link>{" "}
          (hospital based)
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.greatlakesrecovery.org/recovery-services/peer-recovery/">
            Marquette County Peer Recovery Services
          </Link>{" "}
          (rural)
        </PDFListItem>
      </View>
      <hr style={styles.line} />
      <Text style={{ ...styles.bodyHighlighted, ...styles.listItemSpaceTop }}>
        MH/SUD Treatment
      </Text>
      <Text>
        There are many types of treatments that professionals provide for mental
        health and substance use disorders. The amount of available mental
        health and substance use disorder treatment is important in assessing a
        region’s capacity to treat opioid use disorder.
      </Text>
      <Text style={styles.Paragraph}>
        According to the{" "}
        <Link src="https://nida.nih.gov/publications/drugfacts/treatment-approaches-drug-addiction">
          National Institute of Drug Abuse
        </Link>
        , the following are key principles that should inform the basis of any
        effective substance use treatment program:
      </Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          Addiction is a complex but treatable disease that affects brain
          function and behavior.
        </PDFListItem>
        <PDFListItem>No single treatment is right for everyone.</PDFListItem>
        <PDFListItem>
          People need to have quick access to treatment.
        </PDFListItem>
        <PDFListItem>
          Effective treatment addresses all of the patient’s needs, not just his
          or her drug use.
        </PDFListItem>
        <PDFListItem>Staying in treatment long enough is critical.</PDFListItem>
        <PDFListItem>
          Counseling and other behavioral therapies are the most commonly used
          forms of treatment.
        </PDFListItem>
        <PDFListItem>
          Medications are often an important part of treatment, especially when
          combined with behavioral therapies.
        </PDFListItem>
        <PDFListItem>
          Treatment plans must be reviewed often and modified to fit the
          patient’s changing needs.
        </PDFListItem>
        <PDFListItem>
          Treatment should address other possible mental disorders.
        </PDFListItem>
        <PDFListItem>
          Medically assisted detoxification is only the first stage of
          treatment.
        </PDFListItem>
        <PDFListItem>
          Treatment doesn't need to be voluntary to be effective.
        </PDFListItem>
        <PDFListItem>
          Drug use during treatment must be monitored continuously.
        </PDFListItem>
        <PDFListItem>
          Treatment programs should test patients for HIV/AIDS, hepatitis B and
          C, tuberculosis, and other infectious diseases as well as teach them
          about steps they can take to reduce their risk of these illnesses.
        </PDFListItem>
      </View>

      <Text style={styles.Paragraph}>
        Patients can receive treatment in several settings, such as in emergency
        departments, outpatient specialty SUD and behavioral health programs,
        primary care, via telehealth, and/or inpatient/residential treatment
        centers. Adjunct services are now being expanded using mobile technology
        for asynchronous treatment engagement and providing contingency
        management incentives to increase retention and engagement of
        individuals in treatment.
      </Text>
      <Text style={styles.Paragraph}>
        Many states and jurisdictions provide treatments for individuals
        struggling with substance use disorders. For example,{" "}
        <Link src="https://www.nyc.gov/site/doh/health/health-topics/alcohol-and-drug-use-services.page">
          New York City
        </Link>{" "}
        provides many free and low-cost services for people struggling with drug
        or alcohol use disorder, such as a{" "}
        <Link src="https://www.samhsa.gov/medication-assisted-treatment/find-treatment/treatment-practitioner-locator">
          Buprenorphine Physician Locator
        </Link>
        . Also, The District of Columbia provides{" "}
        <Link src="https://dbh.dc.gov/service/treatment-services">
          Assessment and Referral sites
        </Link>{" "}
        that deliver same-day assessments and referrals for individuals seeking
        treatment for substance use disorders.
      </Text>
      <Text style={styles.Paragraph}>
        SAMHSA provides a national treatment locator at{" "}
        <Link src="https://findtreatment.gov/">FindTreatment.gov</Link>.
      </Text>
      <hr style={styles.line} />
      <Text style={{ ...styles.bodyHighlighted, ...styles.Paragraph }}>
        Counseling/Addiction Professional
      </Text>
      <Text>
        Counselors and addiction professionals typically administer clinical
        mental health and substance use disorder treatment services. The number
        of addiction and counseling professionals available in a region is
        important in assessing a region’s capacity to treat opioid use disorder.
        Expanding access to counseling and addiction professionals are a key
        part in fighting the opioid epidemic. Studies have found that health
        centers that received Medicaid behavioral health reimbursement were five
        times as likely as those that did not to offer addiction counseling
        services and to employ certified addiction counselors. In general,{" "}
        <Link src="https://aspe.hhs.gov/sites/default/files/private/pdf/263006/CLRSUDWorkforce.pdf">
          inconsistent reimbursement
        </Link>{" "}
        practices across payers relative to mental health provider type and
        state-regulations has stymied response efforts.
      </Text>
      <Text style={styles.Paragraph}>
        In addition to Medicaid reimbursement, grant funding also provides a
        significant opportunity to grow the workforce and improve addiction
        treatment capacities within the health system. Research has highlighted
        that the capacity for delivering behavioral health services, in
        particular, is sensitive to grant funding. Therefore, increasing grant
        funding could be an effective way to continue increasing access to
        behavioral health services in underserved areas. Some{" "}
        <Link src="https://www.ruralhealthinfo.org/topics/substance-use/funding">
          non-profit organizations
        </Link>{" "}
        provide funding to communities to improve their health care networks, as
        well as to individuals to pursue degrees in addiction counseling.
        Strengthening the counseling/addiction professional workforce could be
        an area of opportunity for states and localities as well. Some examples
        are listed below:
      </Text>
      <View style={styles.listContainer}>
        <PDFListItem>
          <Link src="https://nbccfoundation.submittable.com/submit/fc97067e-8fea-4f13-b5d3-c859fc24730a/2023-nbcc-minority-fellowship-program-for-addictions-counselors-masters">
            NBCC Foundation Minority Fellowship Program for Addiction Counselors
          </Link>
        </PDFListItem>
        <PDFListItem>
          <Link src="https://www.ruralhealthinfo.org/funding/5675">
            Rural Communities Opioid Response Program – Overdose Response
          </Link>
        </PDFListItem>
      </View>
      <PDFReportFooter text={footerText} />
    </Page>
  )
}
