import React from "react"

//react-pdf
import { Link, Text, View } from "@react-pdf/renderer"

//styles
import reportStyles from "./PDFReportStyles"

import { PDFCustomListItem } from "./PDFReportHelper"

export default function PDFReportHowToUse({ reportType }) {
  const styles = reportStyles()

  const GetStateText = () => {
    return (
      <View style={styles.listContainer}>
        <PDFCustomListItem bullet={"1."}>
          <Text style={styles.textBold}>
            Understand the state’s opioid settlement plan.
          </Text>{" "}
          It is important to understand how the selected state has decided to
          allocate funds from current opioid settlements in order to guide
          spending.{" "}
          <Link src="https://www.opioidsettlementtracker.com/globalsettlementtracker/#statuses">
            The Opioid Settlement Tracker
          </Link>{" "}
          includes the status of state opioid settlement settlements and
          publicly available information on{" "}
          <Link src="https://www.opioidsettlementtracker.com/settlementspending/#plans">
            state allocation plans
          </Link>
          , where available. If you are involved in a state or county’s planning
          processes for opioid settlement funds, you may already have this
          information, including specific dollar amounts to be received. The
          National Academy for State Health Policy’s{" "}
          <Link src="https://nashp.org/state-approaches-for-distribution-of-national-opioid-settlement-funding/">
            tracker
          </Link>{" "}
          has information on the{" "}
          <Link src="https://nationalopioidsettlement.com/">
            National Opioid Settlement
          </Link>{" "}
          specifically.
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"2."}>
          <Text style={styles.textBold}>
            Review the recovery investment opportunities below.
          </Text>{" "}
          We used the selected state’s performance across specific indicators
          (see the Appendix below) within the Healthy People 2030 Domains to
          create a prioritized list of potential investment areas for opioid
          settlement funds, called{" "}
          <Text style={styles.textBold}>Recovery Investment Opportunities</Text>
          . Domains where the selected state scored in the red represent the{" "}
          <Text style={{ textDecoration: "underline" }}>
            highest level of potential need
          </Text>{" "}
          and are listed first below, whereas those where the county scored in
          the green represent{" "}
          <Text style={{ textDecoration: "underline" }}>
            lower potential need
          </Text>{" "}
          for resources.
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"3."}>
          <Text style={styles.textBold}>
            Include stakeholder feedback and other data sources.
          </Text>{" "}
          These recovery investment opportunities are based on a review of a
          limited set of publicly available data sources and are intended to
          provide general guidance on interventions and evidence-based
          practices. This information should be considered alongside feedback
          from a diverse set of stakeholders, including community members,
          individuals with lived experience with substance use disorders, health
          care providers, community-based organizations, and others with an
          in-depth understanding of the existing needs within a community. You
          may also have access to recent information gathered by your state or
          county, such as data dashboards or local needs assessments.
        </PDFCustomListItem>
      </View>
    )
  }

  const GetCountyText = () => {
    return (
      <View style={styles.listContainer}>
        <PDFCustomListItem bullet={"1."}>
          <Text style={styles.textBold}>
            Understand the state’s opioid settlement plan.
          </Text>
          <Text>
            {" "}
            Before making decisions around spending, it is important to
            understand how the selected state and county have decided to
            allocate funds from current opioid settlements in order to guide
            spending.{" "}
            <Link src="https://www.opioidsettlementtracker.com/globalsettlementtracker/#statuses">
              The Opioid Settlement Tracker
            </Link>{" "}
            includes the status of state opioid settlement settlements and
            publicly available information on{" "}
            <Link src="https://www.opioidsettlementtracker.com/settlementspending/#plans">
              state allocation plans
            </Link>
            , where available. If you are involved in the selected state or
            county’s planning processes for opioid settlement funds, you may
            already have this information, including specific dollar amounts to
            be received. The National Academy for State Health Policy’s{" "}
            <Link src="https://nashp.org/state-approaches-for-distribution-of-national-opioid-settlement-funding/">
              tracker
            </Link>{" "}
            has information on the{" "}
            <Link src="https://nationalopioidsettlement.com/">
              National Opioid Settlement
            </Link>{" "}
            specifically.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"2."}>
          <Text style={styles.textBold}>
            Review the recovery investment opportunities below.
          </Text>

          <Text>
            {" "}
            We used the selected county’s performance across specific indicators
            (see the Appendix below) within the Healthy People 2030 Domains to
            create a prioritized list of potential investment areas for opioid
            settlement funds, called{" "}
          </Text>
          <Text style={styles.textBold}>Recovery Investment Opportunities</Text>
          <Text>
            . Domains where the selected county scored in the red represent the{" "}
          </Text>
          <Text style={{ textDecoration: "underline" }}>
            highest level of potential need
          </Text>
          <Text>
            {" "}
            and are listed first below, whereas those where the county scored in
            the green represent <Text style={{}}>lower potential need</Text> for
            resources.
          </Text>
        </PDFCustomListItem>
        <PDFCustomListItem bullet={"3."}>
          <Text style={styles.textBold}>
            Include stakeholder feedback and other data sources.
          </Text>
          <Text>
            {" "}
            These recovery investment opportunities are based on a review of a
            limited set of publicly available data sources and are intended to
            provide general guidance on interventions and evidence-based
            practices. This information should be considered alongside feedback
            from a diverse set of stakeholders, including community members,
            individuals with lived experience with substance use disorders,
            health care providers, community-based organizations, and others
            with an in-depth understanding of the existing needs within a
            community. You may also have access to recent information gathered
            by your state or county, such as data dashboards or local needs
            assessments. Resources on Needs Assessments and other planning tools
            for counties are available in National Association of Counties’
            Planning Principles Toolkit.{" "}
          </Text>
        </PDFCustomListItem>
      </View>
    )
  }

  return (
    <>
      <Text style={styles.title}>HOW TO USE THIS REPORT</Text>
      {reportType === "State" ? <GetStateText /> : <GetCountyText />}
    </>
  )
}
