import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css"
import myStyle from "../../styles/Default/choropleth.module.css"
import MapboxChoropleth from "./choroplethCreator"
import { popupGetBase, popupCountyHtml } from "./choroplethPopup"

export default function Choropleth({
  mapBoxToken,
  valueData,
  valueFieldName,
  valueFieldType,
  valueDisplayName,
  valueDisplayPrefix,
  valueDisplaySuffix,
  valueKeyToGeoJsonId,
  filterColumn,
  filterValue,
  geojsonURL,
  geojsonIdColName,
  colorScheme,
  centerLongLat,
  zoom,
  boundingBox,
  boundingBoxRecenterToggle,
  hideLegend,
  getCustomLegend, //Custom legend generator
  selectedIdValue, //The selected value in geojsonIdColName
  parentElementId,
  onFeatureClick,
}) {
  mapboxgl.accessToken = mapBoxToken

  //const MapboxChoropleth = require("mapbox-choropleth")

  //MapBox variables
  const mapContainer = useRef(null)
  const map = useRef(null)
  const defaultCenter = [-98.5795, 39.82818] //[longitude, latitude]
  const defaultZoom = 7
  const defaultBoundingBox = [
    [-133.2421875, 16.972741],
    [-47.63671875, 52.696361],
  ] // Array of 2x combinations of [longitude, latitude]
  const [mapObj, setMapObj] = useState(undefined)

  const hoveredCountyRef = useRef(undefined)
  const filteredData = useRef(undefined)
  const variableValues = useRef(undefined)
  const popup = useRef(popupGetBase())
  const currentSelectedIdValue = useRef(selectedIdValue)
  const selectedFeatureId = useRef(undefined)
  const selectedMapId = useRef(undefined)
  const choroplethLayerId = "choropleth"

  //Choropleth variables
  const getChoropleth = (data) => {
    return new MapboxChoropleth({
      tableRows: data,
      tableNumericField: valueFieldName,
      tableIdField: valueKeyToGeoJsonId,
      fieldDataType: valueFieldType,
      geometryType: "geojson",
      geometryUrl: geojsonURL,
      geometryIdField: geojsonIdColName,
      sourceLayer: "COUNTY",
      binCount: 9,
      colorScheme: colorScheme || ["#B6D5E8", "#084C8F"],
      //before: "state-label",
      lineLayerWidthHover: 5,
    })
  }

  const onMapStyleAndSourceLoaded = (fn) => {
    // It seems to be so hard to reliably add a layer without hitting a 'style not ready' error.
    if (map.current.isStyleLoaded()) {
      if (!map.current.getSource(choroplethLayerId)) {
        const nextFn = () =>
          window.setTimeout((fn) => {
            onMapStyleAndSourceLoaded(fn)
          }, 10)
        nextFn()
      } else {
        const nextFn = () => window.setTimeout(fn, 0)
        nextFn()
      }
    } else {
      map.current.once("style.load", (fn) => {
        onMapStyleAndSourceLoaded(fn)
      })
    }
  }

  useEffect(() => {
    variableValues.current = {
      valueFieldName,
      valueDisplayName,
      valueDisplayPrefix,
      valueDisplaySuffix,
    }
  }, [valueFieldName, valueDisplayName, valueDisplayPrefix, valueDisplaySuffix])

  /*=======================
  Choropleth
=======================*/

  const mouseMove = useRef((e) => {
    if (e.features.length > 0 && !!variableValues.current) {
      if (!!hoveredCountyRef.current || hoveredCountyRef.current === 0) {
        map.current.setFeatureState(
          { source: choroplethLayerId, id: hoveredCountyRef.current },
          { hover: false }
        )
      }

      const dataSet = filteredData.current.filter(
        (filter) => filter.map_geo_id === e.features[0].properties.GEO_ID
      )
      if (dataSet.length > 0) {
        hoveredCountyRef.current = e.features[0].id

        map.current.setFeatureState(
          { source: choroplethLayerId, id: e.features[0].id },
          { hover: true }
        )

        popup.current
          .setLngLat({ lat: e.lngLat.lat, lng: e.lngLat.lng })
          .setHTML(
            popupCountyHtml(
              dataSet[0],
              variableValues.current.valueFieldName,
              variableValues.current.valueDisplayName,
              variableValues.current.valueDisplayPrefix,
              variableValues.current.valueDisplaySuffix
            )
          )
          .addTo(map.current)
      } else {
        popup.current.remove()
      }
    }
  })

  const mouseLeave = useRef(() => {
    if (!!hoveredCountyRef.current) {
      map.current.setFeatureState(
        { source: choroplethLayerId, id: hoveredCountyRef.current },
        { hover: false }
      )

      hoveredCountyRef.current = undefined
      popup.current.remove()
    }
  })

  const mouseClick = useRef((e) => {
    if (e.features.length > 0 && !!onFeatureClick) {
      onFeatureClick(e.features[0].properties.GEO_ID)
    }
  })

  const addChoroplethToMap = (data) => {
    //Remove events
    if (map.current.getLayer("choropleth")) {
      map.current.off("mousemove", choroplethLayerId, mouseMove.current)
      map.current.off("mouseleave", choroplethLayerId, mouseLeave.current)
      map.current.off("click", choroplethLayerId, mouseClick.current)
    }

    filteredData.current = data
    const test = getChoropleth(data)
    test.addTo(map.current)
    test.table.then(() => {
      setMapObj(test)

      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.
      map.current.on("mousemove", choroplethLayerId, mouseMove.current)

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.current.on("mouseleave", choroplethLayerId, mouseLeave.current)

      // When a mouse click is triggered on a state-fill layer item, select the county
      map.current.on("click", choroplethLayerId, mouseClick.current)

      map.current.on("render", choroplethLayerId, () => {
        showSelectedArea()
      })

      map.current.on("moveend", () => {
        showSelectedArea()
      })

      onMapStyleAndSourceLoaded(() => {
        addFeatureState(data)
      })
    })
  }

  const addFeatureState = (data) => {
    for (let row of data) {
      map.current.setFeatureState(
        {
          id: row[valueKeyToGeoJsonId],
          source: choroplethLayerId,
        },
        {
          isSelected: !currentSelectedIdValue.current
            ? false
            : currentSelectedIdValue.current === row[valueKeyToGeoJsonId],
          hover: false,
        }
      )
    }
  }

  const findFeature = (geo_id) => {
    if (!!geo_id && map.current.getLayer(choroplethLayerId)) {
      let features = map.current.queryRenderedFeatures({
        layers: [choroplethLayerId],
      })

      let feature = undefined
      if (features.length > 0) {
        features = features.filter(
          (feature) => feature.properties.GEO_ID === geo_id
        )
        if (features.length > 0) {
          feature = features[0]
        }
      }

      return feature
    }
  }

  const setSelectedFeatureBorder = () => {
    if (
      map.current &&
      currentSelectedIdValue.current !== selectedMapId.current
    ) {
      if (map.current.getLayer(choroplethLayerId)) {
        let features = map.current.queryRenderedFeatures({
          layers: [choroplethLayerId],
        })

        let featureId = undefined
        if (features.length > 0) {
          features = features.filter(
            (feature) =>
              feature.properties.GEO_ID === currentSelectedIdValue.current
          )
          if (features.length > 0) {
            featureId = features[0].id
          } else {
            featureId = undefined
          }
        }

        //unselect previously selected Id
        if (!!selectedFeatureId.current || selectedFeatureId.current === 0) {
          map.current.setFeatureState(
            { source: choroplethLayerId, id: selectedFeatureId.current },
            { isSelected: false }
          )

          selectedMapId.current = undefined
          selectedFeatureId.current = undefined
        }

        //select new selected Id
        if (!!featureId || featureId === 0) {
          map.current.setFeatureState(
            { source: choroplethLayerId, id: featureId },
            { isSelected: true }
          )

          selectedMapId.current = currentSelectedIdValue.current
          selectedFeatureId.current = featureId
        }
      }
    }
  }

  const showSelectedArea = () => {
    if (currentSelectedIdValue.current) {
      const feature = findFeature(selectedMapId.current)

      if (
        !!feature &&
        (!!selectedFeatureId.current || selectedFeatureId.current === 0)
      ) {
        if (!feature.state.isSelected) {
          map.current.setFeatureState(
            { source: choroplethLayerId, id: selectedFeatureId.current },
            { isSelected: true }
          )
        }
      } else {
        setSelectedFeatureBorder()
      }
    }
  }

  const fitToBounds = () => {
    //TODO: Make this a prop and focus closer to USA
    if (map.current) {
      map.current.fitBounds(!!boundingBox ? boundingBox : defaultBoundingBox)
    }
  }

  useEffect(() => {
    let data = valueData
    if (!!data) {
      if (filterColumn) {
        data = data.filter((x) => x[filterColumn] === filterValue)
      }

      if (!!map.current) {
        addChoroplethToMap(data)
      } else {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v11",
          center: !!centerLongLat ? centerLongLat : defaultCenter,
          zoom: !!zoom ? zoom : defaultZoom,
        })
        map.current.on("load", function () {
          //TODO: Make this a prop and focus closer to USA
          fitToBounds()

          addChoroplethToMap(data)
        })
      }
    }
  }, [valueData, valueFieldName, filterValue, filterColumn, map.current])

  //When the parent component's height changes, resize the map to fit
  /*   useEffect(() => {
    if (mapParent && map.current) {
      setMapHeight(mapParent.offsetHeight)
      map.current.resize()
      fitToBounds()
    }
  }, [mapParent.offsetHeight]) */

  useEffect(() => {
    fitToBounds()
  }, [boundingBox])

  useEffect(() => {
    currentSelectedIdValue.current = selectedIdValue
    setSelectedFeatureBorder()
  }, [selectedIdValue, map.current])

  useEffect(() => {
    if (boundingBoxRecenterToggle !== undefined) {
      fitToBounds()
    }
  }, [boundingBoxRecenterToggle])

  //Generate a map legend to display on the MapBox map
  //Reverse the color bins to show from maximum to minimum
  function GetLegend() {
    if (!!getCustomLegend) {
      return getCustomLegend(mapObj)
    } else {
      if (!mapObj || mapObj.bins.length === 0) {
        return null
      }

      const middle = Math.ceil(mapObj.bins.length / 2) - 1

      //Calculate the color for a given value and return a block with that color.
      // If the block is at the beginning, middle or end of the range, display the value as well
      const getBin = (value, index) => {
        const col = mapObj.colorScale(value[0])
        return (
          <div style={{ height: "20px" }}>
            <span
              className={myStyle.choroplethLegendBox}
              style={{ backgroundColor: col.hex() }}
            ></span>
            <span className={myStyle.choroplethLegendLabel}>
              {index === 0 ||
              index === mapObj.bins.length - 1 ||
              index === middle
                ? mapObj.numberFormatFunc(value[0])
                : null}
            </span>
          </div>
        )
      }

      return (
        <div className={myStyle.choroplethLegend}>
          {mapObj.bins.reverse().map((x, index) => getBin(x, index))}
        </div>
      )
    }
  }

  useEffect(() => {
    if (!hideLegend && !!map.current) {
      map.current.resize()
    }
  }, [hideLegend])

  //style={{ height: getMapHeight() }}

  return (
    <>
      <div
        ref={mapContainer}
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
      />
      {mapObj && !hideLegend ? (
        <div className={myStyle.legend}>{GetLegend()}</div>
      ) : null}
    </>
  )
}
