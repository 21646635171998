import React, { useEffect, useState } from "react"
import RouteBuilder from "./config/RouteBuilder"
import "primereact/resources/primereact.min.css"
import "./styles/App.css"
import "primeicons/primeicons.css"
import { MobileView } from "react-device-detect"

import MainHeader from "./components/header/MainHeader"
import MainFooter from "./components/footer/MainFooter"

// Context
import { MainContext } from "./context/mainContext"

//Helpers
import getCountyData from "./helpers/getCountyData"
import getStateData from "./helpers/getStateData"
import {
  getScoreCardDataCounty,
  getScoreCardDataState,
} from "./helpers/getScoreCardData"
import { getDataMap, getDataColumnNames } from "./helpers/dataMap"
import getNationalData from "./helpers/getNationalData"

//pages
import Login from "./pages/Login/Login"

//Google analytics
import { install } from "ga-gtag"

function App() {
  const [location, setLocation] = useState({
    state_fips: "",
    state: "",
    county_fips: "",
    county: "",
    focusColumn: undefined,
    optionType: undefined,
  })

  const doLock = process.env.REACT_APP_USE_LOCK
  const gaCode = process.env.REACT_APP_GOOGLE_ANALYTIC_CODE

  //Set the Global Site Tag script in head. Also disable auto page view measurement for SPA
  install(gaCode, { send_page_view: false })

  const [locked, setLocked] = useState(!doLock || doLock === "YES")

  const [mapDataSelection, setMapDataSelection] = useState(undefined)
  const [mapBoundingBox, setMapBoundingBox] = useState(undefined)
  const [mapZoon, setMapZoom] = useState(undefined)
  const [showMobileWarning, setShowMobileWarning] = useState(true)

  //Selector
  const [countyOptions, setCountyOptions] = useState(undefined)
  const [stateOptions, setStateOptions] = useState(undefined)
  const [countyData, setCountyData] = useState(undefined)
  const [stateData, setStateData] = useState(undefined)
  const [nationalData, setNationalData] = useState(undefined)
  const [scoreCardDataCounty, setScoreCardDataCounty] = useState(undefined)
  const [scoreCardDataState, setScoreCardDataState] = useState(undefined)
  const [recoveryClassData, setRecoveryClassData] = useState(undefined)
  const [showTutorial, setShowTutorial] = useState(true)

  const unlock = (username, password) => {
    if (
      username === process.env.REACT_APP_UN &&
      password === process.env.REACT_APP_PWD
    ) {
      setLocked(false)
    } else {
      return "Incorrect credentials"
    }
  }

  const mainContextValue = {
    location,
    setLocation,
    mapDataSelection,
    setMapDataSelection,
    mapBoundingBox,
    setMapBoundingBox,
    mapZoon,
    setMapZoom,
    countyOptions,
    stateOptions,
    countyData,
    stateData,
    nationalData,
    scoreCardDataCounty,
    scoreCardDataState,
    recoveryClassData,
    setRecoveryClassData,
    showTutorial,
    setShowTutorial,
    locked,
    unlock,
  }

  const formatDatasetColumns = (dataSet) => {
    //TODO: Convert this to be all values except key values
    const formatCols = getDataColumnNames()

    const convertRow = (row) => {
      formatCols.forEach((col) => {
        if (row[col] === "NA") {
          row[col] = undefined
        } else {
          let value = row[col]
          if (value !== undefined) {
            value = value.replace(" ", "")
          }
          row[col] = +(+value).toFixed(2)
        }
      })

      return row
    }

    return dataSet.data.map((x) => convertRow(x))
  }

  const processNationalData = (dataSet) => {
    let data = formatDatasetColumns(dataSet)
    setNationalData(data[0])
  }

  const processStateData = (dataSet) => {
    let data = formatDatasetColumns(dataSet)
    setStateData(data)

    setStateOptions([
      ...new Map(
        data.map((x) => {
          return [
            x.state_FIPS,
            {
              state_fips: x.state_FIPS,
              state: x.GEOID,
              county_fips: "",
              county: "",
              focusColumn: "state_FIPS",
              focusValue: x.state_FIPS,
              label: `${x.Name}`,
              optionType: "State",
              mapId: x.state_FIPS,
              hideOption: false,
            },
          ]
        })
      ).values(),
    ])
  }

  const processCountiesData = (dataSet) => {
    let data = formatDatasetColumns(dataSet)

    setCountyOptions(
      data.map((x) => {
        return {
          state_fips: x.state_FIPS,
          state: x.state,
          county_fips: x.county_FIPS,
          county: x.county,
          focusColumn: "state_FIPS",
          focusValue: x.state_FIPS,
          label: `${x.county}, ${x.state}`,
          optionType: "County",
          mapId: x.map_geo_id,
          hideOption: x.state === "DC",
        }
      })
    )

    /*     setStateOptions([
      ...new Map(
        data.map((x) => {
          return [
            x.state_FIPS,
            {
              state_fips: x.state_FIPS,
              state: x.state,
              county_fips: "",
              county: "",
              focusColumn: "state_FIPS",
              focusValue: x.state_FIPS,
              label: `${x.state}`,
              optionType: "State",
              mapId: x.state_FIPS,
            },
          ]
        })
      ).values(), 
    ])*/

    setCountyData(data)
  }

  useEffect(() => {
    if (!countyData) {
      getCountyData(processCountiesData)
    }

    if (!stateData) {
      getStateData(processStateData)
    }

    if (!nationalData) {
      getNationalData(processNationalData)
    }

    if (!scoreCardDataCounty) {
      getScoreCardDataCounty((data) => {
        setScoreCardDataCounty(data.data)
      })
    }

    if (!scoreCardDataState) {
      getScoreCardDataState((data) => {
        setScoreCardDataState(data.data)
      })
    }

    if (!mapDataSelection) {
      const dataMap = getDataMap()
      setMapDataSelection({
        group: dataMap[0],
        field: dataMap[0].columnNames[0],
      })
    }
  }, [])

  return (
    <MainContext.Provider value={mainContextValue}>
      {locked ? (
        <Login />
      ) : (
        <div className="mainContainer">
          <div className="mainHeader">
            <MobileView>
              {showMobileWarning ? (
                <div className="mobile-warning">
                  This tool is best viewed on desktop browsers
                  <button
                    className="btn mobile-warning-btn"
                    onClick={() => setShowMobileWarning(false)}
                  >
                    X
                  </button>
                </div>
              ) : null}
            </MobileView>
            <MainHeader />
          </div>
          <div className="mainBody">
            <RouteBuilder />
          </div>
          <div className="mainFooter">
            <MainFooter />
          </div>
        </div>
      )}
    </MainContext.Provider>
  )
}

export default App
