import Papa from "papaparse"
import CSVData from "../assets/data/State.Data.csv"

export default function getStateData(onDataRetrieved) {
  return fetch(CSVData)
    .then((data) => data.text())
    .then((responseText) => {
      Papa.parse(responseText, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          result.data = result.data.map((row) => {
            let geoID = row.fips_state.padStart(2, "0")

            return {
              ...row,
              state_FIPS: geoID,
            }
          })
          onDataRetrieved(result)
        },
      })
    })
}
