import { GetBaseRoute, GetBaseImageRoute } from "./getEnvVariableHelper"

export default function getPath(path) {
  let baseRoute = GetBaseRoute()

  if (!!baseRoute) {
    baseRoute = baseRoute + "/"
  }

  return `${baseRoute}${path}`
}

export function getImgPath(path) {
  let baseRoute = GetBaseImageRoute()

  if (!!baseRoute) {
    baseRoute = `${baseRoute}/`
  } else {
    baseRoute = "/"
  }

  return `${baseRoute}${path}`
}
