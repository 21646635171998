import { Image, Link, Text, View } from "@react-pdf/renderer"
import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

export default function PDFReportIntro({ reportType, reportSelection }) {
  const styles = reportStyles()

  const GetStateText = () => {
    return (
      <Text style={styles.textRegular}>
        This tool uses publicly available state and county data organized by the
        Healthy People 2030{" "}
        <Link src="https://health.gov/healthypeople/priority-areas/social-determinants-health">
          Social Determinants of Health Domains
        </Link>{" "}
        You can see a state’s overall performance by domain by clicking on the{" "}
        <Text style={styles.textBold}>Monitor</Text> tab within this tool, or
        viewing the <b>Appendix</b> at the end of this report. The indicators
        selected can help you understand the potential demand for substance use
        and mental health prevention, treatment, and recovery support services
        in a state. For county-level information, click on the <b>Explore</b>{" "}
        tab, select "County" in the drop down menu, and type in the county name.
      </Text>
    )
  }

  const GetCountyText = () => {
    return (
      <Text style={styles.textRegular}>
        This tool uses publicly available state and county data organized by the
        Healthy People 2030{" "}
        <Link src="https://health.gov/healthypeople/priority-areas/social-determinants-health">
          Social Determinants of Health Domains
        </Link>{" "}
        to provide comparisons to state and national averages. You can see a
        county’s overall performance by domain by clicking on the{" "}
        <Text style={styles.textBold}>Monitor</Text> tab within this tool, or
        viewing the <Text style={styles.textBold}>Appendix</Text> at the end of
        this report. The indicators selected can help you understand the
        potential demand for substance use and mental health prevention,
        treatment, and recovery support services in a county.{" "}
      </Text>
    )
  }

  const imgUrl = async () => {
    const imageUrl = `${window.location.origin}/margolis-logo-white.png`
    return imageUrl
  }

  return (
    <>
      <View style={styles.headerView}>
        <Text style={{ paddingLeft: "-5mm" }}>
          <Image
            src={imgUrl}
            style={{
              height: "45px",
              width: "300px",
              margin: "0px",
              marginBottom: "20mm",
            }}
          />
        </Text>
      </View>
      <View style={styles.body}>
        <Text style={styles.reportTitle}>
          {reportSelection || "No selection available"} Report
        </Text>
        <Text style={styles.textRegular}>
          This tool provides state and local officials, community members, and
          others involved in addressing the opioid epidemic with tailored
          guidance on areas of need in their communities and where to target
          spending funds from the{" "}
          <Link src="https://www.opioidsettlementtracker.com">
            billions of dollars in opioid settlements
          </Link>{" "}
          reached with manufacturers, pharmaceutical distributors, and
          pharmacies.
        </Text>
        <Text style={styles.title}>ABOUT THE DATA</Text>
        {reportType === "State" ? <GetStateText /> : <GetCountyText />}
      </View>
    </>
  )
}
