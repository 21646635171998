import React, { useState } from "react"

//primeReact:
import { Dialog } from "primereact/dialog"

//Styles
import myStyles from "../../styles/Default/infoModal.module.css"

export default function InfoModal({ show, onHide }) {
  const [hoverClose, setHoverClose] = useState(false)
  return (
    <Dialog
      visible={show}
      header={
        <div className={myStyles.header}>
          <div className={myStyles.headerGrid}>
            <div
              className={myStyles.headerTitle}
            >{`Recovery Investment Opportunities`}</div>
          </div>
          <div className={myStyles.headerButton}>
            <button
              className={`${myStyles.headerCloseButton} ${
                hoverClose ? myStyles.headerCloseButtonHover : ""
              }`}
              onMouseEnter={() => setHoverClose(true)}
              onMouseLeave={() => setHoverClose(false)}
              onClick={onHide}
            >
              X
            </button>
          </div>
        </div>
      }
      closable={false}
      onHide={onHide}
      className={`${myStyles.dialog}`}
    >
      <>
        <div className={myStyles.data}>
          Recovery Investment Opportunities are potential areas for investment
          of opioid settlement funds. <br />
          <br />
          Click on the bubbles below for a description of each area. <br />
          <br />
          For more information and examples of each area, click{" "}
          <b>Generate Report</b> above.
        </div>
      </>
    </Dialog>
  )
}
