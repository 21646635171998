import React, { useState } from "react"

//primeReact:
import { Dialog } from "primereact/dialog"

//Styles
import myStyles from "../../styles/Default/infoModal.module.css"
import legendStyles from "../../styles/Default/scoreCardLegend.module.css"

export default function LegendModal({ show, onHide, getScoreColor }) {
  const [hoverClose, setHoverClose] = useState(false)

  function GetLegendPallette() {
    /*     const minValue = -2
    const maxValue = 2
    const increment = 0.4 */

    const legendColors = ["green", "#99CC00", "#FF9900", "red"]

    //Get the colors
    /*     let currentValue = minValue - increment
    do {
      currentValue = currentValue + increment
      legendColors.push(getScoreColor(currentValue))
    } while (currentValue < maxValue) */

    return (
      <div className={legendStyles.container}>
        {legendColors.map((x, index) => (
          <div
            className={legendStyles.legendColorItem}
            style={{ backgroundColor: x }}
            key={index}
          ></div>
        ))}
      </div>
    )
  }

  return (
    <Dialog
      visible={show}
      onHide={onHide}
      closable={false}
      header={
        <div className={myStyles.header}>
          <div className={myStyles.headerGrid}>
            <div className={myStyles.headerTitle}>Need index</div>
          </div>
          <div className={myStyles.headerButton}>
            <button
              className={`${myStyles.headerCloseButton} ${
                hoverClose ? myStyles.headerCloseButtonHover : ""
              }`}
              onMouseEnter={() => setHoverClose(true)}
              onMouseLeave={() => setHoverClose(false)}
              onClick={onHide}
            >
              X
            </button>
          </div>
        </div>
      }
      className={legendStyles.modal}
    >
      <GetLegendPallette />
      The Need Index incorporates all available potential need variables for
      that domain, providing quick insight for investment priorities. It is
      represented on a color scale from{" "}
      <span style={{ color: "green" }}>green (lowest need)</span> to{" "}
      <span style={{ color: "red" }}>red (high need)</span>.
    </Dialog>
  )
}
