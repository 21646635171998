import React from "react"

//styles
import reportStyles from "./PDFReportStyles"

//react-pdf
import { Image, Text } from "@react-pdf/renderer"

import html2canvas from "html2canvas"

export default function PDFReportAppendix({ ref }) {
  const styles = reportStyles()

  const getImage = async () => {
    const node = document.getElementById("scoreCard")
    let canvas = await html2canvas(node, {
      scrollY: -window.scrollY,
    })
    const url = canvas.toDataURL("image/jpeg")

    return url
  }

  return (
    <>
      <Text style={styles.title}>Appendix</Text>
      <Text
        style={{
          ...styles.textRegular,
          paddingLeft: "0mm",
          paddingRight: "0mm",
        }}
      >
        <Image src={getImage} style={{ height: "410px", width: "550px" }} />
      </Text>
    </>
  )
}
