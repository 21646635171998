import React from "react"

//styles
import reportStyles from "./PDFReportStyles" //styles
import originalStyles from "../../../styles/Default/report.module.css"

//react-pdf
import { Text, View } from "@react-pdf/renderer"

function GetTab({ tabs }) {
  const styles = reportStyles()

  if (!!tabs) {
    let tabArray = []

    while (tabArray.length <= tabs) {
      tabArray.push(
        <Text
          style={{ ...styles.textRegular, marginHorizontal: 8, minWidth: 10 }}
        >
          {" "}
        </Text>
      )
    }

    return tabArray
  }

  return null
}

export function PDFCustomListItem({ bullet, tabs, customStyles, children }) {
  const styles = reportStyles()

  return (
    <View style={{ ...styles.listItemContainer, ...customStyles }}>
      <GetTab tabs={tabs} />
      <Text
        style={{ ...styles.textRegular, marginHorizontal: 8, minWidth: 10 }}
      >
        {bullet}
      </Text>
      <Text style={{ ...styles.textRegular, width: "100%" }}>{children}</Text>
    </View>
  )
}

export function PDFListItem({ tabs, customStyles, children }) {
  const styles = reportStyles()

  return (
    <View style={{ ...styles.listItemContainer, ...customStyles }} wrap={false}>
      <GetTab tabs={tabs} />
      <Text style={{ ...styles.textRegular, marginHorizontal: 8 }}>
        {`\u2022`}
      </Text>
      <Text style={{ ...styles.textRegular, width: "100%" }}>{children}</Text>
    </View>
  )
}

export function PDFRecommendTitleStyle(needLevelStyle) {
  const styles = reportStyles()
  let pdfStyle = {}

  switch (needLevelStyle) {
    case originalStyles.needHigh:
      pdfStyle = styles.recommendationHighNeedText
      break
    case originalStyles.needModerate:
      pdfStyle = styles.recommendationModerateNeedText
      break
    case originalStyles.needLow:
      pdfStyle = styles.recommendationLowNeedText
      break
    case originalStyles.needLowest:
      pdfStyle = styles.recommendationLowestNeedText
      break
    default:
      break
  }

  return pdfStyle
}
