import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommendEducation({
  needLevel,
  needLevelStyle,
}) {
  return (
    <>
      <div
        className={`${myStyles.mainContainer} ${myStyles.mainContainerRecommendItem} ${needLevelStyle}`}
      >
        <div className={myStyles.mainBody}>
          <div className={`${myStyles.subTitle2} ${myStyles.noMargin}`}>
            Education: {needLevel}
          </div>
        </div>
      </div>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <br />
          <div className={myStyles.bodyHighlighted}>
            School-based Recovery Support Services
          </div>
          <div className={myStyles.body}>
            <p>
              School-based recovery services are designed to help students
              achieve their educational goals while supporting their sustained
              recovery from SUDs or co-occurring disorders. Both high schools
              and higher education institutions offer education-based recovery
              support services. Studies have shown that students enrolled in
              recovery high schools were much more likely than those not
              enrolled in such schools to report being substance-free six months
              after they were first surveyed.
            </p>
            <p>
              Within higher education,{" "}
              <a
                href="https://collegiaterecovery.org/crps-crcs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Collegiate Recovery Programs
              </a>{" "}
              provide seamless access to recovery-related educational and social
              supports for in-need college students. The{" "}
              <a
                href="https://dbh.dc.gov/service/recovery-support-services"
                target="_blank"
                rel="noopener noreferrer"
              >
                DC government
              </a>{" "}
              also provides Education Support Services that provide group and
              one-on-one instruction focusing on expanding clients’ knowledge
              base, ranging from substance use disorder reoccurrence prevention
              to GED and employment preparation. In addition, the{" "}
              <a
                href="https://recoveryschools.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Association of Recovery Schools
              </a>{" "}
              provide resources for recovery high schools and The{" "}
              <a
                href="https://collegiaterecovery.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Association of Recovery in Higher Education
              </a>{" "}
              provides resources for collegiate recovery programs.
            </p>
            <span className={myStyles.exampleHeader}>Examples:</span>
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=iHNztoBkEv8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Generation Found Documentary Film
                </a>{" "}
                <b>featuring Archway Academy </b>
              </li>
              <li>
                <a
                  href="https://apnc.org/services/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  North Carolina Collegiate Recovery
                </a>
              </li>
              <li>
                <a
                  href="https://www.augsburg.edu/college-recovery-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Step Up Program at Augsburg University
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
      </div>
    </>
  )
}
