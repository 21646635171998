import React from "react"

//react-pdf
import { View } from "@react-pdf/renderer"

export default function PDFReportHeader() {
  const dukeBlue = "#001A57"

  return (
    <View fixed style={{ position: "absolute", top: "0px", left: 0, right: 0 }}>
      <hr
        style={{
          border: "8px",
          borderStyle: "solid",
          borderColor: dukeBlue,
          backgroundColor: dukeBlue,
        }}
      />

      <hr
        style={{
          border: "3px",
          borderStyle: "solid",
          borderColor: dukeBlue,
          backgroundColor: dukeBlue,
          marginTop: "3px",
        }}
      />
    </View>
  )
}
