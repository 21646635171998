export function GetBaseRoute() {
  switch (process.env.REACT_APP_ENV) {
    case "GP":
      return process.env.REACT_APP_ROUTE_BASE_GP
    default:
      return ""
  }
}

export function GetBaseImageRoute() {
  switch (process.env.REACT_APP_ENV) {
    case "GP":
      return process.env.REACT_APP_ROUTE_IMG_BASE_GP
    default:
      return ""
  }
}

export function GetBaseCountyGeoJson() {
  switch (process.env.REACT_APP_ENV) {
    case "GP":
      return process.env.REACT_APP_COUNTY_GEOJSON_GP
    default:
      return `${window.location.origin}/2010_us_20m_counties.geojson`
  }
}
