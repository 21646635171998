import React from "react"
import mapboxgl from "!mapbox-gl" // eslint-disable-line import/no-webpack-loader-syntax
import { renderToString } from "react-dom/server"
import popupStyle from "../../styles/Default/choropleth.popup.module.css"

export function popupGetBase() {
  return new mapboxgl.Popup({
    closeButton: false,
    className: popupStyle.mainBody,
  })
}

export function popupCountyHtml(
  countyData,
  valueFieldName,
  valueDisplayName,
  valueDisplayPrefix,
  valueDisplaySuffix
) {
  return renderToString(
    <PopupCounty
      countyData={countyData}
      valueFieldName={valueFieldName}
      valueDisplayName={valueDisplayName}
      valueDisplayPrefix={valueDisplayPrefix}
      valueDisplaySuffix={valueDisplaySuffix}
    />
  )
}

export function PopupCounty({
  countyData,
  valueFieldName,
  valueDisplayName,
  valueDisplayPrefix,
  valueDisplaySuffix,
}) {
  if (!countyData) {
    return <></>
  }

  let displayValue = countyData[valueFieldName]

  if (displayValue === undefined) {
    valueDisplayPrefix = ""
    valueDisplaySuffix = ""
    displayValue = "Unavailable"
  }

  if (valueDisplayPrefix === "bool") {
    valueDisplayPrefix = ""
    valueDisplaySuffix = ""

    if (displayValue === 1) {
      displayValue = "Yes"
    } else if (displayValue === 0) {
      displayValue = "No"
    } else {
      displayValue = "Unavailable"
    }
  }

  return (
    <div className={popupStyle.mainGridContainer}>
      <div className={popupStyle.mainGridItemName}>{countyData.county}</div>
      <div className={popupStyle.mainGridItemValueName}>{valueDisplayName}</div>
      <div className={popupStyle.mainGridItemValue}>
        {`${!!valueDisplayPrefix ? valueDisplayPrefix : ""}${displayValue}${
          !!valueDisplaySuffix ? valueDisplaySuffix : ""
        }`}
      </div>
    </div>
  )
}
