import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportHowToUse({ reportType }) {
  const GetStateText = () => {
    return (
      <ol type="1">
        <li>
          <b>Understand the state’s opioid settlement plan.</b> It is important
          to understand how the selected state has decided to allocate funds
          from current opioid settlements in order to guide spending.{" "}
          <a
            href="https://www.opioidsettlementtracker.com/globalsettlementtracker/#statuses"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Opioid Settlement Tracker
          </a>{" "}
          includes the status of state opioid settlement settlements and
          publicly available information on{" "}
          <a
            href="https://www.opioidsettlementtracker.com/settlementspending/#plans"
            target="_blank"
            rel="noopener noreferrer"
          >
            state allocation plans
          </a>
          , where available. If you are involved in a state or county’s planning
          processes for opioid settlement funds, you may already have this
          information, including specific dollar amounts to be received. The
          National Academy for State Health Policy’s{" "}
          <a
            href="https://nashp.org/state-approaches-for-distribution-of-national-opioid-settlement-funding/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tracker
          </a>{" "}
          has information on the{" "}
          <a
            href="https://nationalopioidsettlement.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            National Opioid Settlement
          </a>{" "}
          specifically.
        </li>
        <li>
          <b>Review the recovery investment opportunities below.</b> We used the
          selected state’s performance across specific indicators (see the
          Appendix below) within the Healthy People 2030 Domains to create a
          prioritized list of potential investment areas for opioid settlement
          funds, called <b>Recovery Investment Opportunities</b>. Domains where
          the selected state scored in the red represent the{" "}
          <u>highest level of potential need</u> and are listed first below,
          whereas those where the county scored in the green represent{" "}
          <u>lower potential need</u> for resources.
        </li>
        <li>
          <b>Include stakeholder feedback and other data sources.</b> These
          recovery investment opportunities are based on a review of a limited
          set of publicly available data sources and are intended to provide
          general guidance on interventions and evidence-based practices. This
          information should be considered alongside feedback from a diverse set
          of stakeholders, including community members, individuals with lived
          experience with substance use disorders, health care providers,
          community-based organizations, and others with an in-depth
          understanding of the existing needs within a community. You may also
          have access to recent information gathered by your state or county,
          such as data dashboards or local needs assessments.
        </li>
      </ol>
    )
  }

  const GetCountyText = () => {
    return (
      <ol type="1">
        <li>
          <b>Understand the state’s opioid settlement plan.</b> Before making
          decisions around spending, it is important to understand how the
          selected state and county have decided to allocate funds from current
          opioid settlements in order to guide spending.{" "}
          <a
            href="https://www.opioidsettlementtracker.com/globalsettlementtracker/#statuses"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Opioid Settlement Tracker
          </a>{" "}
          includes the status of state opioid settlement settlements and
          publicly available information on{" "}
          <a
            href="https://www.opioidsettlementtracker.com/settlementspending/#plans"
            target="_blank"
            rel="noopener noreferrer"
          >
            state allocation plans
          </a>
          , where available. If you are involved in the selected state or
          county’s planning processes for opioid settlement funds, you may
          already have this information, including specific dollar amounts to be
          received. The National Academy for State Health Policy’s{" "}
          <a
            href="https://nashp.org/state-approaches-for-distribution-of-national-opioid-settlement-funding/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tracker
          </a>{" "}
          has information on the{" "}
          <a
            href="https://nationalopioidsettlement.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            National Opioid Settlement
          </a>{" "}
          specifically.
        </li>
        <li>
          <b>Review the recovery investment opportunities below.</b> We used the
          selected county’s performance across specific indicators (see the
          Appendix below) within the Healthy People 2030 Domains to create a
          prioritized list of potential investment areas for opioid settlement
          funds, called <b>Recovery Investment Opportunities</b>. Domains where
          the selected county scored in the red represent the{" "}
          <u>highest level of potential need</u> and are listed first below,
          whereas those where the county scored in the green represent{" "}
          <u>lower potential need</u> for resources.
        </li>
        <li>
          <b>Include stakeholder feedback and other data sources.</b> These
          recovery investment opportunities are based on a review of a limited
          set of publicly available data sources and are intended to provide
          general guidance on interventions and evidence-based practices. This
          information should be considered alongside feedback from a diverse set
          of stakeholders, including community members, individuals with lived
          experience with substance use disorders, health care providers,
          community-based organizations, and others with an in-depth
          understanding of the existing needs within a community. You may also
          have access to recent information gathered by your state or county,
          such as data dashboards or local needs assessments. Resources on Needs
          Assessments and other planning tools for counties are available in
          National Association of Counties’ Planning Principles Toolkit.
        </li>
      </ol>
    )
  }

  return (
    <>
      <div className={myStyles.title}>HOW TO USE THIS REPORT</div>
      <div className={myStyles.body}>
        {reportType === "State" ? <GetStateText /> : <GetCountyText />}
      </div>
    </>
  )
}
