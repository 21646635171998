import React, { useContext, useEffect, useRef, useState } from "react"

//primeReact:
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { Tooltip } from "primereact/tooltip"
import { Toast } from "primereact/toast"

//styles
import myStyles from "../../styles/Default/selector.module.css"

//context
import { MainContext } from "../../context/mainContext"

//helpers
import getStateBoundsData from "../../helpers/getStateBounds"

import Tutorial from "./Tutorial"

//Google analytics
import { gtag } from "ga-gtag"

export default function Selector({
  show,
  refresh,
  onRefreshDone,
  onHide,
  onSelectedLocationChanged,
}) {
  const mainContext = useContext(MainContext)
  const toast = useRef(null)

  const [stateBounds] = useState(getStateBoundsData())

  const [locationType, setLocationType] = useState(
    !mainContext.location.optionType
      ? "County"
      : mainContext.location.optionType
  )
  const [location, setLocation] = useState(mainContext.location)
  const [locationOptions, setLocationOptions] = useState(
    mainContext.countyOptions
  )
  const [hoverClose, setHoverClose] = useState(false)

  const nationalOption = [
    {
      county: "",
      county_fips: "",
      focusColumn: "",
      focusValue: "",
      state: "",
      state_fips: "",
      label: "National",
      optionType: "National",
      mapId: "",
    },
  ]

  const setLocationTypeOptions = (locationType) => {
    switch (locationType) {
      case "County":
        let options = mainContext.countyOptions

        if (!!options) {
          options = options.filter((x) => !x.hideOption)
        }

        setLocationOptions(options)
        if (
          !!mainContext.location &&
          mainContext.location.optionType !== "County"
        ) {
          setLocation(undefined)
        }

        break
      case "State":
        setLocationOptions(mainContext.stateOptions)
        if (
          !!mainContext.location &&
          mainContext.location.optionType !== "State"
        ) {
          setLocation(undefined)
        }
        break
      case "National":
        setLocationOptions(nationalOption)
        setLocation(nationalOption[0])
        break
      default:
        setLocationOptions(undefined)
        setLocation(undefined)
    }
  }

  useEffect(() => {
    setLocationTypeOptions(locationType)
  }, [mainContext.countyOptions, mainContext.stateOptions])

  useEffect(() => {
    if (
      refresh &&
      !!mainContext.location &&
      !!mainContext.location.optionType
    ) {
      setLocationType(mainContext.location.optionType)
      setLocationOptions(mainContext.countyOptions)
      setLocation(mainContext.location)
    }
    if (!!onRefreshDone) {
      onRefreshDone()
    }
  }, [refresh])

  const locationTypeOptions = ["County", "State", "National"]

  function setSelection() {
    if (!!location) {
      mainContext.setLocation(location)

      gtag("event", "location_selection", {
        location_type: location.optionType,
        location: location.label,
      })

      const stateBoundingBox = stateBounds.filter(
        (x) => x.fips === location.state_fips
      )
      if (stateBoundingBox.length > 0) {
        //Zoom the bounds out a bit
        let bounds = stateBoundingBox[0].bounds

        mainContext.setMapBoundingBox([
          [bounds[0][0], bounds[0][1]],
          [bounds[1][0], bounds[1][1]],
        ])
        mainContext.setMapZoom(9)
      } else {
        mainContext.setMapBoundingBox(undefined)
        mainContext.setMapZoom(undefined)
      }

      if (!!onSelectedLocationChanged) {
        onSelectedLocationChanged(location)
      }
      onHide()
      mainContext.setShowTutorial(false)
    } else {
      toast.current.show({
        severity: "warn",
        summary: "No selection",
        detail: "Please select a location to continue",
      })
    }
  }

  return (
    <Dialog
      visible={show}
      header={
        <div className={myStyles.header}>
          {mainContext.showTutorial
            ? "How to use this tool"
            : "Location selection"}
          <button
            className={`${myStyles.headerCloseButton} ${
              hoverClose ? myStyles.headerCloseButtonHover : ""
            }`}
            onMouseEnter={() => setHoverClose(true)}
            onMouseLeave={() => setHoverClose(false)}
            onClick={() => {
              setSelection()
            }}
          >
            X
          </button>
        </div>
      }
      closable={false}
      headerClassName={myStyles.header}
    >
      <div
        className={
          mainContext.showTutorial
            ? myStyles.mainGridContainer
            : myStyles.SelectOnlyGridContainer
        }
      >
        <Toast ref={toast} />
        {mainContext.showTutorial ? (
          <>
            <div className={myStyles.mainGridItemTutorial}>
              <Tutorial />
            </div>
          </>
        ) : null}

        <div className={myStyles.mainGridItemSelect}>
          {mainContext.showTutorial ? (
            <div className={myStyles.tutorialNote}>
              Done with the tutorial? Start here:
            </div>
          ) : null}

          <Tooltip target={".tt-selector"} />
          <div>Please select a location type:</div>
          <Dropdown
            options={locationTypeOptions}
            value={locationType}
            onChange={(e) => {
              setLocationType(e.value)
              setLocationTypeOptions(e.value)
            }}
            style={{ width: "100%" }}
          />
          <div className={myStyles.option}>Please select a location:</div>
          <Dropdown
            filter={true}
            options={locationOptions}
            optionLabel="label"
            value={location}
            placeholder="Please select a location"
            onChange={(e) => {
              setLocation(e.value)
            }}
            style={{ width: "100%" }}
          />
          <div>
            <div
              onClick={() => {
                mainContext.setShowTutorial(!mainContext.showTutorial)
              }}
              className={`${myStyles.showTutorialButton} tt-selector`}
              data-pr-tooltip={
                mainContext.showTutorial ? "Hide tutorial" : "Show tutorial"
              }
              data-pr-position={"bottom"}
            >
              <i className="pi pi-question-circle" />
            </div>
            <button
              onClick={setSelection}
              disabled={!location}
              className={`btn ${myStyles.submitButton}`}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
