import React from "react"

//styles
import myStyles from "../../styles/Default/report.module.css"

export default function ReportRecommendSocial({ needLevel, needLevelStyle }) {
  return (
    <>
      <div
        className={`${myStyles.mainContainer} ${myStyles.mainContainerRecommendItem} ${needLevelStyle}`}
      >
        <div className={myStyles.mainBody}>
          <div className={`${myStyles.subTitle2} ${myStyles.noMargin}`}>
            Social: {needLevel}
          </div>
        </div>
      </div>
      <div className={myStyles.mainContainer}>
        <div className={myStyles.mainBody}>
          <br />
          <div className={myStyles.bodyHighlighted}>
            Peer Recovery Support/Recovery Coach
          </div>
          <div className={myStyles.body}>
            <p>
              <a
                href="https://www.samhsa.gov/sites/default/files/programs_campaigns/brss_tacs/peer-support-2017.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Peer recovery support
              </a>{" "}
              and recovery coaching include a wide range of services between
              people who share similar experiences with mental health
              conditions, substance use disorders, or both. Trained and
              certified peers provide non-clinical support in assisting people
              and their families with their recovery through sharing their own
              lived experience, practical guidance, and connection to other
              resources and tools. The use of peer recovery support services to
              support individuals with substance use disorders is growing both
              for outreach and engagement and ongoing long-term support.{" "}
            </p>
            <p>
              Peer support paraprofessionals practice in a variety of settings
              including peer-run organizations, recovery community
              organizations, community centers, recovery residences, homeless
              shelters, criminal justice settings, and health care settings.
              While inconsistent implementation and research methods inhibit
              broad conclusions, some research suggests that peer recovery
              support is associated with reduced substance use and reoccurrence
              of SUD rates, increased retention in SUD treatment, and improved
              satisfaction with treatment (Gormley et al., 2021).
            </p>
            <p>
              Please refer to the <b>Recovery Support Services</b> section
              within the <b>Health Care domain</b> for more information on Peer
              Recovery Support and other Recovery Support Services.
            </p>
            <div className={myStyles.divider}>
              <hr />
            </div>
          </div>
          <div className={myStyles.bodyHighlighted}>
            Mutual-Aid Support Meeting
          </div>
          <div className={myStyles.body}>
            <p>
              Mutual-Aid Support Meetings are community-based or virtual
              self-help programs to address substance use such as Alcoholics
              Anonymous (AA) or Narcotics Anonymous. They can include
              behavioral, spiritual, and cognitive components—{" "}
              <a
                href="https://facesandvoicesofrecovery.org/resources/mutual-aid-resources/"
                target="_blank"
                rel="noopener noreferrer"
              >
                this website
              </a>{" "}
              includes a guide to different models used around the country. A
              review of AA and similar twelve-step facilitation programs found
              that these interventions usually produced higher rates of
              continuous abstinence from alcohol (opioids were not studied)
              compared to other treatments (e.g., cognitive behavioral therapy)
              (Kelly et al., 2020).
            </p>
            <span className={myStyles.exampleHeader}>Examples:</span>
            <ul>
              <li>
                <a
                  href="https://www.na.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Narcotics Anonymous
                </a>
              </li>
              <li>
                <a
                  href="https://www.aa.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alcoholics Anonymous
                </a>
              </li>
              <li>
                <a
                  href="https://www.smartrecovery.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SMART (Self-Management and Recovery Training) Recovery
                </a>
              </li>
              <li>
                <a
                  href="https://al-anon.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Al-Anon Family Groups
                </a>
              </li>
            </ul>
          </div>{" "}
        </div>
      </div>
    </>
  )
}
