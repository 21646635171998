import React, { useState } from "react"

//styles
import myStyles from "../../styles/Default/selector.module.css"

//PrimeReact
import { Paginator } from "primereact/paginator"

//images
import imgWhite from "../../assets/images/white.png"
import imgSelect from "../../assets/images/tutorial_select_2.png"
import imgExplore from "../../assets/images/tutorial_explore_4.png"
import imgExploreSelection from "../../assets/images/tutorial_explore_selection_3.png"
import imgExploreVariables from "../../assets/images/tutorial_explore_variables_3.png"
import imgMonitor from "../../assets/images/tutorial_monitor_5.png"
import imgMonitorNeeds from "../../assets/images/tutorial_monitor_needIndex_4.png"
import imgMonitorAssets from "../../assets/images/tutorial_monitor_recoveryAsset_4.png"
import imgDone from "../../assets/images/white.png"

export default function Tutorial() {
  const [page, setPage] = useState(0)

  const tutorialItems = [
    {
      header: "Welcome",
      image: imgWhite,
      footer: `To proceed with a quick tutorial on the use of this tool, please navigate with the arrows at the bottom of this page. 
        
Alternatively, use the option boxes on the right to select a region and start exploring`,
    },
    {
      header: "Location selection",
      image: imgSelect,
      footer:
        "Select a location and click on submit to filter research values. The tutorial can be accessed by clicking on the question mark icon in the bottom left corner of the popup",
    },
    {
      header: "Explore",
      image: imgExplore,
      footer:
        "The Explore page consists of a map representing the selected variables from the table on the right.",
    },
    {
      header: "Explore: Variables",
      image: imgExploreVariables,
      footer: `Selecting one of the categorized variables will update the map to reflect the values within the selected region

Clicking on the "View location statistics" button will navigate to the Monitor page for more information
        `,
    },
    {
      header: "Explore: Selection",
      image: imgExploreSelection,
      footer: `Information on the current variable as it pertains to the selected location will be displayed on the panel on top of the map.

Clicking on the (i) icon next to the variable name will open an information popup detailing calculations, sources and historic values for the selected category

The magnifying glass will open the selection popup to allow for changing of the selected location`,
    },
    {
      header: "Monitor",
      image: imgMonitor,
      footer: `The Monitor page contains a detailed report for the selected location. 
      
Comparisons are shown on state and national averages with a summarized need index color coded to indicate the score within that category`,
    },
    {
      header: "Monitor: Need index",
      image: imgMonitorNeeds,
      footer: `The need index reflects the overall score for the category
      
There are potential investment areas for opioid settlement funds that can support improvement in each domain`,
    },
    {
      header: "Monitor: Recovery Investment Opportunities",
      image: imgMonitorAssets,
      footer:
        "Clicking on the Recovery Investment Opportunities will open a popup with more information on each asset class.",
    },
    {
      header: "Go explore",
      image: imgDone,
      footer: `This concludes the tutorial. 
        
To continue, use the option boxes on the right to select a region and start exploring.`,
    },
  ]

  function TutorialItem() {
    return (
      <div style={{ minHeight: "638px" }}>
        <div className={myStyles.tutorialHeader}>
          {tutorialItems[page].header}
        </div>
        <div className={myStyles.tutorialImage}>
          <img src={tutorialItems[page].image} alt="Tutorial" />
        </div>
        <div className={myStyles.tutorialFooter}>
          {tutorialItems[page].footer}
        </div>
      </div>
    )
  }

  return (
    <div style={{ height: "100%" }}>
      <TutorialItem />
      <Paginator
        first={page}
        rows={1}
        totalRecords={tutorialItems.length}
        onPageChange={(e) => setPage(e.first)}
        className={myStyles.tutorialPaginator}
      />
    </div>
  )
}
